import {
  GET_ADDRESSES,
  SAVE_ADDRESS,
  DELETE_ADDRESS,
  SET_DEFAULT,
  EDIT_ADDRESS,
} from './actionTypes';

const initialState = {
  addresses: [],
  edit_address: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ADDRESSES:
      return { ...state, addresses: action.payload };
    case SAVE_ADDRESS:
      return { ...state, addresses: action.payload };
    case DELETE_ADDRESS:
      return { ...state, addresses: action.payload };
    case SET_DEFAULT:
      return { ...state, addresses: action.payload };
    case EDIT_ADDRESS:
      return { ...state, edit_address: action.payload };
    default:
      return state;
  }
}
