import { APPLY_COUPON, ALL_COUPONS, REMOVE_COUPONS } from './actionTypes';
import { ADD_PRODUCT, REMOVE_PRODUCT, UPDATE_CART } from '../cart/actionTypes';

const initialState = {
  coupon: {},
  all_coupons: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case APPLY_COUPON:
      return { ...state, coupon: action.payload };
    case ALL_COUPONS:
      return { ...state, all_coupons: action.payload };
    case REMOVE_COUPONS:
      return { ...state, coupon: {} };
    case ADD_PRODUCT:
      return { ...state, coupon: {} };
    case REMOVE_PRODUCT:
      return { ...state, coupon: {} };
    case UPDATE_CART:
      return { ...state, coupon: {} };
    default:
      return state;
  }
}
