import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import {
  GET_WALLET_TRANSACTIONS_URL,
  UPDATE_USER_INFO_URL,
} from '../../../../configs';
import Axios from 'axios';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/lab/Skeleton';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';

export default function MyWallet(params) {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [user, setUser] = useState({});

  useEffect(() => {
    let userl = JSON.parse(localStorage.getItem('user'));

    Axios.post(UPDATE_USER_INFO_URL, {
      token: userl.auth_token,
      user_id: userl.id,
    })
      .then((res) => {
        setUser(res.data.data);
        userl.wallet_balance = res.data.data.wallet_balance;
        localStorage.setItem('user', JSON.stringify(userl));
      })
      .catch((e) => console.log(e));

    Axios.post(GET_WALLET_TRANSACTIONS_URL, {
      token: userl.auth_token,
      user_id: userl.id,
    })
      .then((res) => {
        setTransactions(res.data.transactions);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row bg-white pt-4">
        <div className="col-3">
          <div style={{ border: '1px solid grey', padding: 10 }}>
            <b>
              Wallet{' '}
              <span style={{ color: 'orange' }}>Rs. {user.wallet_balance}</span>
            </b>
          </div>
        </div>
      </div>

      {loading && (
        <div className="row">
          {[...Array(10)].map((i) => {
            return (
              <div key={i} className="col-md-10">
                <div className="mb-3 bg-white p-3">
                  <Skeleton variant="rect" height={54} />
                  <Skeleton variant="text" style={{ marginTop: 5 }} />
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={50} />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="row bg-white">
        <div className="col-md-10">
          <List component="nav" aria-label="main mailbox folders">
            {!loading &&
              transactions.length > 0 &&
              transactions.map((item) => {
                return (
                  <>
                    <ListItem button key={item.id}>
                      <ListItemIcon>
                        {item.type === 'deposit' && (
                          <AccountBalanceWalletOutlinedIcon
                            style={{ color: 'green' }}
                          />
                        )}
                        {item.type !== 'deposit' && (
                          <ReceiptOutlinedIcon style={{ color: 'red' }} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={'₹ ' + item.amount}
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body2">
                              {item.meta.description}
                            </Typography>
                            <br />
                            {item.created_at}
                            <Typography
                              component="span"
                              variant="body2"
                              style={{
                                float: 'right',
                                color:
                                  item.type === 'deposit' ? 'green' : 'red',
                              }}
                            >
                              {item.type === 'deposit' ? 'Credit' : 'Debit'}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </>
                );
              })}
          </List>

          {transactions.length === 0 && (
            <Typography
              style={{ padding: 10, textAlign: 'center' }}
              className="ml-2 mt-2 mb-4"
              variant="body1"
            >
              <b>No Wallet Transactions Yet!!!</b>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
