const setAsDefaultAddress = (user, addressObj) => {
  const myLocation = {
    formatted_address: addressObj?.address,
    geometry: {
      location: {
        lat: addressObj?.latitude,
        lng: addressObj?.longitude,
      },
    },
  };

  localStorage.setItem('geoLocation', JSON.stringify(myLocation));
  localStorage.setItem('address', addressObj?.address);

  if (user) {
    localStorage.setItem('userSetAddress', JSON.stringify(myLocation));
    const userObject = {
      ...user,
      default_address: addressObj,
    };
    localStorage.setItem('user', JSON.stringify(userObject));
  }
};

export default setAsDefaultAddress;
