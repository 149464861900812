import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

// eslint-disable-next-line
export default (initialState) => {
  initialState =
    JSON.parse(window.localStorage.getItem('state')) || initialState;
  const middleware = [thunk];

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware)
      /* window.__REDUX_DEVTOOLS_EXTENSION__ &&
              window.__REDUX_DEVTOOLS_EXTENSION__() */
    )
  );

  store.subscribe(() => {
    const state = store.getState();
    //save the config to localstorage
    for (const translation in state.languages.language) {
      // eslint-disable-next-line no-prototype-builtins
      if (state.languages.language.hasOwnProperty(translation)) {
        const trans = state.languages.language[translation];
        localStorage.setItem(translation, trans);
      }
    }

    let filtered = [];

    // eslint-disable-next-line no-prototype-builtins
    if (state['settings'].hasOwnProperty('settings')) {
      filtered = state.settings.settings.filter(
        (item) =>
          item.key !== 'googleApiKey' &&
          item.key !== 'googleApiKeyIP' &&
          item.key !== 'googleAppId' &&
          item.key !== 'facebookAppId'
      );
      for (const setting in filtered) {
        // eslint-disable-next-line no-prototype-builtins
        if (filtered.hasOwnProperty(setting)) {
          const element = filtered[setting];
          localStorage.setItem(element.key, element.value);
        }
      }
    }

    const persist = {
      settings: filtered, // state.settings.settings,
      cart: state.cart,
      coupon: state.coupon,
      total: state.total,
      items: state.items,
      pages: state.pages,
      user: state.user,
      addresses: state.addresses,
      delivery_user: state.delivery_user,
      languages: state.languages.languages,
      language: state.languages.language,
    };
    localStorage.setItem('state', JSON.stringify(persist));

    // const user = {
    //     user: state.user
    // };
    // window.localStorage.setItem("user", JSON.stringify(user.user));
  });

  return store;
};
