import { WEBSITE_URL } from './website';

export { WEBSITE_URL };
export const ROOT_URL =
  typeof window !== 'undefined' && window.location.hostname !== 'localhost'
    ? WEBSITE_URL + '/public/'
    : WEBSITE_URL;
export const DEBUG = false;
export const GET_SETTINGS_URL = WEBSITE_URL + '/api/get-settings';
export const SEARCH_LOCATIONS_URL = WEBSITE_URL + '/api/search-location';
export const GET_POPULAR_LOCATIONS_URL =
  WEBSITE_URL + '/api/popular-geo-locations';
export const GET_PROMO_SLIDER_URL = WEBSITE_URL + '/api/promo-slider';
export const GET_DELIVERY_RESTAURANTS_URL =
  WEBSITE_URL + '/api/get-delivery-restaurants';
export const GET_SELFPICKUP_RESTAURANTS_URL =
  WEBSITE_URL + '/api/get-selfpickup-restaurants';
export const GET_RESTAURANT_INFO_URL = WEBSITE_URL + '/api/get-restaurant-info';
export const GET_RESTAURANT_INFO_BY_ID_URL =
  WEBSITE_URL + '/api/get-restaurant-info-by-id';
export const GET_RESTAURANT_ITEMS_URL =
  WEBSITE_URL + '/api/get-restaurant-items';
export const GET_MAIN_SLIDER_URL = WEBSITE_URL + '/api/get-main-slider';
export const APPLY_COUPON_URL = WEBSITE_URL + '/api/apply-coupon';
export const ALL_COUPONS_URL = WEBSITE_URL + '/api/get-coupons';
export const LOGIN_USER_URL = WEBSITE_URL + '/api/login';
export const LOGIN_USER_MOBILE_URL = WEBSITE_URL + '/api/login';
export const LOGIN_USER_MOBILE_OTP_URL = WEBSITE_URL + '/api/login';
export const NEW_REGISTER_OTP = WEBSITE_URL + '/api/login';
export const LOGOUT_USER_URL = WEBSITE_URL + '/api/logout';
export const VERIFY_USER_IS_LOGGED_IN_URL =
  WEBSITE_URL + '/api/verifyUserIsLoggedIn';
export const USER_CHANGE_LOGIN_CREDENTIALS_URL =
  WEBSITE_URL + '/api/userChangeLoginCredentials';
export const USER_CHANGE_PROFILE_DATA_URL =
  WEBSITE_URL + '/api/userChangeProfileData';
export const REGISTER_USER_URL = WEBSITE_URL + '/api/register';
export const GET_PAGES_URL = WEBSITE_URL + '/api/get-pages';
export const GET_SINGLE_PAGE_URL = WEBSITE_URL + '/api/get-single-page';
export const SEARCH_RESTAURANTS_URL = WEBSITE_URL + '/api/search-restaurants';
export const GET_ADDRESSES_URL = WEBSITE_URL + '/api/get-addresses';
export const SAVE_ADDRESS_URL = WEBSITE_URL + '/api/save-address';
export const DELETE_ADDRESS_URL = WEBSITE_URL + '/api/delete-address';
export const UPDATE_USER_INFO_URL = WEBSITE_URL + '/api/update-user-info';
export const PLACE_ORDER_URL = WEBSITE_URL + '/api/place-order';
export const GET_ORDER_DETAIL_URL = WEBSITE_URL + '/api/get-order-details';

export const SET_DEFAULT_URL = WEBSITE_URL + '/api/set-default-address';
export const GET_ORDERS_URL = WEBSITE_URL + '/api/get-orders';
export const GET_PAYMENT_GATEWAYS_URL =
  WEBSITE_URL + '/api/get-payment-gateways';
export const NOTIFICATION_TOKEN_URL =
  WEBSITE_URL + '/api/save-notification-token';
export const SEND_OTP_URL = WEBSITE_URL + '/api/send-otp';
export const SEND_REGISTRATION_OTP_URL =
  WEBSITE_URL + '/api/send-registration-otp/';
export const VERIFY_OTP_URL = WEBSITE_URL + '/api/verify-otp';
export const RAZORPAY_PAYMENT_URL =
  WEBSITE_URL + '/api/payment/process-razor-pay/';
export const CHECK_USER_RUNNING_ORDER_URL =
  WEBSITE_URL + '/api/check-running-order';
export const GET_ORDER_CANCEL_URL = WEBSITE_URL + '/api/cancel-order';
export const GET_WALLET_TRANSACTIONS_URL =
  WEBSITE_URL + '/api/get-wallet-transactions';
export const CHECK_RESTAURANT_OPERATION_SERVICE_URL =
  WEBSITE_URL + '/api/check-restaurant-operation-service';
export const GET_SINGLE_ITEM_URL = WEBSITE_URL + '/api/get-single-item';
export const GET_ALL_LANGUAGES_URL = WEBSITE_URL + '/api/get-all-languages';
export const GET_SINGLE_LANGUAGE_DATA_URL =
  WEBSITE_URL + '/api/get-single-language';
export const SET_SINGLE_LANGUAGE_DATA_URL =
  WEBSITE_URL + '/api/set-single-language';
export const GET_ADDRESS_FROM_COORDINATES =
  WEBSITE_URL + '/api/coordinate-to-address';
export const SEND_PASSWORD_RESET_EMAIL_URL =
  WEBSITE_URL + '/api/send-password-reset-mail';
export const VERIFY_PASSWORD_RESET_OTP_URL =
  WEBSITE_URL + '/api/verify-password-reset-otp';
export const CHANGE_USER_PASSWORD_URL =
  WEBSITE_URL + '/api/change-user-password';
export const GET_RESTAURANTS_CATEGORIES_URL =
  WEBSITE_URL + '/api/get-all-restaurants-categories';
export const GET_FILTERED_RESTAURANTS_URL =
  WEBSITE_URL + '/api/get-filtered-restaurants';
export const GET_RESTAURANTS_SLIDES_URL =
  WEBSITE_URL + '/api/get-restaurant-category-slides';
export const GET_ALERTS_URL = WEBSITE_URL + '/api/get-user-notifications';
export const MARK_ALL_NOTIFICATIONS_READ_URL =
  WEBSITE_URL + '/api/mark-all-notifications-read';
export const MARK_ONE_NOTIFICATION_READ_URL =
  WEBSITE_URL + '/api/mark-one-notification-read';

export const GET_SINGLE_ORDER_TO_BE_RATED =
  WEBSITE_URL + '/api/single-ratable-order';
export const GET_RATABLE_ORDER_DETAILS_URL =
  WEBSITE_URL + '/api/get-ratable-order';
export const ADD_RATING_URL = WEBSITE_URL + '/api/save-new-rating';

export const POST_USER_PROFILE = WEBSITE_URL + '/api/edit-profile';
export const POST_USER_ADDRESS = WEBSITE_URL + '/api/edit-address';
export const USER_SUPPORT = WEBSITE_URL + '/api/ask-support';

/* Delivery URLs */
export const LOGIN_DELIVERY_USER_URL = WEBSITE_URL + '/api/delivery/login';
export const UPDATE_DELIVERY_USER_INFO_URL =
  WEBSITE_URL + '/api/delivery/update-user-info';
export const GET_DELIVERY_ORDERS_URL =
  WEBSITE_URL + '/api/delivery/get-delivery-orders';
export const GET_SINGLE_DELIVERY_ORDER_URL =
  WEBSITE_URL + '/api/delivery/get-single-delivery-order';
export const SEND_DELIVERY_GUY_GPS_LOCATION_URL =
  WEBSITE_URL + '/api/delivery/set-delivery-guy-gps-location';
export const GET_DELIVERY_GUY_GPS_LOCATION_URL =
  WEBSITE_URL + '/api/delivery/get-delivery-guy-gps-location';
export const ACCEPT_TO_DELIVER_URL =
  WEBSITE_URL + '/api/delivery/accept-to-deliver';
export const PICKEDUP_ORDER_URL = WEBSITE_URL + '/api/delivery/pickedup-order';
export const DELIVER_ORDER_URL = WEBSITE_URL + '/api/delivery/deliver-order';
