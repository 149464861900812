import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SignupHandler from '../../Shared/Auth/SignUp';
import Social0Auth from '../../Shared/Auth/Social';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl } from '@mui/material';
import { Box } from '@mui/material';
import PhoneTextField from 'mui-phone-textfield';
import '../styles.scss';

export default function SignupDrawer(props) {
  const localstorage = Object.assign({}, window.localStorage);
  const history = useHistory();
  const location = useLocation();

  const [signup, setSignupDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCountry, setPhoneCountry] = useState('US');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [inputEmailError, setInputEmailError] = useState(false);
  const [inputPasswordError, setInputPasswordError] = useState({
    validation: false,
    identic: false,
    chars: false,
  });

  const [otpIsTriggered, setIsOtpTriggered] = useState(false);
  const [signupIsTriggered, setIsSignupTriggered] = useState(false);
  const [signupType, setSignupType] = useState('');
  const [socialUser, setSocialUser] = useState({});

  const user = localstorage?.user;

  useEffect(() => {
    if (props.trigger) {
      setSignupDrawer(!props.login ? true : false);
    }
  }, [props.login, props.trigger]);

  useEffect(() => {
    if (otpIsTriggered && otpSent) {
      setIsOtpTriggered(false);
    }
  }, [otpIsTriggered, otpSent]);

  useEffect(() => {
    if (signupIsTriggered) {
      setIsSignupTriggered(false);
    }
  }, [signupIsTriggered, otpSent]);

  const doOtp = () => {
    setIsOtpTriggered(true);
  };

  const doSignup = (type, socialUser) => {
    setSignupType(type);
    if (socialUser) {
      setSocialUser(socialUser);
    }
    setIsSignupTriggered(true);
  };

  const handleSignUpDrawer = () => {
    setSignupDrawer(!signup);
    props.triggerCallback();
  };

  return (
    <>
      <SignupHandler
        name={name}
        email={email}
        phone={phone}
        password1={password1}
        password2={password2}
        otp={otp}
        callbackSignupDrawer={setSignupDrawer}
        callbackOtpSent={setOtpSent}
        callbackIsLoading={setLoading}
        callbackInputEmailError={setInputEmailError}
        callbackInputPasswordError={setInputPasswordError}
        isDoOtp={otpIsTriggered ? true : false}
        callbackOtpFailed={() => setIsOtpTriggered(false)}
        isDoSignup={signupIsTriggered ? true : false}
        callbackSignupFailed={() => setIsSignupTriggered(false)}
        callbackSignupCompleted={() => setIsSignupTriggered(false)}
        signupType={signupType}
        socialUser={socialUser}
      />

      {(!user || user === 'undefined' || Object.keys(user)?.length < 1) &&
      location.pathname === '/' ? (
        <div
          type="button"
          className={
            signup
              ? 'desktop-header-nav-btns desktop-header-nav-btn--active'
              : 'desktop-header-nav-btns desktop-header-nav-btn'
          }
          onClick={() => handleSignUpDrawer()}
        >
          {props.signin}
        </div>
      ) : (
        location.pathname === '/' && (
          <div
            type="button"
            className="desktop-header-nav-btns desktop-header-nav-btn"
            style={{
              float: 'right',
              marginLeft: 10,
            }}
            onClick={() => history.push('/desktop/account')}
          >
            {props.account}
          </div>
        )
      )}

      <Drawer
        anchor={'right'}
        open={signup}
        onClose={() => handleSignUpDrawer()}
      >
        <div style={{ padding: 1 }}>
          <IconButton onClick={() => handleSignUpDrawer()} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ padding: 25 }}>
          <Typography variant="h5" gutterBottom>
            Register
          </Typography>
          <Typography variant="body" gutterBottom>
            or{' '}
          </Typography>
          <div
            style={{ color: '#8c1ed3', cursor: 'pointer' }}
            variant="body"
            gutterBottom
            onClick={() => {
              setSignupDrawer(!signup);
              props.triggerCallback('login');
            }}
          >
            login to your account
          </div>

          <br />

          <div
            className="signup-text-fields-wrapper"
            style={{
              display:
                otpSent && !inputEmailError && !inputPasswordError?.validation
                  ? 'none'
                  : 'flex',
            }}
          >
            <TextField
              className="filled-input-white"
              style={{
                marginTop: 5,
                marginBottom: 10,
                backgroundColor: 'white',
                borderColor: 'black',
                width: '100%',
              }}
              label="Name"
              id="name"
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
            />
            <TextField
              className="filled-input-white"
              style={{
                marginTop: 5,
                backgroundColor: 'white',
                borderColor: 'black',
                width: '100%',
              }}
              label="Email "
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              error={inputEmailError}
            />
            {inputEmailError ? (
              <div className="validation-error">{'Email is invalid'}</div>
            ) : (
              <div className="validation-error"></div>
            )}

            <Box className="my-account-register-phone-number" sx={{ width: 1 }}>
              <FormControl>
                <PhoneTextField
                  label="Phone (optional)"
                  country={phoneCountry}
                  onCountryChange={setPhoneCountry}
                  onPhoneNumber={setPhone}
                  placeholder="Enter your phone number"
                  sx={{ width: 1, backgroundColor: 'white' }}
                />
              </FormControl>
              <Box sx={{ my: 2 }} />
              {phone && (
                <Box>
                  This is a valid {phoneCountry} phone number: {phone}
                </Box>
              )}
            </Box>

            <TextField
              className="filled-input-white"
              style={{
                marginTop: 5,
                marginBottom: 10,
                backgroundColor: 'white',
                borderColor: 'black',
                width: '100%',
              }}
              label="Password"
              id="password1"
              onChange={(e) => setPassword1(e.target.value)}
              defaultValue=""
              type="password"
              variant="outlined"
              error={inputPasswordError.validation}
            />

            <TextField
              className="filled-input-white"
              style={{
                marginTop: 5,
                backgroundColor: 'white',
                borderColor: 'black',
                width: '100%',
              }}
              label="Repeat Password"
              id="password2"
              onChange={(e) => setPassword2(e.target.value)}
              defaultValue=""
              type="password"
              variant="outlined"
              error={inputPasswordError.validation}
            />
            {!!inputPasswordError.identic && (
              <div className="validation-error">
                {'Password is not identical'}
              </div>
            )}
            {!!inputPasswordError.chars && (
              <div className="validation-error">
                {'Password length min. 8 chars'}
              </div>
            )}
            {!inputPasswordError.identic && !inputPasswordError.chars && (
              <div className="validation-error"></div>
            )}

            <Button
              style={{
                padding: 10,
                width: '100%',
                backgroundColor: '#8c1ed3',
                borderRadius: 4,
                color: 'white',
              }}
              variant="contained"
              size="large"
              loading={loading}
              disabled={loading}
              onClick={() => doOtp()}
              color="primary"
            >
              {loading ? 'Please Wait...' : 'REGISTER'}
            </Button>
          </div>

          <div
            style={{
              display:
                otpSent && !inputEmailError && !inputPasswordError?.validation
                  ? ''
                  : 'none',
            }}
          >
            <div>
              {
                'Please insert the 6 digits otp from your email inside the input field and submit.'
              }
            </div>
            <TextField
              className="filled-input-white"
              style={{
                marginTop: 5,
                backgroundColor: 'white',
                borderColor: 'black',
                width: '100%',
              }}
              label="OTP"
              id="otp"
              onChange={(e) =>
                e.target.value === '' ||
                (e.target.value.match(/^[0-9]+$/) &&
                  e.target.value?.length <= 6)
                  ? setOtp(e.target.value)
                  : null
              }
              type="tel"
              variant="outlined"
              value={otp}
            />
            <Button
              style={{
                marginTop: 25,
                padding: 10,
                width: '100%',
                backgroundColor: '#8c1ed3',
                borderRadius: 4,
                color: 'white',
              }}
              variant="contained"
              size="large"
              loading={loading}
              disabled={loading}
              onClick={() => doSignup('otp')}
              color="primary"
            >
              {loading ? 'Please Wait...' : 'VERIFY'}
            </Button>
            <Button
              style={{
                marginTop: 25,
                padding: 10,
                width: '100%',
                border: '1px solid',
                borderColor: localstorage.storeColor,
                borderRadius: 4,
                backgroundColor: '#fff',
                color: localstorage.storeColor,
              }}
              variant="contained"
              size="large"
              onClick={() => {
                setOtpSent(false);
                setSignupDrawer(signup);
                props.triggerCallback('registry');
              }}
            >
              Reset
            </Button>
          </div>
          <div style={{ display: !otpSent ? '' : 'none', marginTop: 60 }}>
            <Social0Auth
              action="signup"
              type="signup0Auth"
              callback={doSignup}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
}
