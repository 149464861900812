import React, { Component } from 'react';

import Ink from 'react-ink';
// import Collapsible from "react-collapsible";
import BackWithSearch from '../../Mobile/Elements/BackWithSearch';
import { applyCoupon, allCoupons } from '../../../services/coupon/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import PagePopup from "./PagePopup";
// import DelayLink from "../../../helpers/delayLink";

// const faqs = [
//   "lkjhboih", "Wcevt34"
// ];

class Offers extends Component {
  static contextTypes = {
    router: () => null,
  };

  state = {
    inputCoupon: '',
    couponFailed: true,
    couponValid: false,
    couponInvalid: false,
  };

  componentDidMount() {
    this.props.allCoupons();
  }

  handleApplyCoupn(code) {
    this.setState({ inputCoupon: code });
    // event.preventDefault();
    console.log(this.props.coupon);
    window.navigator.clipboard.writeText(code);
    alert('Code Copied!');
  }

  render() {
    const { coupon, all_coupons } = this.props;

    return (
      <React.Fragment>
        <BackWithSearch
          boxshadow={true}
          has_title={true}
          title="Offers"
          disable_search={true}
        />
        <React.Fragment>
          <div className="input-group pt-50">
            <div className="coupon-status">
              {coupon.code && (
                <div className="coupon-success pt-10 pb-10">
                  {'"' + coupon.code + '"'}{' '}
                  {localStorage.getItem('cartApplyCoupon')}{' '}
                  {coupon.discount_type === 'PERCENTAGE' ? (
                    coupon.discount + '%'
                  ) : (
                    <React.Fragment>
                      {localStorage.getItem('currencySymbolAlign') === 'left' &&
                        localStorage.getItem('currencyFormat') +
                          coupon.discount}

                      {localStorage.getItem('currencySymbolAlign') ===
                        'right' &&
                        coupon.discount +
                          localStorage.getItem('currencyFormat')}
                      {localStorage.getItem('cartCouponOffText')}
                    </React.Fragment>
                  )}
                </div>
              )}
              {/* Coupon is not applied, then coupon state is true */}
              {!this.state.couponFailed && (
                <div className="coupon-fail pt-10 pb-10">
                  {localStorage.getItem('cartInvalidCoupon')}
                </div>
              )}
            </div>
          </div>

          <div className="display-flex p-2 bg-light">
            <div className="flex-auto border-0 text-muted text-md">
              <span>All Available Coupons</span>
            </div>
          </div>

          <div>
            {/*<Collapsible trigger={localStorage.getItem("accountMyAccount")} transitionTime={200} open={false}>
					    </Collapsible>*/}

            {all_coupons !== undefined && (
              <>
                {all_coupons.map((item, index) => {
                  return (
                    <div key={index} className="block-content block-content">
                      <h4 className="font-w800 mb-5 text-dark">{item.name}</h4>
                      <div className="font-size-sm text-muted text-muted">
                        {item.description}
                        <br />
                        <button
                          style={{
                            borderStyle: 'dashed',
                            color: 'green',
                            borderColor: 'green',
                          }}
                          class="mt-2 mr-5 btn btn-square btn-outline-secondary min-width-125 mb-10 order-status-button text-muted"
                        >
                          {item.code}
                        </button>
                        <div className="pull-right">
                          <button
                            type="button"
                            onClick={() => this.handleApplyCoupn(item.code)}
                            className="btn btn-square btn-outline-secondary mb-10 order-track-button"
                            delay={250}
                            style={{ position: 'relative' }}
                          >
                            Copy
                            <span className="pulse ml-2"></span>
                            <Ink duration="500" />
                          </button>
                        </div>
                      </div>
                      <hr className="my-1" />
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  coupon: state.coupon.coupon,
  user: state.user.user,
  cartTotal: state.total.data,
  all_coupons: state.coupon.all_coupons,
  restaurant_info: state.items.restaurant_info,
});

export default connect(mapStateToProps, { applyCoupon, allCoupons })(
  withRouter(Offers)
);
