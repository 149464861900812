import { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import CartDrawer from '../../Desktop/Cart/cartDrawer';
import './styles.scss';

const CartIcon = ({ place }) => {
  const localstorage = Object.assign({}, window.localStorage);
  const location = useLocation();
  const [showCart, setShowCart] = useState(false);
  let cart = useMemo(
    () => (localstorage.cart && JSON.parse(localstorage?.cart)) || [],
    [localstorage?.cart]
  );

  const showDrawerHandler = (show) => setShowCart(show);

  return (
    <>
      {cart?.length > 0 && (
        <Button
          className={
            place === 'home'
              ? 'cart-icon-btn cart-icon-btn--home'
              : 'cart-icon-btn'
          }
          onClick={() =>
            showDrawerHandler(
              location.pathname !== '/desktop/cart' && !showCart
            )
          }
          style={{
            padding: 20,
            color: location.pathname === '/desktop/cart' ? '#ffd9fa' : 'white',
          }}
        >
          <span
            className={
              place === 'home'
                ? 'cart-quantity-badge--desktop cart-quantity-badge--desktop-home'
                : 'cart-quantity-badge--desktop'
            }
            style={{ backgroundColor: localstorage?.storeColor }}
          >
            {cart?.length}
          </span>

          <ShoppingBagOutlinedIcon fontSize="large" style={{ color: '#fff' }} />
        </Button>
      )}
      <CartDrawer cart={cart} show={showCart} callback={showDrawerHandler} />
    </>
  );
};

export default CartIcon;
