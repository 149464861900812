import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSettings } from '../../services/settings/actions';
import Desktop from '../../components/Desktop';
import Mobile from '../../components/Mobile';

import {
  getAllLanguages,
  getSingleLanguageData,
} from '../../services/languages/actions';

// supress unecessary warnings
const consoleWarn = console.error;
const SUPPRESSED_WARNINGS = [
  'MUI: There are multiple `InputBase` components inside a FormControl.',
];

console.error = function filterWarnings(msg, ...args) {
  if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
    consoleWarn(msg, ...args);
  }
};
class App extends Component {
  state = {
    languageId: undefined,
  };

  componentDidMount() {
    this.props.getSettings();
    this.props.getAllLanguages();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.languages !== nextProps.languages &&
      localStorage.getItem('userPreferedLanguage') !== 'undefined'
    ) {
      console.log('Using Available Translation Data...');
      const curLang = localStorage.getItem('userPreferedLanguage');

      // verify language ID is correct set, if not always use default ID = 1
      if (nextProps.languages?.length < parseInt(curLang)) {
        this.props.getSingleLanguageData(1);
        this.setState({
          languageId: 1,
        });
        localStorage.setItem('userPreferedLanguage', 1);
      } else {
        this.props.getSingleLanguageData(curLang);
        this.setState({
          languageId: curLang,
        });
      }
    } else if (
      !localStorage.getItem('userPreferedLanguage') ||
      (localStorage.getItem('userPreferedLanguage') === 'undefined' &&
        !this.state.languageId)
    ) {
      console.log('Fetching New Translation Data...');
      const id = nextProps?.languages?.filter(
        (lang) => lang.is_default === 1
      )[0]?.id;

      localStorage.removeItem('userPreferedLanguage');
      localStorage.setItem('userPreferedLanguage', id);

      this.props.getSingleLanguageData(id);
      this.setState({
        languageId: id,
      });
    }
  }

  render() {
    const { languages, language } = this.props;

    return (
      <>
        {window.innerWidth <= 860
          ? language && <Mobile language={language} languages={languages} />
          : language && <Desktop languages={languages} language={language} />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings.settings,
  user: state.user.user,
  notification_token: state.notification_token.notification_token,
  languages: state.languages.languages,
  language: state.languages.language,
});

export default withRouter(
  connect(mapStateToProps, {
    getSettings,
    getSingleLanguageData,
    getAllLanguages,
  })(App)
);
