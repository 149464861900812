import { MAIN_SLIDER } from './actionTypes';

const initialState = {
  main_slider: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case MAIN_SLIDER:
      return { ...state, main_slider: action.payload };
    default:
      return state;
  }
}
