import { BrowserRouter, Route, Switch } from 'react-router-dom';

import App from './components/App';

// import Landing from './components/Desktop/Restaurants';
import Restaurants from './components/Desktop/Restaurants/Restaurants';
import RestaurantDetail from './components/Desktop/Restaurants/RestaurantDetail';
import Offers from './components/Desktop/Offers';
import Search from './components/Desktop/Search';
import Help from './components/Desktop/Help';
import PrivacyPolicy from './components/Desktop/Help/PrivacyPolicy';
import TermsAndConditions from './components/Desktop/Help/TermsAndConditions';
import Cart from './components/Desktop/Cart/Cart';
import DesktopAccount from './components/Desktop/Account/account';
import Categories from './components/Desktop/Restaurants/Categories';
import MobileOffer from './components/Mobile/Offers/Offers';
import PromoRestaurants from './components/Mobile/PromoRestaurants/PromoRestaurants';
import DesktopPromoRestaurants from './components/Desktop/PromoRestaurants/PromoRestaurants';
import Footer from './components/Mobile/Footer';

import Loadable from 'react-loadable';
import Loading from './components/helpers/loading';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import withTracker from './withTracker';

// import NotFound from "./components/NotFound";
const NotFound = Loadable({
  loader: () => import('./components/Shared/Pages/NotFound'),
  loading: () => <Loading />,
});

// import Location from "./components/Mobile/Location";
const Location = Loadable({
  loader: () => import('./components/Mobile/Location'),
  loading: () => <Loading />,
});

// import Items from "./components/Mobile/Items";
const Items = Loadable({
  loader: () => import('./components/Mobile/Items'),
  loading: () => <Loading />,
});

// import Login from "./components/Mobile/Auth/Login";
const Login = Loadable({
  loader: () => import('./components/Mobile/Auth/Login'),
  loading: () => <Loading />,
});

// import Register from "./components/Mobile/Auth/Register";
const Register = Loadable({
  loader: () => import('./components/Mobile/Auth/Register'),
  loading: () => <Loading />,
});

// import CartPage from "./components/Mobile/Cart";
const CartPage = Loadable({
  loader: () => import('./components/Mobile/Cart'),
  loading: () => <Loading />,
});

// import Account from "./components/Mobile/Account";
const Account = Loadable({
  loader: () => import('./components/Mobile/Account'),
  loading: () => <Loading />,
});
const Faqs = Loadable({
  loader: () => import('./components/Mobile/Help/Faqs'),
  loading: () => <Loading />,
});

// import Explore from "./components/Mobile/Explore";
const Explore = Loadable({
  loader: () => import('./components/Mobile/Explore'),
  loading: () => <Loading />,
});

// import Addresses from "./components/Mobile/Account/Addresses";
const Addresses = Loadable({
  loader: () => import('./components/Mobile/Account/Addresses'),
  loading: () => <Loading />,
});

// import SetNewAddress from "./components/Mobile/Account/Addresses/SetNewAddress";
const SetNewAddress = Loadable({
  loader: () => import('./components/Mobile/Account/Addresses/SetNewAddress'),
  loading: () => <Loading />,
});

// import Checkout from "./components/Mobile/Checkout";
const Checkout = Loadable({
  loader: () => import('./components/Mobile/Checkout'),
  loading: () => <Loading />,
});

// import RunningOrder from "./components/Mobile/RunningOrder";
const RunningOrder = Loadable({
  loader: () => import('./components/Mobile/RunningOrder'),
  loading: () => <Loading />,
});

// import Orders from "./components/Mobile/Account/Orders";
const Orders = Loadable({
  loader: () => import('./components/Mobile/Account/Orders'),
  loading: () => <Loading />,
});

// import WalletPage from "./components/Mobile/Account/Wallet";
const WalletPage = Loadable({
  loader: () => import('./components/Mobile/Account/Wallet'),
  loading: () => <Loading />,
});

// import WalletPage from "./components/Mobile/Account/Wallet";
const Profile = Loadable({
  loader: () => import('./components/Mobile/Profile'),
  loading: () => <Loading />,
});

/* Delivery */
// import Delivery from "./components/Delivery";
const Delivery = Loadable({
  loader: () => import('./components/Delivery'),
  loading: () => <Loading />,
});

// import DeliveryLogin from "./components/Delivery/Login";
const DeliveryLogin = Loadable({
  loader: () => import('./components/Delivery/Login'),
  loading: () => <Loading />,
});

// import DeliveryOrders from "./components/Delivery/Orders";
const DeliveryOrders = Loadable({
  loader: () => import('./components/Delivery/Orders'),
  loading: () => <Loading />,
});

// import ViewOrder from "./components/Delivery/ViewOrder";
const ViewOrder = Loadable({
  loader: () => import('./components/Delivery/ViewOrder'),
  loading: () => <Loading />,
});

// import GeoLocationPage from "./components/Mobile/GeoLocationPage";
const GeoLocationPage = Loadable({
  loader: () => import('./components/Mobile/GeoLocationPage'),
  loading: () => <Loading />,
});

// import SingleItem from "./components/Mobile/Items/SingleItem";
const SingleItem = Loadable({
  loader: () => import('./components/Mobile/Items/SingleItem'),
  loading: () => <Loading />,
});

const SinglePage = Loadable({
  loader: () => import('./components/SinglePage'),
  loading: () => <Loading />,
});

const ForgotPassword = Loadable({
  loader: () => import('./components/Mobile/Auth/ForgotPassword'),
  loading: () => <Loading />,
});

const RestaurantList = Loadable({
  loader: () => import('./components/Mobile/Home/RestaurantList'),
  loading: () => <Loading />,
});

const RestaurantListOnCategory = Loadable({
  loader: () => import('./components/Mobile/Home/RestaurantListOnCategory'),
  loading: () => <Loading />,
});

const RatingAndReview = Loadable({
  loader: () => import('./components/Mobile/Account/Orders/RatingAndReview'),
  loading: () => <Loading />,
});

const Alerts = Loadable({
  loader: () => import('./components/Mobile/Alerts'),
  loading: () => <Loading />,
});

const Languages = Loadable({
  loader: () => import('./components/Mobile/Languages'),
  loading: () => <Loading />,
});

const AllCoupons = Loadable({
  loader: () => import('./components/Mobile/Cart/Coupon/AllCoupons'),
  loading: () => <Loading />,
});

const OrderDetails = Loadable({
  loader: () => import('./components/Mobile/Account/Orders/OrderDetails'),
  loading: () => <Loading />,
});

const Support = Loadable({
  loader: () => import('./components/Mobile/Support'),
  loading: () => <Loading />,
});

const Paytm = Loadable({
  loader: () => import('./components/Mobile/Checkout/Paytm.js'),
  loading: () => <Loading />,
});

const PayuMoney = Loadable({
  loader: () => import('./components/Mobile/Checkout/PayuMoney.js'),
  loading: () => <Loading />,
});

// const TestComponent = Loadable({
// 	loader: () => import("./components/Mobile/TestComponent"),
// 	loading: () => <Loading />
// });

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

ReactDOM.render(
  <Root>
    <BrowserRouter>
      <React.Fragment>
        <Route component={ScrollToTop} />
        <Switch>
          {/* <Route exact strict  path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`} />} /> */}
          <Route path={'/'} exact component={withTracker(App)} />
          <Route
            path={'/desktop/restaurants'}
            exact
            component={withTracker(Restaurants)}
          />
          <Route
            path={'/desktop/restaurantDetail/:resSlug'}
            exact
            component={withTracker(RestaurantDetail)}
          />
          <Route
            path={'/desktop/stores/:resSlug'}
            exact
            component={withTracker(RestaurantDetail)}
          />
          <Route path={'/desktop/help'} exact component={withTracker(Help)} />
          <Route
            path={'/desktop/search'}
            exact
            component={withTracker(Search)}
          />
          <Route
            path={'/desktop/offers'}
            exact
            component={withTracker(Offers)}
          />
          <Route
            path={'/desktop/privacyPolicy'}
            exact
            component={withTracker(PrivacyPolicy)}
          />
          <Route
            path={'/desktop/termsAndConditions'}
            exact
            component={withTracker(TermsAndConditions)}
          />
          <Route path={'/desktop/cart'} exact component={withTracker(Cart)} />
          <Route
            path={'/desktop/account'}
            exact
            component={withTracker(DesktopAccount)}
          />
          <Route
            path={'/desktop/categories/:categoryId'}
            exact
            component={withTracker(Categories)}
          />
          <Route
            path={'/desktop/promo-restaurants/:slug'}
            exact
            component={withTracker(DesktopPromoRestaurants)}
          />
          {/* Test Routes*/}
          {/* <Route path={"/saurabh/test"} exact component={TestComponent} /> */}
          <Route
            path={'/search-location'}
            exact
            component={withTracker(Location)}
          />
          <Route
            path={'/my-location'}
            exact
            component={withTracker(GeoLocationPage)}
          />
          {/* <Route path={"/restaurants"} exact component={withTracker(Home)} /> */}
          <Route
            path={'/restaurants'}
            exact
            component={withTracker(RestaurantList)}
          />
          <Route
            path={'/categories/restaurants'}
            exact
            component={withTracker(RestaurantListOnCategory)}
          />
          <Route
            path={'/restaurants/:restaurant'}
            exact
            component={withTracker(Items)}
          />
          <Route
            path={'/restaurants/:restaurant/:id'}
            exact
            component={withTracker(SingleItem)}
          />
          <Route path={'/explore'} exact component={withTracker(Explore)} />
          <Route path={'/login'} exact component={withTracker(Login)} />
          <Route
            path={'/login/forgot-password'}
            exact
            component={withTracker(ForgotPassword)}
          />
          <Route path={'/register'} exact component={withTracker(Register)} />
          <Route path={'/faqs'} exact component={withTracker(Faqs)} />
          <Route path={'/support'} exact component={withTracker(Support)} />
          <Route
            path={'/my-account'}
            exact
            component={withTracker(Account)}
            render={(props) => <Account {...props} />}
          />
          <Route path={'/alerts'} exact component={withTracker(Alerts)} />
          <Route path={'/languages'} exact component={withTracker(Languages)} />
          <Route
            path={'/set-new-address'}
            exact
            component={withTracker(SetNewAddress)}
          />
          <Route
            path={'/my-addresses'}
            exact
            component={withTracker(Addresses)}
          />
          <Route
            path={'/my-wallet'}
            exact
            component={withTracker(WalletPage)}
          />
          <Route path={'/my-orders'} exact component={withTracker(Orders)} />
          <Route
            path={'/order-details/:unique_order_id'}
            exact
            component={withTracker(OrderDetails)}
          />
          <Route path={'/profile'} exact component={withTracker(Profile)} />
          <Route
            path={'/rate-and-review/:id'}
            exact
            component={withTracker(RatingAndReview)}
          />
          <Route path={'/checkout'} exact component={withTracker(Checkout)} />
          <Route
            path={'/running-order/:unique_order_id'}
            exact
            component={withTracker(RunningOrder)}
          />
          <Route path={'/cart'} exact component={withTracker(CartPage)} />
          <Route
            path={'/all-coupons'}
            exact
            component={withTracker(AllCoupons)}
          />
          <Route path={'/offers'} exact component={withTracker(MobileOffer)} />
          <Route
            path={'/promo-restaurants/:slug'}
            exact
            component={withTracker(PromoRestaurants)}
          />
          <Route
            path={'/desktop/promo-restaurants/:slug'}
            exact
            component={withTracker(PromoRestaurants)}
          />
          <Route
            path={'/pages/:slug'}
            exact
            component={withTracker(SinglePage)}
          />
          <Route path={'/paytm'} exact component={withTracker(Paytm)} />
          <Route path={'/payumoney'} exact component={withTracker(PayuMoney)} />
          {/* Delivery Routes */}
          <Route path={'/delivery'} exact component={Delivery} />
          <Route path={'/delivery/login'} exact component={DeliveryLogin} />
          <Route path={'/delivery/orders'} exact component={DeliveryOrders} />
          <Route
            path={'/delivery/orders/:unique_order_id'}
            exact
            component={ViewOrder}
          />
          <Route
            path={'/delivery/completed-orders'}
            exact
            component={Delivery}
          />
          {/* Common Routes */}
          <Route path="*" exact={true} component={NotFound} />
        </Switch>
      </React.Fragment>
      {window.innerWidth <= 768 && <Footer />}
    </BrowserRouter>
  </Root>,
  document.getElementById('root')
);
