const getLanguageWord = (languages) => {
  const localstorage = Object.assign({}, window.localStorage);
  const userPreferedLanguage = localstorage?.userPreferedLanguage | 0;
  return (
    languages &&
    Object.values(languages)?.filter(
      (language) =>
        language.id === userPreferedLanguage ||
        (language?.is_default && !userPreferedLanguage)
    )[0]?.language_word
  );
};

export default getLanguageWord;
