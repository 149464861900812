import { useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-responsive-modal';
import Lottie from 'react-lottie';
import colors from '../../Utils/Colors/colors';
import './styles.scss';

const ModalPopup = (props) => {
  const {
    state,
    headline,
    content,
    option,
    lottie,
    animationData,
    callbackState,
  } = props;
  const [modalState, setModalState] = useState(state);
  const [lottieFinished, setLottieFinish] = useState(false);

  colors();
  const defaultOptions = useMemo(() => {
    return {
      loop: lottie.loop || false,
      autoplay: lottie.autoplay || false,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  }, [animationData, lottie.autoplay, lottie.loop]);

  useEffect(() => {
    if (!modalState) {
      callbackState(false);
    }
  }, [callbackState, modalState]);

  return (
    <Modal
      open={modalState}
      onClose={() => setModalState(false)}
      center
      classNames={{
        overlay: 'react-modal-overlay-wrapper',
        modal: 'react-modal-wrapper',
      }}
      focusTrapped={false}
    >
      <div className="modal-headline">{headline}</div>
      <div className="modal-content">{content}</div>
      {lottie && defaultOptions && (
        <Lottie
          options={defaultOptions}
          height={110}
          width={110}
          // isStopped={lottieFinished}
          // isPaused={lottieFinished}
          eventListeners={
            !lottieFinished
              ? [
                  {
                    eventName: 'loopComplete',
                    callback: () => setLottieFinish(true),
                  },
                ]
              : []
          }
        />
      )}
      {option?.button ? (
        <div className="modal-button-wrapper">
          <Button className="modal-button" onClick={() => setModalState(false)}>
            OK
          </Button>
        </div>
      ) : (
        ''
      )}
    </Modal>
  );
};

export default ModalPopup;
