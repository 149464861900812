import Typography from '@mui/material/Typography';
import './styles.scss';

export default function HandleLanguages({
  languages,
  selectLanguage,
  device,
  position,
}) {
  const localstorage = Object.assign({}, window.localStorage);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getLanguage = () => {
    const userPreferedLanguageId = localstorage.userPreferedLanguage | 0;

    console.log(languages);
    // eslint-disable-next-line array-callback-return
    return (
      languages &&
      // eslint-disable-next-line array-callback-return
      languages?.map((language) => {
        // eslint-disable-next-line default-case
        switch (language.language_name) {
          case 'English':
            return (
              <div
                key={language.language_name}
                className={
                  userPreferedLanguageId === language.id ||
                  (!userPreferedLanguageId && language.is_default)
                    ? 'shared-language-column--prefered-language shared-language-column'
                    : 'shared-language-column'
                }
                onClick={() =>
                  selectLanguage && selectLanguage(language.id, 'Language')
                }
              >
                {position !== 'header' ? (
                  <img
                    src="/assets/img/flag/great-britain-96.png"
                    style={{
                      boxShadow:
                        device !== 'mobile' &&
                        (userPreferedLanguageId === language.id ||
                          (!userPreferedLanguageId && language.is_default))
                          ? '0 0 5px 5px ' + localstorage.storeColor
                          : '',
                    }}
                    alt=""
                  />
                ) : (
                  <span className="shared-language-column--header">
                    {language.language_short}
                  </span>
                )}
                <Typography variant="h6" gutterBottom>
                  {device !== 'mobile' && language.language_name}
                </Typography>
              </div>
            );
          case 'Deutsch':
            return (
              <div
                key={language.language_name}
                className={
                  userPreferedLanguageId === language.id ||
                  (!userPreferedLanguageId && language.is_default)
                    ? 'shared-language-column--prefered-language shared-language-column'
                    : 'shared-language-column'
                }
                onClick={() =>
                  selectLanguage && selectLanguage(language.id, 'Sprache')
                }
              >
                {position !== 'header' ? (
                  <img
                    src="/assets/img/flag/germany-96.png"
                    style={{
                      boxShadow:
                        device !== 'mobile' &&
                        (userPreferedLanguageId === language.id ||
                          (!userPreferedLanguageId && language.is_default))
                          ? '0 0 5px 5px ' + localstorage.storeColor
                          : '',
                    }}
                    alt=""
                  />
                ) : (
                  <span className="shared-language-column--header">
                    {language.language_short}
                  </span>
                )}
                <Typography variant="h6" gutterBottom>
                  {device !== 'mobile' && language.language_name}
                </Typography>
              </div>
            );
          case 'Français':
            return (
              <div
                key={language.language_name}
                className={
                  userPreferedLanguageId === language.id ||
                  (!userPreferedLanguageId && language.is_default)
                    ? 'shared-language-column--prefered-language shared-language-column'
                    : 'shared-language-column'
                }
                onClick={() =>
                  selectLanguage && selectLanguage(language.id, 'Langue')
                }
              >
                {position !== 'header' ? (
                  <img
                    src="/assets/img/flag/french-96.png"
                    style={{
                      boxShadow:
                        device !== 'mobile' &&
                        (userPreferedLanguageId === language.id ||
                          (!userPreferedLanguageId && language.is_default))
                          ? '0 0 5px 5px ' + localstorage.storeColor
                          : '',
                    }}
                    alt=""
                  />
                ) : (
                  <span className="shared-language-column--header">
                    {language.language_short}
                  </span>
                )}
                <Typography variant="h6" gutterBottom>
                  {device !== 'mobile' && language.language_name}
                </Typography>
              </div>
            );
          case 'Español':
            return (
              <div
                key={language.language_name}
                className={
                  userPreferedLanguageId === language.id ||
                  (!userPreferedLanguageId && language.is_default)
                    ? 'shared-language-column--prefered-language shared-language-column'
                    : 'shared-language-column'
                }
                onClick={() =>
                  selectLanguage && selectLanguage(language.id, 'Idioma')
                }
              >
                {position !== 'header' ? (
                  <img
                    src="/assets/img/flag/spanish-96.png"
                    style={{
                      boxShadow:
                        device !== 'mobile' &&
                        (userPreferedLanguageId === language.id ||
                          (!userPreferedLanguageId && language.is_default))
                          ? '0 0 5px 5px ' + localstorage.storeColor
                          : '',
                    }}
                    alt=""
                  />
                ) : (
                  <span className="shared-language-column--header">
                    {language.language_short}
                  </span>
                )}
                <Typography variant="h6" gutterBottom>
                  {device !== 'mobile' && language.language_name}
                </Typography>
              </div>
            );
          case 'Nederlands':
            return (
              <div
                key={language.language_name}
                className={
                  userPreferedLanguageId === language.id ||
                  (!userPreferedLanguageId && language.is_default)
                    ? 'shared-language-column--prefered-language shared-language-column'
                    : 'shared-language-column'
                }
                onClick={() =>
                  selectLanguage && selectLanguage(language.id, 'Taal')
                }
              >
                {position !== 'header' ? (
                  <img
                    src="/assets/img/flag/dutsch-96.png"
                    style={{
                      boxShadow:
                        device !== 'mobile' &&
                        (userPreferedLanguageId === language.id ||
                          (!userPreferedLanguageId && language.is_default))
                          ? '0 0 5px 5px ' + localstorage.storeColor
                          : '',
                    }}
                    alt=""
                  />
                ) : (
                  <span className="shared-language-column--header">
                    {language.language_short}
                  </span>
                )}
                <Typography variant="h6" gutterBottom>
                  {device !== 'mobile' && language.language_name}
                </Typography>
              </div>
            );
          case 'Magyar':
            return (
              <div
                key={language.language_name}
                className={
                  userPreferedLanguageId === language.id ||
                  (!userPreferedLanguageId && language.is_default)
                    ? 'shared-language-column--prefered-language shared-language-column'
                    : 'shared-language-column'
                }
                onClick={() =>
                  selectLanguage && selectLanguage(language.id, 'Nyelv')
                }
              >
                {position !== 'header' ? (
                  <img
                    src="/assets/img/flag/hungary-96.png"
                    style={{
                      boxShadow:
                        device !== 'mobile' &&
                        (userPreferedLanguageId === language.id ||
                          (!userPreferedLanguageId && language.is_default))
                          ? '0 0 5px 5px ' + localstorage.storeColor
                          : '',
                    }}
                    alt=""
                  />
                ) : (
                  <span className="shared-language-column--header">
                    {language.language_short}
                  </span>
                )}
                <Typography variant="h6" gutterBottom>
                  {device !== 'mobile' && language.language_name}
                </Typography>
              </div>
            );
          case 'Srbski':
            return (
              <div
                key={language.language_name}
                className={
                  userPreferedLanguageId === language.id ||
                  (!userPreferedLanguageId && language.is_default)
                    ? 'shared-language-column--prefered-language shared-language-column'
                    : 'shared-language-column'
                }
                onClick={() =>
                  selectLanguage && selectLanguage(language.id, 'Jezik')
                }
              >
                {position !== 'header' ? (
                  <img
                    src="/assets/img/flag/serbian-96.png"
                    style={{
                      boxShadow:
                        device !== 'mobile' &&
                        (userPreferedLanguageId === language.id ||
                          (!userPreferedLanguageId && language.is_default))
                          ? '0 0 5px 5px ' + localstorage.storeColor
                          : '',
                    }}
                    alt=""
                  />
                ) : (
                  <span className="shared-language-column--header">
                    {language.language_short}
                  </span>
                )}
                <Typography variant="h6" gutterBottom>
                  {device !== 'mobile' && language.language_name}
                </Typography>
              </div>
            );
          case 'Polski':
            return (
              <div
                key={language.language_name}
                className={
                  userPreferedLanguageId === language.id ||
                  (!userPreferedLanguageId && language.is_default)
                    ? 'shared-language-column--prefered-language shared-language-column'
                    : 'shared-language-column'
                }
                onClick={() =>
                  selectLanguage && selectLanguage(language.id, 'Język')
                }
              >
                {position !== 'header' ? (
                  <img
                    src="/assets/img/flag/polish-96.png"
                    style={{
                      boxShadow:
                        device !== 'mobile' &&
                        (userPreferedLanguageId === language.id ||
                          (!userPreferedLanguageId && language.is_default))
                          ? '0 0 5px 5px ' + localstorage.storeColor
                          : '',
                    }}
                    alt=""
                  />
                ) : (
                  <span className="shared-language-column--header">
                    {language.language_short}
                  </span>
                )}
                <Typography variant="h6" gutterBottom>
                  {device !== 'mobile' && language.language_name}
                </Typography>
              </div>
            );
        }
      })
    );
  };

  return (
    <>
      <div className="shared-language-column-wrapper">{getLanguage()}</div>
    </>
  );
}
