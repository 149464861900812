import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles/';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { GET_ORDER_DETAIL_URL } from '../../../../configs';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import Skeleton from '@mui/lab/Skeleton';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: useTheme().spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function OrderDetailDrawer(props) {
  const classes = useStyles();
  const history = useHistory();

  // const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [restaurant, setRestaurant] = useState({});

  // useEffect(()=>{
  //   setDrawer(props.drawer)
  // },[props.drawer])

  // useEffect(()=>{
  //   props.setDrawer(drawer)
  // },[props, drawer])

  useEffect(() => {
    if (props.order_id === null) return;

    let user = JSON.parse(localStorage.getItem('user'));
    // setAccount(user)

    Axios.post(GET_ORDER_DETAIL_URL, {
      token: user.auth_token,
      user_id: user.id,
      order_id: props.order_id,
    })
      .then((res) => {
        setOrder(res.data.order);
        setRestaurant(res.data.resturant_details);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [props.order_id]);

  const reOrder = () => {
    history.push(`/desktop/restaurantDetail/${restaurant.slug}`);
  };

  return (
    <>
      <Drawer
        anchor={'right'}
        open={props.drawer}
        onClose={() => props.setDrawer(false)}
        PaperProps={{
          sx: { width: '35%' },
        }}
      >
        <div style={{ padding: 1 }}>
          <IconButton
            onClick={() => props.setDrawer(false)}
            aria-label="close"
            className={classes.margin}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className="container-fluid bg-app">
          {loading && (
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3 bg-white p-3">
                  <Skeleton variant="rect" height={102} />
                  <Skeleton variant="text" style={{ marginTop: 5 }} />
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={50} />
                </div>
              </div>
            </div>
          )}

          {!loading && (
            <div
              className="row bg-white"
              style={{ borderBottom: '1px solid #d0d0d0', paddingBottom: 10 }}
            >
              <div className="col-12">
                <Typography className="ml-2" variant="body1" component="span">
                  <b>ORDER # {order.unique_order_id} </b>
                </Typography>
                <br />
                <Typography
                  style={{ marginLeft: '4rem' }}
                  color="text-secondary"
                  variant="caption"
                  component="span"
                >
                  {order.created_at} | {order.orderitems.length} Items,{' '}
                  {localStorage.getItem('currencyFormat')} {order.payable}
                </Typography>
              </div>
            </div>
          )}

          {!loading && (
            <div className="row bg-white">
              <div className="col-12">
                <div className="p-3">
                  <div className="row">
                    <div className="col-2">
                      <RoomOutlinedIcon className="mr-2" />
                    </div>
                    <div
                      className="col-10"
                      style={{ borderLeft: '1px dashed' }}
                    >
                      <Typography variant="h6">{restaurant.name}</Typography>
                      <Typography variant="body1">
                        {restaurant.address}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-3 mb-1"></div>

              <div className="col-9 mb-1">
                <Button
                  size="small"
                  endIcon={<CloudDownloadOutlinedIcon />}
                  variant="outlined"
                  className="mb-3 mr-2"
                  style={{ borderRadius: 0 }}
                >
                  Invoice
                </Button>
                <Button
                  onClick={() => {
                    window.location = `tel:${restaurant.contact_number}`;
                  }}
                  aria-label="call"
                  size="small"
                  endIcon={<AddIcCallOutlinedIcon />}
                  variant="outlined"
                  className="mb-3"
                  style={{ borderRadius: 0 }}
                >
                  Call Now
                </Button>
              </div>

              <div className="col-12">
                <div
                  className="p-3"
                  style={{
                    borderBottom: '1px solid #d0d0d0',
                    paddingBottom: 10,
                  }}
                >
                  <div className="row">
                    <div className="col-2">
                      <HomeOutlinedIcon className="mr-2" />
                    </div>
                    <div
                      className="col-10"
                      style={{ borderLeft: '1px dashed' }}
                    >
                      <Typography variant="h6">Home</Typography>
                      <Typography variant="body1">{order.address}</Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="p-3">
                  <Typography variant="body2">
                    Order delivered on{' '}
                    {new Date(order.updated_at).toLocaleString()}
                  </Typography>
                </div>
              </div>

              <div className="col-12">
                <Typography variant="body1">
                  <b>BILL DETAILD</b>
                </Typography>
                <div className="row">
                  <div className="col-8">
                    <Typography className="pb-3" variant="body2">
                      Subtotal
                    </Typography>
                  </div>
                  <div className="col-4">
                    <Typography className=" float-right" variant="body2">
                      {localStorage.getItem('currencyFormat')} {order.payable}
                    </Typography>
                  </div>

                  <div className="col-8">
                    <Typography className=" text-secondary" variant="body2">
                      Restaurant Charges (2%)
                    </Typography>
                  </div>
                  <div className="col-4">
                    <Typography
                      className=" text-secondary float-right"
                      variant="body2"
                    >
                      {localStorage.getItem('currencyFormat')}{' '}
                      {order.restaurant_charge}
                    </Typography>
                  </div>

                  <div className="col-8">
                    <Typography className=" text-secondary" variant="body2">
                      Total Discount
                    </Typography>
                  </div>
                  <div className="col-4">
                    <Typography
                      className=" text-secondary float-right"
                      variant="body2"
                    >
                      - {localStorage.getItem('currencyFormat')} 00
                    </Typography>
                  </div>

                  <div className="col-8">
                    <Typography className=" text-secondary" variant="body2">
                      Delivery Charge
                    </Typography>
                  </div>
                  <div className="col-4">
                    <Typography
                      className=" float-right text-secondary"
                      variant="body2"
                    >
                      {localStorage.getItem('currencyFormat')}{' '}
                      {order.delivery_charge}
                    </Typography>
                  </div>

                  <div className="col-8">
                    <Typography className=" text-secondary" variant="body2">
                      TAX (5%)
                    </Typography>
                  </div>
                  <div className="col-4">
                    <Typography
                      className=" text-secondary float-right"
                      variant="body2"
                    >
                      {localStorage.getItem('currencyFormat')} {order.tax}
                    </Typography>
                  </div>

                  {order.driver_tip_amount !== '' && (
                    <>
                      <div className="col-8" style={Styles.bbs}>
                        <Typography className=" text-secondary" variant="body2">
                          Delivery Tip
                        </Typography>
                      </div>
                      <div className="col-4" style={Styles.bbs}>
                        <Typography
                          className="pb-2 text-secondary float-right"
                          variant="body2"
                        >
                          {localStorage.getItem('currencyFormat')}{' '}
                          {order.driver_tip_amount}
                        </Typography>
                      </div>
                    </>
                  )}

                  <div className="col-6">
                    <Typography className="p-2" variant="h6">
                      <b>Total</b>
                    </Typography>
                  </div>
                  <div className="col-6">
                    <Typography className="p-2" variant="h6">
                      <b>
                        {localStorage.getItem('currencyFormat')} {order.payable}
                      </b>
                    </Typography>
                  </div>

                  <div className="col-12 mb-4">
                    <Typography className="p-2" variant="body2">
                      <b>Paid via {order.payment_mode}</b>
                    </Typography>
                  </div>

                  <div className="col-12 mb-1">
                    <Button
                      onClick={() => reOrder()}
                      variant="contained"
                      className="mb-3"
                      style={{
                        height: 45,
                        borderRadius: 0,
                        width: '100%',
                        backgroundColor: 'blue',
                        color: 'white',
                      }}
                    >
                      RE ORDER
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}

const Styles = {
  vegIcon: {
    width: '16px',
    height: '16px',
    position: 'absolute',
    left: '10px',
    top: '10px',
    background: 'white',
  },
  bts: {
    borderTop: '1px solid #ababab',
  },
  btd: {
    borderTop: '1px dashed',
  },
  bbs: {
    borderBottom: '1px solid #ababab',
  },
  bbd: {
    borderBottom: '1px dashed',
  },
};
