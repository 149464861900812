import React, { createContext, useState } from 'react';

// Create the Context
const ContextStore = createContext();

// Create a Provider component that will wrap the entire app
const ReactiveProvider = ({ children }) => {
  // State variables to be shared
  //const [routeState, setRouteState] = useState(false)
  const [changedAddress, setChangedAddress] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  // Functions to update the state
  const changedAddressState = (s) => setChangedAddress(s);
  const loggedInState = (s) => setLoggedIn(s);
  //const changeRouteState = (s) => setRouteState(s)
  // The data and functions to be shared are passed as a value to the context provider
  const contextValue = {
    //routeState,
    //changeRouteState,
    changedAddress,
    changedAddressState,
    loggedIn,
    loggedInState,
  };

  return (
    <ContextStore.Provider value={contextValue}>
      {children}
    </ContextStore.Provider>
  );
};

export { ContextStore, ReactiveProvider };
