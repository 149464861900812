import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  UPDATE_USER_INFO,
  USER_CHANGE_LOGIN_CREDENTIALS,
  USER_CHANGE_PROFILE_DATA,
  SEND_OTP,
  VERIFY_OTP,
  RUNNING_ORDER,
  GET_WALLET_TRANSACTIONS,
  SEND_PASSWORD_RESET_EMAIL,
  VERIFY_PASSWORD_RESET_OTP,
  VERIFY_USER_IS_LOGGED_IN,
  CHANGE_USER_PASSWORD,
  UPDATE_USER_PROFILE,
  SUBMIT_SUPPORT,
} from './actionTypes';

const initialState = {
  user: [],
  running_order: false,
  wallet: [],
  reset_mail: [],
  validate_email_otp: [],
  change_password: [],
  profile_updated: {},
  support_submitted: {},
  loggedIn: false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, user: action.payload, loggedIn: true };
    case REGISTER_USER:
      return { ...state, user: action.payload };
    case LOGOUT_USER:
      return {
        ...state,
        user: action.payload,
        loggedIn: false,
      };
    case VERIFY_USER_IS_LOGGED_IN:
      return { ...state, user: action.payload };
    case USER_CHANGE_LOGIN_CREDENTIALS:
      return { ...state, user: action.payload };
    case USER_CHANGE_PROFILE_DATA:
      return { ...state, user: action.payload };
    case UPDATE_USER_INFO:
      return { ...state, user: action.payload };
    case SEND_OTP:
      return { ...state, user: action.payload };
    case VERIFY_OTP:
      return { ...state, user: action.payload };
    case RUNNING_ORDER:
      return { ...state, running_order: action.payload };
    case GET_WALLET_TRANSACTIONS:
      return { ...state, wallet: action.payload };
    case SEND_PASSWORD_RESET_EMAIL:
      return { ...state, reset_mail: action.payload };
    case VERIFY_PASSWORD_RESET_OTP:
      return { ...state, validate_email_otp: action.payload };
    case CHANGE_USER_PASSWORD:
      return { ...state, change_password: action.payload };
    case UPDATE_USER_PROFILE:
      //profile updated
      // eslint-disable-next-line no-case-declarations
      let updatedUser = state.user;
      updatedUser.data.name = action.payload.name;
      updatedUser.data.photo = action.payload.photo;

      return { ...state, user: updatedUser, profile_updated: action.payload };
    case SUBMIT_SUPPORT:
      return { ...state, support_submitted: action.payload };
    default:
      return state;
  }
}
