import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROOT_URL } from '../../../configs/index';
import { getMainSlider } from '../../../services/slider/actions';
import Slider from 'react-slick';

var settings = {
  dots: false,
  infinite: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 100,
  speed: 8000,
  cssEase: 'linear',
  variableWidth: true,
  centerMode: true,
  centerPadding: '30px',
  lazyLoad: 'ondemand',
  focusOnSelect: true,
  slidesToShow: 2,
  slidesToScroll: 1,
};

const MainSlider = ({ device }) => {
  const dispatch = useDispatch();
  const slider = useSelector((state) => state?.main_slider);

  const [mainSlider, setMainSlider] = useState(slider);
  const [slidesLoaded, setSlidesLoaded] = useState(0);

  const handleOnLoad = (e) => {
    var elems = document.querySelectorAll('.main-slider-card');
    [].forEach.call(elems, function (el) {
      if (
        slidesLoaded <=
          mainSlider?.main_slider[0]?.images_container?.slider_images.length &&
        !el.classList.contains('loading-slick-img')
      ) {
        el.classList.add('loading-slick-img', 'css-loading-spinner');
      } else {
        el.classList.remove('loading-slick-img', 'css-loading-spinner');
        el.children[0].children[0].style.visibility = 'visible';
      }
    });

    setSlidesLoaded(slidesLoaded + 1);
  };

  useEffect(() => {
    if (!slider?.main_slider?.length) {
      dispatch(getMainSlider());
    }
    if (slider?.main_slider?.length) {
      setMainSlider(slider);
    }
  }, [dispatch, slider, slider?.main_slider?.length]);

  if (
    !mainSlider?.main_slider[0]?.is_active ||
    !mainSlider?.main_slider[0]?.devices.length ||
    (device === 'mobile' &&
      !mainSlider?.main_slider[0]?.devices.includes('mobile')) ||
    (device === 'desktop' &&
      !mainSlider?.main_slider[0]?.devices.includes('desktop'))
  ) {
    return null;
  }

  return (
    <>
      {mainSlider?.main_slider[0]?.type === 'slider' ? (
        <>
          {mainSlider?.main_slider[0]?.title !== '' && (
            <div
              className="main-slider-title"
              style={{
                background: mainSlider?.main_slider[0]?.title_background_style,
              }}
            >
              <div className="main-slider-title-text">
                {mainSlider?.main_slider[0]?.title}
              </div>
            </div>
          )}

          <Slider {...settings}>
            {mainSlider?.main_slider[0]?.images_container?.slider_images.map(
              (item, i) => (
                <div key={i} className="main-slider-card" onLoad={handleOnLoad}>
                  <a href={item.targetUrl}>
                    <img
                      className="main-slider-card-img"
                      src={`${ROOT_URL}${item?.imageSource}`}
                      alt="slider"
                    />
                  </a>
                </div>
              )
            )}
          </Slider>
        </>
      ) : (
        <>
          {mainSlider?.main_slider[0]?.title !== '' && (
            <div
              className="main-slider-title"
              style={{
                background: mainSlider?.main_slider[0]?.title_background_style,
              }}
            >
              <div className="main-slider-title-text">
                {mainSlider?.main_slider[0]?.title}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-sm-12 col-md-12 main-slider-banner-img">
              <a
                href={
                  mainSlider?.main_slider[0]?.images_container?.banner_image
                    ?.targetUrl
                }
              >
                <img
                  style={{ width: '100%', height: 'auto' }}
                  src={`${ROOT_URL}${mainSlider?.main_slider[0]?.images_container?.banner_image?.imageSource}`}
                  alt="HotPot Africa"
                  className="footer-logo mr-2"
                />
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MainSlider;
