import React from 'react';
import registerServiceWorker from './register-service-worker';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { OnlineStatusProvider } from './configs/lib/OnlineStatusProvider';
import { ReactiveProvider } from './configs/lib/ReactiveStatusProvider';
import { WindowStatusProvider } from './configs/lib/WindowStatusProvider';

import { Provider } from 'react-redux';
import store from './services/store';
// import CheckVersion from "./components/CheckVersion";
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';

const Root = ({ children, initialState = {} }) => {
  return (
    <React.Fragment>
      {/* {checkVersion()} */}
      <WindowStatusProvider>
        <GoogleOAuthProvider clientId="360567930760-3urs6ng2ih1vih50uhr28sposqu9pj6s.apps.googleusercontent.com">
          <OnlineStatusProvider>
            <ReactiveProvider>
              <SnackbarProvider>
                <CookiesProvider>
                  <Provider store={store(initialState)}>
                    {children}
                    {/*<CheckVersion />*/}
                  </Provider>
                </CookiesProvider>
              </SnackbarProvider>
            </ReactiveProvider>
          </OnlineStatusProvider>
        </GoogleOAuthProvider>
      </WindowStatusProvider>
    </React.Fragment>
  );
};

registerServiceWorker();

export default Root;
