import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import { useHistory } from "react-router-dom";
import TuneOutlined from '@mui/icons-material/TuneOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';

export default function ItemFiltersDrawer(props) {
  const localstorage = Object.assign({}, window.localStorage);
  // const history = useHistory();

  const [login, setDrawer] = useState(false);
  // const [message, setMessage] = useState('');
  const [filters, setFilters] = useState([]);

  const toggleFilter = (v) => {
    if (filters.includes(v)) {
      setFilters(filters.filter((i) => i !== v));
    } else {
      setFilters([...filters, v]);
    }
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: 'white',
          borderRadius: 3,
          color: localstorage.storeColor || '#000',
        }}
        variant="contained"
        color="primary"
        size="small"
        className="ml-1 mr-1"
        onClick={() => setDrawer(true)}
        startIcon={<TuneOutlined />}
      >
        Filter
      </Button>

      <Drawer
        style={{ width: '28%' }}
        anchor={'right'}
        open={login}
        onClose={() => setDrawer(false)}
      >
        <div style={{ padding: 1 }}>
          <IconButton onClick={() => setDrawer(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ padding: 25 }}>
          <div className="row bg-white">
            <div className="col-12">
              <div className="p-3">
                <FormControl className="w-100" component="fieldset">
                  <Typography variant="h5" gutterBottom className="mt-3">
                    Filters
                  </Typography>
                  <div className="row">
                    <div className="col-6">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Veg')}
                            onChange={() => toggleFilter('Veg')}
                            name="Veg"
                          />
                        }
                        label="Veg"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Nut Free')}
                            onChange={() => toggleFilter('Nut Free')}
                            name="Nut Free"
                          />
                        }
                        label="Nut Free"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Dairy Free')}
                            onChange={() => toggleFilter('Dairy Free')}
                            name="Dairy Free"
                          />
                        }
                        label="Dairy Free"
                      />
                    </div>
                    <div className="col-6">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Vegan')}
                            onChange={() => toggleFilter('Vegan')}
                            name="Vegan"
                          />
                        }
                        label="Vegan"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Halal')}
                            onChange={() => toggleFilter('Halal')}
                            name="Halal"
                          />
                        }
                        label="Halal"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Gluten Free')}
                            onChange={() => toggleFilter('Gluten Free')}
                            name="Gluten Free"
                          />
                        }
                        label="Gluten Free"
                      />
                    </div>
                  </div>

                  <div
                    className="p-2"
                    style={{ width: '21%', position: 'fixed', bottom: 0 }}
                  >
                    <Button
                      onClick={() => props.filterItems(filters)}
                      style={{
                        width: '100%',
                        marginTop: '3rem',
                        backgroundColor: '#c119b0',
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      Apply
                    </Button>
                  </div>
                </FormControl>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </Drawer>
    </>
  );
}
