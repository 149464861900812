import React, { useState } from 'react';

// import { connect } from "react-redux";
import Header from '../Layouts/Header';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';

export default function Help() {
  const history = useHistory();

  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="container-fluid p-0">
      <Header />
      <div
        className="row"
        style={{
          position: 'sticky',
          top: 0,
          marginTop: 64,
          padding: '40px 50px',
          backgroundImage:
            'linear-gradient(to right, rgb(196, 25, 175), rgb(114, 32, 227))',
          color: 'white',
        }}
      >
        <div className="col-md-12">
          <h3 style={{ color: 'white', marginBottom: 5 }}>Help & Support</h3>
          <h6 style={{ color: 'white', marginBottom: 0 }}>
            Let's take a step ahead and help you better.
          </h6>
        </div>
      </div>

      <div
        className="row"
        style={{
          padding: 50,
          backgroundImage:
            'linear-gradient(to right, rgb(196, 25, 175), rgb(114, 32, 227))',
          color: 'white',
          paddingTop: 0,
        }}
      >
        <div className="col-md-2 p-0" style={{ backgroundColor: '#d3eaff' }}>
          <Tabs
            value={tab}
            orientation="vertical"
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            onChange={handleTabChange}
            aria-label="Help"
            style={{ marginTop: 20 }}
          >
            <Tab label="Legal" />
            <Tab label="FAQs" />
          </Tabs>
        </div>
        <div
          className="col-md-10"
          style={{ backgroundColor: '#fff', padding: 40, minHeight: 540 }}
        >
          {tab === 0 && (
            <div
              Style={{ padding: 20 }}
              role="tabpanel"
              id="vertical-tabpanel-0"
              aria-labelledby="vertical-tabpanel-0"
            >
              <h3>Legal</h3>

              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h6">Privacy Policy</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    HotPotAfrica is a online food delivery service .By visiting
                    our website www.hotpotafrica.com and our app "HotPotAfrica
                    App" jointly referred to as the "Platform", you provide us
                    with some personal data.
                    <br />
                    We HotPotAfrica are fully committed to respecting your
                    privacy, protecting your personal information and ensuring
                    it is safe with us. This privacy policy sets out the
                    information practices, with respect to the use of our
                    website including the type of information collected, how the
                    information is collected, how the information is used and
                    with whom it is shared.
                    <br />
                    <br />
                    <Button
                      onClick={() => history.push('/desktop/privacyPolicy')}
                    >
                      Read More
                    </Button>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h6">Terms & Conditions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    This website is owned and managed by HotPotAfrica. By
                    accessing and using the “www.hotpotafrica.com” web site, you
                    are legally bound by these Terms & conditions. The terms
                    “you” and “User” refer to anyone who accesses the web site.
                    <br />
                    As you browse through the website (www.hotpotafrica.com) and
                    HotPotAfrica sites you may access other web sites that are
                    subject to different terms of use. When you use those sites,
                    you will be legally bound by the specific terms of use
                    posted on such sites. If there is a conflict between these
                    Terms & Conditions and the other terms and condition, the
                    other terms & conditions will govern with respect to use of
                    such pages
                    <br />
                    <br />
                    <Button
                      onClick={() =>
                        history.push('/desktop/termsAndConditions')
                      }
                    >
                      Read More
                    </Button>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          )}

          {tab === 1 && (
            <div
              style={{ padding: 20 }}
              role="tabpanel"
              id="vertical-tabpanel-1"
              aria-labelledby="vertical-tabpanel-1"
            >
              <h3>FAQs</h3>

              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">What is HotPotAfrica?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    HotPotAfrica is a food home delivery service with the range
                    of 500 restaurants / outlets in DHANANJAY
                    <br />
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    Where is HotPotAfrica registered and where is its Head
                    office?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We are Registered at or our Head Office is currently at;
                    <br />
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    How can you place an order with HotPotAfrica?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can place an order with HotPotAfrica, in any one of the
                    following modes: (i) By PHONE – just call 123456789..
                    <br />
                    (ii) Online – just login to www.hotpotafrica.com and place
                    your order and have the option of paying online (via a
                    secured Online Payment mode OR by using the HotPotAfrica
                    Prepaid Account mode)
                    <br />
                    (iii) Use our mobile application app – HotPotAfrica
                    <br />
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    What are the office hours?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We are open Every Day 07:00 AM to 11:59 PM for your food
                    booking needs at any time, day or night, at
                    www.hotpotafrica.com Should you rather talk to one of our
                    helpful customer service representatives, they are available
                    from 7:00 AM to 11:59 PM seven days a week.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    Forgot your Username and password?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    DO NOT STRESS – your Username is your Registered Mobile
                    Number.
                    <br />
                    In case of Forgotten Password – we will set this right,
                    almost immediately:
                    <br />
                    (i) Click LOGIN on the top right of the site page to access
                    the login screen.
                    <br />
                    (ii) Click Forgot Password? Link available on the bottom of
                    the login screen.
                    <br />
                    (iii) Enter your mobile number in the displayed field.
                    <br />
                    (iv) We will send you a new password to your registered
                    e-mail ID.
                    <br />
                    (v) IF you DO NOT have an email ID registered with us – just
                    call us (123456789.), and we will register your email ID,
                    immediately.
                    <br />
                    (vi) You can use the Password, provided in the email, to
                    login.
                    <br />
                    (vii) Remember to reset your password, instantly.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    Are the prices listed on this website accurate and binding?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We make every effort to keep the prices updated and
                    accurate. Prices listed on this website are however
                    approximate and subject to changes by the Restaurant and
                    Taxes.
                    <br />
                    The final price is as per the ACTUAL BILL raised by the
                    Restaurant and presented to you, at the time of the
                    delivery.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    What if my amount is deducted and I didn’t get confirmation
                    message?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    In such case, call our executives and check whether the
                    order is placed or not. Due to mobile network issues, there
                    can be delay in receiving the order confirmation message. DO
                    NOT WORRY! There’s 100% probability that the order is placed
                    from HotPotAfrica end and will be delivered at your place of
                    order.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    How do I use promo codes?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Once you’ve added all the items you wish to order to your
                    bag, press “Checkout.” Underneath the “Tip amount” box
                    (don’t forget about the tip amount box!), enter your promo
                    code and press “Add.” Your bag will now reflect the
                    discount. If you have multiple promos applied to your
                    account, simply select the one you wish to use by clicking
                    on it. In most cases, you cannot combine promotions or other
                    offers.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    {' '}
                    How much is the delivery fees?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Our minimum delivery fee is Rs.20, but it varies by
                    restaurant. We have a tier fee system in place, so the
                    closer you are to the restaurant, the cheaper it will be.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    What’s the minimum order size?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The minimum order size is Rs 99 (excluding taxes and our
                    delivery fee).
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    Do I need to pay taxes on my order?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes. All prices mentioned on the website are exclusive of
                    taxes.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    Do I need to tip the delivery boy?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    No, you do not need to tip the delivery boy. The meal price
                    of HotPotAfrica charges is inclusive of delivery cost. In
                    fact, we would suggest that you do not pay any extra amount
                    that your pre-agreed price.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    Where is my personal information used?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We do not share your personal details (phone number and mail
                    ID) with the delivery restaurants. However, we may use your
                    number/mail id to send you promotional offers about
                    HotPotAfrica. We take security seriously and we take
                    precautions to keep your personal information secure. We
                    have put in place appropriate physical, electronic and
                    managerial procedures to safeguard the information we
                    collect. However, due to the open communication nature of
                    the Internet, HotPotAfrica cannot guarantee that
                    communications between you and HotPotAfrica or information
                    stored on HotPotAfrica servers, will be free from
                    unauthorized access by third parties.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    What if internet connection is lost at any step in between?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    If you received confirmation SMS, your order is booked. If
                    you did not receive the order confirmation SMS, your
                    transaction did not complete and order was not placed. You
                    would have to place the order again online or call our
                    helpful customer service representatives at 123456789 to
                    help you complete your order.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    I don’t have access to the Internet. Can I still use your
                    services?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Of course! Our helpful customer service representatives are
                    ready to take your order from 7:00 AM to 11:59 PM at
                    123456789..
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    I am stuck and can’t figure out how to order…?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    No issues. Give us a call at 123456789 between 7:00 AM and
                    11:59 PM and our helpful customer service representatives
                    will assist you to complete the order.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    How does HotPotAfrica work?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We deliver for our customers from anywhere our customers
                    would like. We take your order by internet and place it with
                    the restaurant. We dispatch a meal courier to pick up the
                    food, pack it in an insulated carrier and deliver it
                    directly to you. Usually, your meal courier will be waiting
                    at the restaurant when the food comes out of the kitchen. We
                    are not affiliated with some of the restaurants and in no
                    way are we claiming to be in business with some of the
                    businesses listed, unless otherwise stated.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    How long does it take for a delivery?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Our normal delivery time is between 50 minutes however
                    certain situations such as traffic, weather, and restaurant
                    preparation time require extra time. Please know that we are
                    always working hard to get your food delivered as quickly as
                    possible. We appreciate your patience. Placing orders in
                    advance is appreciated.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    Why are there more restaurants in the delivery catalog than
                    on the website?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We deliver for restaurants all over the city. When you log
                    in to the website, the restaurants within your general
                    delivery range will appear. Payments: -
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    What mode of payments are acceptable using HotPotAfrica?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can use any ONE of the following Payment Modes, using
                    HotPotAfrica…
                    <br />
                    (i) Cash on Delivery.
                    <br />
                    (ii) Online Payment – using Credit Card / Debit Card / Net
                    Banking.
                    <br />
                    (iii) HotPotAfrica Prepaid Account.
                    <br />
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">When I have to pay?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You will have to pay at the time of delivery as per the
                    payment details we send to you via email.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    What payment modes are accepted?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can pay by cash or cheque at the time of delivery. We
                    will soon be introducing more payment methods.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    Shall I buy the goods on credit?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Sorry, we don’t allow a credit on goods currently.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">Can I pay online?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can do payU, airtel money, phone pay, Google pay, Debit
                    card, credit card, Net Banking. We will be sharing the
                    details via email.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">Will I get an invoice?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes. You will get your invoice from our supplier at the time
                    of delivery.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    What is your Refund Policy?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The customer is financially responsible for payment once an
                    order is submitted. If you want to change your order, we
                    will attempt to accommodate such wishes within the time
                    constraints and the good will of the participating
                    restaurants. However, if a change is too late to process,
                    you are responsible for payment of the original order.
                    Changes to orders must be phoned to the staff. Our e-mail is
                    not checked often enough to ensure that we get your changes.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
