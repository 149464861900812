import React, { createContext, useEffect, useCallback } from 'react';

// Create the Context
const ContextStore = createContext();

// Create a Provider component that will wrap the entire app
const WindowStatusProvider = ({ children }) => {
  // trigger resize if the window width matches 768px
  const mq = window.matchMedia('(max-width: 860px)');

  const constructMobileURL = (device) => {
    let newLocation = '';
    const protocol = window.location.protocol;
    const location = window.location.href;
    const regex = /([^/]+)\/?$/;
    let rootPath = window.location.href.match(regex);

    if (rootPath[1] === `${window.location.host}`) {
      rootPath = [];
    } else {
      rootPath = rootPath[1];
    }

    if (
      /desktop/.test(location) &&
      !/restaurantDetail/.test(location) &&
      !/account/.test(location) &&
      device === 'mobile'
    ) {
      newLocation = `${protocol}//${window.location.host}/restaurants`;
    } else if (
      rootPath?.length &&
      /restaurantDetail/.test(location) &&
      device === 'mobile'
    ) {
      newLocation = `${protocol}//${window.location.host}/restaurants/${rootPath}`;
    } else if (/restaurants/.test(location) && device === 'desktop') {
      if (rootPath?.length) {
        console.log(location.indexOf(rootPath[1]), location, rootPath);
        newLocation = `${protocol}//${window.location.host}/desktop/restaurantDetail/${rootPath}`;
      } else {
        newLocation = `${protocol}//${window.location.host}/desktop/restaurants`;
      }
    } else if (/account/.test(location) && device === 'mobile') {
      newLocation = `${protocol}//${window.location.host}/my-account`;
    } else if (/my-account/.test(location) && device === 'desktop') {
      newLocation = `${protocol}//${window.location.host}/desktop/account`;
    } else {
      return window.location.reload();
    }

    window.location.href = newLocation;
  };

  // toggle viewports based on matchMedia event
  const toggle = useCallback(() => {
    if (mq.matches) {
      // responsive mobile / tablet viewport <= 768px
      constructMobileURL('mobile');
    } else {
      // responsive desktop viewport >= 768px
      constructMobileURL('desktop');
    }
  }, [mq.matches]);

  useEffect(() => {
    mq.addListener(toggle);

    // unmount cleanup handler
    return () => mq.removeListener(toggle);
  }, [mq, toggle]);

  return <ContextStore.Provider>{children}</ContextStore.Provider>;
};

export { ContextStore, WindowStatusProvider };
