import {
  GET_ALL_LANGUAGES,
  GET_SINGLE_LANGUAGE_DATA,
  SET_SINGLE_LANGUAGE_DATA,
} from './actionTypes';

export const initialState = {
  languages: [],
  language: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LANGUAGES:
      return { ...state, languages: action.payload };
    case GET_SINGLE_LANGUAGE_DATA:
      return { ...state, language: action.payload };
    case SET_SINGLE_LANGUAGE_DATA:
      return { ...state, language: action.payload };
    default:
      return state;
  }
}
