import React, { Component } from 'react';

import ContentLoader from 'react-content-loader';
import LazyLoad from 'react-lazyload';
import { NavLink } from 'react-router-dom';
import Ink from 'react-ink';
import { ROOT_URL } from '../../../../configs/index';

class PromoSlider extends Component {
  state = {
    slide: 'right',
  };

  // componentDidMount(){
  // 	this.initAutoSlide();
  // }

  initAutoSlide() {
    var size = this.props.slides.length - 2;
    var i = size;
    if (this.state.slide === 'right') {
      for (i = size; i > 0; i--) {
        this.loopNow(i, 180, 'left', size);
      }
    } else {
      for (i = size; i > 0; i--) {
        this.loopNow(i, -180, 'right', size);
      }
    }
  }

  loopNow(i, val, side, length) {
    setTimeout(() => {
      if (document.getElementById('bannerSlider') == null) return true;
      document.getElementById('bannerSlider').scrollBy(val, 0);
      if (i === length) {
        this.setState({ slide: side });
      }
    }, 3000 * i);
  }

  render() {
    const { slides, secondarySlider } = this.props;
    return (
      <React.Fragment>
        {/*this.initAutoSlide()*/}
        <div
          id="bannerSlider"
          style={{ marginTop: '3.5rem!important' }}
          className={
            secondarySlider
              ? 'slider-wrapper1 secondary-slider-wrapper my-20'
              : 'slider-wrapper1 bg-light py-20 my-0'
          }
        >
          {slides.length === 0 ? (
            <ContentLoader
              height={170}
              width={400}
              speed={1.2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
              <rect x="228" y="0" rx="4" ry="4" width="168" height="168" />
            </ContentLoader>
          ) : (
            slides.map((slide) =>
              slide.url.indexOf('http://') === 0 ||
              slide.url.indexOf('https://') === 0 ? (
                <a
                  href={'/restaurants/' + slide.url.split('/')[1]}
                  className="slider-wrapper__img-wrapper"
                  key={slide.id}
                  style={{ position: 'relative' }}
                >
                  {/* if customURL then use anchor tag */}
                  <LazyLoad>
                    <img
                      src={ROOT_URL + '/public/' + slide.image}
                      alt={slide.name}
                      style={{ objectFit: 'scale-down' }}
                      className={`slider-wrapper__img slider-cust-img ${
                        !secondarySlider && 'slider-wrapper__img-shadow'
                      } `}
                    />
                  </LazyLoad>
                  <Ink duration="500" hasTouch={true} />
                </a>
              ) : (
                <NavLink
                  to={'/promo-restaurants/' + slide.url}
                  className="slider-wrapper__img-wrapper"
                  key={slide.id}
                  style={{ position: 'relative' }}
                >
                  <LazyLoad>
                    <img
                      src={ROOT_URL + '/public/' + slide.image}
                      alt={slide.name}
                      className={`slider-wrapper__img slider-cust-img ${
                        !secondarySlider && 'slider-wrapper__img-shadow'
                      } `}
                    />
                  </LazyLoad>
                  <Ink duration="500" hasTouch={true} />
                </NavLink>
              )
            )
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default PromoSlider;
