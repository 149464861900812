import { MAIN_SLIDER } from './actionTypes';
import { GET_MAIN_SLIDER_URL } from '../../configs';
import Axios from 'axios';

export const getMainSlider = () => (dispatch) => {
  Axios.post(GET_MAIN_SLIDER_URL)
    .then((response) => {
      const main_slider = response.data;
      return dispatch({ type: MAIN_SLIDER, payload: main_slider });
    })
    .catch(function (error) {
      console.log(error);
    });
};
