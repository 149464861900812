import React, { useState, useEffect } from 'react';

import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { ROOT_URL } from '../../../configs';

const CartDrawer = ({ cart, show, callback }) => {
  const history = useHistory();
  const cartStorage = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : null;
  const [cartState, setCart] = useState(cartStorage || cart);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    setShowDrawer(show);
  }, [show]);

  useEffect(() => {
    setCart(cart);
  }, [cart]);

  console.log('CARTSTATE: ', cartState);

  const incrementCart = (citem) => {
    console.log(citem);
    let item = cartState.filter((i) => i.id === citem.id)[0];
    let newItems = cartState.filter((i) => i.id !== citem.id);
    item.quantity = item.quantity + 1;
    item.cartPrice = Number(item.price) * item.quantity;
    newItems.unshift(item);
    setCart(newItems);
    localStorage.setItem('cart', JSON.stringify(newItems));
  };

  const decrementCart = (citem) => {
    let item = cartState.filter((i) => i.id === citem.id)[0];
    if (item.quantity === 1) {
      let newItems = cartState.filter((i) => i.id !== citem.id);
      item.cartPrice = item.cartPrice - Number(item.price);
      setCart(newItems);
      localStorage.setItem('cart', JSON.stringify(newItems));
      if (newItems.length === 0) {
        localStorage.removeItem('cart');
      }
    } else {
      let newItems = cartState.filter((i) => i.id !== citem.id);
      item.quantity = item.quantity - 1;
      item.cartPrice = Number(item.price) * item.quantity;
      newItems.unshift(item);
      setCart(newItems);
      localStorage.setItem('cart', JSON.stringify(newItems));
    }
  };

  function subTotal() {
    let total = 0;
    cartState.map((i) => (total += Number(i?.cartPrice || i?.price)));
    return String(total.toFixed(2)).replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
  }

  const handleShowDrawer = () => {
    setShowDrawer(!showDrawer);
    callback(!showDrawer);
  };

  return (
    <Drawer
      anchor={'right'}
      open={showDrawer}
      onClose={() => handleShowDrawer()}
    >
      <div style={{ padding: 1 }}>
        <IconButton onClick={() => handleShowDrawer()} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <div
        className="w-100"
        style={{
          borderLeft: '1px solid #b7b7b7',
          position: 'sticky',
          zIndex: 9,
          height: '100%',
        }}
      >
        <div style={{ height: '100%', padding: 20 }}>
          <h4 className={'mb-0'}>
            <b>Cart</b>
          </h4>
          <Typography variant="caption" color="textSecondary">
            {cartState.length} ITEM
          </Typography>

          {cartState.length !== 0 && (
            <>
              <div className="cart-drawer-item-list-wrapper">
                {cartState
                  .sort((a, b) =>
                    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                  )
                  .map((item, i) => {
                    return (
                      <div key={i} className="cart-drawer-item-list-row">
                        <img
                          // src={ROOT_URL + "/public/" + item.image}
                          src={ROOT_URL + item.image}
                          alt="HotpotAfrica"
                          style={{
                            width: '25%',
                            borderRadius: 4,
                            objectFit: 'cover',
                          }}
                        />
                        {/*item.is_veg === 1 && (
                              <img
                                alt="..."
                                className="mr-2"
                                src="/assets/img/various/veg-icon.png"
                                style={{
                                  width: 18,
                                  height: 18,
                                  display: 'inline',
                                }}
                              />
                            )}
                            {item.is_veg !== 1 && (
                              <img
                                alt="..."
                                className="mr-2"
                                src="/assets/img/various/non-veg-icon.png"
                                style={{
                                  width: 18,
                                  height: 18,
                                  display: 'inline',
                                }}
                              />
                              )*/}
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          style={{ minWidth: 55, margin: '0 10px' }}
                        >
                          {item.name}
                        </Typography>
                        <div className="col-md-3">
                          <div
                            className="MuiButton-contained"
                            style={{
                              background: 'white',
                              color: localStorage.getItem('storeColor'),
                              padding: 3,
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <IconButton
                                onClick={() => decrementCart(item)}
                                size="small"
                                aria-label="delete"
                              >
                                <RemoveIcon />
                              </IconButton>
                              <p
                                style={{
                                  paddingLeft: 7,
                                  paddingRight: 7,
                                  display: 'inline-block',
                                  marginBottom: 0,
                                }}
                              >
                                {
                                  cartState.filter((a) => a.id === item.id)[0]
                                    .quantity
                                }
                              </p>
                              <IconButton
                                onClick={() => incrementCart(item)}
                                size="small"
                                aria-label="plus"
                              >
                                <AddIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 cart-drawer-line-price">
                          <h6
                            className={`restaurant-item-pricing-alignment--${localStorage.getItem(
                              'currencySymbolAlign'
                            )}`}
                          >
                            <div
                              className={`${localStorage.getItem(
                                'currencySymbolAlign'
                              )} cart-drawer-line-currency`}
                            >
                              {localStorage.getItem('currencyFormat')}{' '}
                            </div>
                            <div className="cart-drawer-line-price-currency">
                              {Number(item?.cartPrice || item?.price)
                                .toFixed(2)
                                .toString()
                                .replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                            </div>
                          </h6>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div
                style={{
                  position: 'absolute',
                  bottom: '25',
                  width: '89%',
                }}
              >
                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-md-8">
                    <Typography variant="h6" style={{ marginBottom: 0 }}>
                      Subtotal
                    </Typography>
                    <Typography
                      className="mt-0"
                      variant="caption"
                      color="textSecondary"
                    >
                      Extra charges may apply
                    </Typography>
                  </div>
                  <div className="col-md-4">
                    <Typography
                      style={{
                        textAlign: 'center',
                        justifyContent: 'flex-start',
                      }}
                      variant="h6"
                      className={`restaurant-item-pricing-alignment--${localStorage.getItem(
                        'currencySymbolAlign'
                      )}`}
                    >
                      <div
                        className={`${localStorage.getItem(
                          'currencySymbolAlign'
                        )}`}
                      >
                        {localStorage.getItem('currencyFormat')}{' '}
                      </div>
                      <div>{subTotal()}</div>
                    </Typography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <Button
                      onClick={() => history.push('/desktop/cart')}
                      style={{
                        width: '100%',
                        height: 45,
                        backgroundColor: localStorage.getItem('storeColor'),
                        color: 'white',
                        borderRadius: 4,
                        marginTop: 15,
                      }}
                      variant="contained"
                    >
                      Checkout
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}

          {cartState.length === 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                className="col-md-9 mt-3"
                style={{ width: '100%', marginBottom: 20 }}
                alt="empty cart"
                src="/assets/img/empty.png"
              />
              <Typography variant="body1">
                Good food is always cooking! <br />
                Go ahead, order some yummy items from the menu.
              </Typography>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default CartDrawer;
