import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ContextStore } from '../../../configs/lib/ReactiveStatusProvider';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { getDeliveryRestaurants } from '../../../services/restaurant/actions';
import HandleGooglePlacesAutocomplete from '../../Shared/HandleGooglePlacesAutocomplete';
import LanguageDrawer from '../../Shared/Language/LanguageDrawer';
import LoginDrawer from '../Auth/LoginDrawer';
import SignupDrawer from '../Auth/SignupDrawer';
import PopularPlaces from '../../Shared/PopularPlaces';
import getLanguageWord from '../../Shared/Lib/getLanguageWord';
import { ROOT_URL } from '../../../configs/index';
import CartIcon from '../../Shared/CartIcon';
import { useOnlineStatus } from '../../../configs/lib/OnlineStatusProvider';
import OffLine from '../../Shared/Pages/OffLine';
import Footer from '../Layouts/Footer';
import './styles.scss';

export default function StartScreen({ user, languages, language, loading }) {
  const isOnline = useOnlineStatus();
  const { loggedIn } = useContext(ContextStore);
  const localstorage = Object.assign({}, window.localStorage);
  const restaurants = useSelector(
    (state) => state.restaurant.delivery_restaurants
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const [restaurantCoordinates, setRestaurantCoordinates] = useState([]);
  const [restaurantsOnLocation, getRestaurantsOnLocation] = useState();
  const [languageWord, setLanguageWord] = useState();
  const [login, showLogin] = useState(false);
  const [registry, showRegistry] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      showLogin(false);
      showRegistry(false);
    } else {
      showLogin(true);
      showRegistry(true);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (languages) {
      setLanguageWord(getLanguageWord(languages));
    }
  }, [languages, setLanguageWord, dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPopularLocationsDisplay = useCallback((restaurants) => {
    const highratedRestaurants = restaurants.filter(
      (item) => item.is_popular === 1 && parseFloat(item.rating) > 4
    );
    highratedRestaurants.length &&
      getRestaurantsOnLocation(highratedRestaurants);
  });

  useEffect(() => {
    if (restaurantCoordinates.length && !restaurants.length) {
      console.log(restaurantCoordinates[0], restaurantCoordinates[1]);
      dispatch(
        getDeliveryRestaurants(
          restaurantCoordinates[0],
          restaurantCoordinates[1]
        )
      );
    }
  }, [
    dispatch,
    restaurantCoordinates,
    restaurantCoordinates.length,
    restaurants.length,
  ]);

  useEffect(() => {
    if (restaurants.length > 0 && !restaurantsOnLocation?.length) {
      getPopularLocationsDisplay(restaurants);
    }
  }, [getPopularLocationsDisplay, restaurants, restaurantsOnLocation]);

  const handlePopularLocationClick = (results) => {
    const saveGeoLocation = new Promise((resolve) => {
      localStorage.setItem('geoLocation', JSON.stringify(results[0]));
      resolve('GeoLocation Saved');
    });
    saveGeoLocation.then(() => {
      //this.setState({ gps_loading: false });
      localStorage.setItem(
        'userLat',
        typeof results[0].geometry.location.lat !== 'function'
          ? results[0].geometry.location.lat
          : results[0].geometry.location.lat()
      );
      localStorage.setItem(
        'userLng',
        typeof results[0].geometry.location.lng !== 'function'
          ? results[0].geometry.location.lng
          : results[0].geometry.location.lng()
      );

      history.push(`desktop/restaurantDetail/${results[0].slug}`);
    });
  };

  const callbackHandleSelect = (lat, lng) => {
    if (!lat || !lng) {
      return;
    }

    // set the near popular restaurants if exists
    setRestaurantCoordinates([lat, lng]);
  };

  const triggerCallback = (reopen) => {
    if (reopen && reopen === 'login') {
      showLogin(true);
      showRegistry(false);
    } else if (reopen && reopen === 'registry') {
      showRegistry(true);
      showLogin(false);
    } else {
      showLogin(false);
      showRegistry(false);
    }
  };

  const handleGooglePlacesAutocompleteLoading = (loading) => {
    setLoading(loading);
  };

  return (
    <>
      {!isOnline && <OffLine />}
      {isLoading && (
        <div className="height-100 overlay-loading ongoing-payment-spin">
          <div className="spin-load spin-load--desktop"></div>
        </div>
      )}
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-sm-12 col-md-6 content-wrapper-desktop">
            <div className="desktop-header-nav-wrapper">
              <a href="/">
                <img
                  src={
                    localstorage.storeLogo
                      ? `${ROOT_URL}/${localstorage.storeLogo}`
                      : `/assets/img/logo_b.png`
                  }
                  alt="Food App"
                  className="store-logo"
                />
              </a>

              {!!localstorage.cart && (
                <div className="home-cart-icon-wrapper">
                  <CartIcon place="home" />
                </div>
              )}
              <SignupDrawer
                trigger={registry}
                login={login}
                triggerCallback={triggerCallback}
                signin={language?.firstScreenRegisterBtn}
                account={language?.accountMyAccount}
              />
              <LoginDrawer
                trigger={login}
                registry={registry}
                triggerCallback={triggerCallback}
                login={language?.firstScreenLoginBtn}
              />
              <LanguageDrawer
                device={'desktop'}
                drawerTitleText={language?.changeLanguageText}
                languages={languages}
                languageWord={languageWord}
              />
              <div
                type="button"
                className="desktop-header-nav-btns desktop-header-nav-btn"
                style={{
                  float: 'right',
                  marginLeft: 0,
                }}
                onClick={() => history.push('/desktop/restaurants')}
              >
                {language?.sideDrawerMenuItemRestaurants}
              </div>
            </div>

            <div style={{ marginLeft: 30, padding: '30px 30px 30px 0' }}>
              <Typography
                style={{
                  color: '#2A1545',
                  fontWeight: 'bold',
                  fontSize: '3rem',
                  marginBottom: '15px',
                  lineHeight: '36px',
                }}
                variant="h6"
                gutterBottom
              >
                {language?.firstScreenHeading}
              </Typography>
              <Typography
                style={{ fontSize: '1.5rem', color: '#2A1545' }}
                variant="body1"
                gutterBottom
              >
                {language?.firstScreenSubHeading}
              </Typography>
            </div>

            <div style={{ marginLeft: 30 }}>
              <HandleGooglePlacesAutocomplete
                device="desktop"
                disableReversLookup={false}
                user={user}
                callbackHandleSelect={callbackHandleSelect}
                loading={handleGooglePlacesAutocompleteLoading}
              />
            </div>
            <div style={{ marginLeft: 30, padding: '20px 30px 20px 0' }}>
              {restaurants && restaurantsOnLocation?.length > 0 && (
                <PopularPlaces
                  loading={false}
                  device="desktop"
                  handleGeoLocationClick={handlePopularLocationClick}
                  locations={restaurantsOnLocation}
                  language={language}
                />
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 splash-image-desktop">
            <div>
              {localstorage?.firstScreenHeroImageVisibility !== 'false' && (
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={
                    localstorage.firstScreenHeroImage
                      ? `${ROOT_URL}/${localstorage.firstScreenHeroImage}`
                      : `/assets/img/sp1.png`
                  }
                  alt="HotPot Africa"
                  className="footer-logo mr-2"
                />
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
