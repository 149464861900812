const colors = () => {
  const localstorage = Object.assign({}, window.localStorage);
  let settings = {};
  try {
    settings = JSON.parse(localstorage?.state)?.settings;
  } catch (e) {
    console.log(e);
  }

  if (Object.keys(settings)?.length < 1) {
    return;
  }
  const storeColor = settings?.find((item) => item.key === 'storeColor')?.value;
  const hexToRgb = (hex) => {
    if (!hex) return '';
    const result =
      'rgba(' +
      [
        ('0x' + hex[1] + hex[2]) | 0,
        ('0x' + hex[3] + hex[4]) | 0,
        ('0x' + hex[5] + hex[6]) | 0,
      ].join() +
      ', 1)';
    return result;
  };
  document.documentElement.style.setProperty(
    '--storeColor',
    localstorage && hexToRgb(storeColor)
  );

  return hexToRgb(storeColor);
};

export default colors;
