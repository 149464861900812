import Axios from 'axios';

export const otpRegistry = async (body) => {
  return await Axios.post(
    // 'https://otp-service-lortschi-lortschis-projects.vercel.app/api/otp/generate',
    `${
      process.env.REACT_APP_PHP_DEPLOYER_VERSION_ACTIVE === true
        ? 'https://otp-service-lortschi-lortschis-projects.vercel.app'
        : 'http://localhost:5001'
    }/api/otp/generate`,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
    {
      data: {
        email: body.email,
        type: 'numeric',
        organization: 'Hotpot',
        subject: 'OTP Verification for Hotpot Account',
      },
    }
  )
    .then((res) => {
      return {
        response: res?.data.response,
        error: res?.data.error,
      };
    })
    .catch((error) => {
      if (error.response?.status === 400) {
        return {
          error: error,
        };
      }
    });
};

export const otpVerify = async (body) => {
  return await Axios.post(
    `${
      process.env.REACT_APP_PHP_DEPLOYER_VERSION_ACTIVE === true
        ? 'https://otp-service-lortschi-lortschis-projects.vercel.app'
        : 'http://localhost:5001'
    }/api/otp/verify`,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
    {
      data: {
        email: body.email,
        otp: body.otp,
      },
    }
  )
    .then((res) => {
      return {
        response: res?.data.response,
        error: res?.data.error,
      };
    })
    .catch((error) => {
      if (error.response?.status === 400) {
        return {
          error: error,
        };
      }
    });
};
