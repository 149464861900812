import { useState, useEffect } from 'react';
import { ALL_COUPONS_URL } from '../../../configs';
import Axios from 'axios';

export const useCouponCode = () => {
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState(null);
  useEffect(() => {
    let resturant = JSON.parse(localStorage.getItem('cartRestaurant'));

    if (resturant) {
      Axios.post(ALL_COUPONS_URL, { restaurant_id: resturant.id })
        .then((res) => {
          setCoupons(res.data);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, []);

  return {
    coupons,
    loading,
  };
};
