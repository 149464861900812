import React, { useState } from 'react';
import { makeStyles } from '@mui/styles/';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// import { useHistory } from "react-router-dom";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { useSnackbar } from 'notistack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import Skeleton from '@mui/lab/Skeleton';

import './styles.scss';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: useTheme().spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function CouponsDrawer(props) {
  const classes = useStyles();
  // const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [drawer, setDrawer] = useState(false);

  const [handleApplyCoupon, setApplyCoupon] = useState({
    couponId: 0,
    applied: false,
    code: '',
  });

  const applyCoupon = (item, i) => {
    let total = 0;
    JSON.parse(localStorage.getItem('cart')).map(
      (i) => (total += parseInt(i.price * i.quantity))
    );

    if (total < Number(item.min_order_amount)) {
      enqueueSnackbar(
        'Coupon applicable on minimum order of ' + item.min_order_amount,
        { variant: 'error' }
      );
      return;
    }

    localStorage.setItem('applyedCoupon', JSON.stringify(item));
    enqueueSnackbar('Coupon applied successfully! ', { variant: 'success' });
    setDrawer(false);
    const couponObj = {
      couponId: i + 1,
      applied: true,
      code: item.code,
    };
    setApplyCoupon(couponObj);
    props.applyCoupon(couponObj);
  };

  const removeCoupon = () => {
    localStorage.removeItem('applyedCoupon');

    const couponObj = {
      couponId: 0,
      applied: false,
      code: '',
    };
    setApplyCoupon(couponObj);
    props.applyCoupon(couponObj);

    enqueueSnackbar('Coupon removed! ', { variant: 'error' });
  };

  const { coupon } = props;

  return (
    <>
      {!handleApplyCoupon?.applied && (
        <div className="row p-2 cart-coupon-wrapper">
          <div className="col-9 p-3" onClick={() => setDrawer(true)}>
            <Typography variant="body1">
              <b>APPLY COUPON</b>
            </Typography>
          </div>
          <div className="col-3 cart-coupon-icon-btn">
            <IconButton
              className="float-right"
              onClick={() => setDrawer(true)}
              aria-label="back"
            >
              <ChevronRightOutlinedIcon
                style={{ position: 'relative', left: 0 }}
              />
            </IconButton>
          </div>
        </div>
      )}

      {handleApplyCoupon?.applied && (
        <div
          className="row"
          style={{
            borderBottom: '1px solid',
            margin: '5px 5px 5px 0',
            backgroundColor:
              localStorage.getItem('storeColor') || 'rgb(0, 160, 0)',
          }}
        >
          <div
            className="col-9 p-3 apply-coupon-wrapper"
            onClick={() => setDrawer(true)}
          >
            <div className="coupon-drawer-applied-text">
              {`${handleApplyCoupon.code} Coupon Applied`}
            </div>
          </div>
          <div className="col-3 cart-coupon-icon-btn">
            <IconButton
              className="float-right"
              onClick={() => removeCoupon()}
              aria-label="back"
            >
              <CloseIcon style={{ marginRight: 5, color: 'white' }} />
            </IconButton>
          </div>
        </div>
      )}

      <Drawer anchor={'right'} open={drawer} onClose={() => setDrawer(false)}>
        <div style={{ padding: 1 }}>
          <IconButton
            onClick={() => setDrawer(false)}
            aria-label="close"
            className={classes.margin}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ minWidth: 300, padding: 25 }}>
          {/*<div className="row">
            {[...Array(4)].map((i) => {
              return (
                <div className="col-12">
                  <div className="mb-3">
                    <Skeleton variant="rect" height={70} />
                    <Skeleton variant="text" style={{ marginTop: 5 }} />
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={50} />
                  </div>
                </div>
              );
            })}
          </div>*/}

          {!!coupon?.length && (
            <div className="row">
              {coupon?.map((item, i) => {
                return (
                  <div key={item?.id} className="col-12">
                    <Card gutterBottom className="mb-4">
                      <CardContent>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          gutterBottom
                        >
                          <b>{item?.name}</b>
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {item?.description}
                        </Typography>
                      </CardContent>
                      <div className="pl-3 pb-3 pr-3">
                        <Button variant="contained" disabled>
                          {item?.code}
                        </Button>
                        <Button
                          onClick={() => applyCoupon(item, i)}
                          style={{ background: 'forestgreen', color: 'white' }}
                          className="float-right"
                          variant="contained"
                        >
                          Apply
                        </Button>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}
