import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ChangeLoginCredentialsDrawer from './ChangeLoginCredentialsDrawer';
import ChangeProfileDrawer from './ChangeProfileDrawer';

// import { useHistory } from 'react-router-dom';
import '../styles.scss';

const MyProfile = ({ user, changeAddress }) => {
  const localstorage = Object.assign({}, window.localStorage);
  const defaultAddress =
    !user?.id && localstorage.userSetAddress
      ? JSON.parse(localStorage.userSetAddress)?.formatted_address
      : user?.default_address;

  const [changeProfile, setChangeProfileData] = useState(false);
  const [changeLoginCredentials, setChangeLoginCredentials] = useState(false);

  const triggerCallback = (state) => {
    setChangeLoginCredentials(state);
    setChangeProfileData(state);
  };

  console.log(defaultAddress);

  return (
    <div className="account-my-profile">
      <Typography
        className="address-item"
        variant="caption"
        style={{
          color: '#000',
          fontSize: '14px',
          background:
            'linear-gradient(to right, rgba(196, 25, 175, 0), rgb(233, 227, 227), rgba(196, 25, 175, 0))',
        }}
      >
        <div className="account-my-profile-address-wrapper">
          <div className="account-my-profile-address">
            <i className="si si-home mr-5"></i>
            {'Delivery Address'}
          </div>
          <i className="si si-arrow-right mr-5"></i>
          {typeof defaultAddress === 'string'
            ? defaultAddress
            : defaultAddress?.address}
        </div>
        <div className="account-my-profile-email-wrapper">
          <div className="account-my-profile-email">
            <i className="si si-envelope-letter mr-5"></i>
            {'Email'}
          </div>
          <i className="si si-arrow-right mr-5"></i>
          {user?.email || user?.data?.email}
        </div>
        <div className="account-my-profile-phone-wrapper">
          <div className="account-my-profile-phone">
            <i className="si si-phone mr-5"></i>
            {'Phone'}
          </div>
          <i className="si si-arrow-right mr-5"></i>
          {user?.phone || user?.data?.phone}
        </div>
      </Typography>
      <div className="account-my-profile-buttons">
        {!!user?.id && (
          <Button
            onClick={() => setChangeProfileData(true)}
            color="secondary"
            size="large"
          >
            <i className="si si-user mr-5"></i>
            Change Profile Data
          </Button>
        )}
        <Button onClick={changeAddress} color="secondary" size="large">
          <i className="si si-home mr-5"></i>
          Change Delivery Address
        </Button>
        {!!user?.id && (
          <Button
            onClick={() => setChangeLoginCredentials(true)}
            color="secondary"
            size="large"
          >
            <i className="si si-login mr-5"></i>
            Change Login Credentials
          </Button>
        )}
      </div>
      {changeLoginCredentials && (
        <ChangeLoginCredentialsDrawer
          trigger={true}
          triggerCallback={triggerCallback}
          user={user}
        />
      )}
      {changeProfile && (
        <ChangeProfileDrawer
          trigger={true}
          triggerCallback={triggerCallback}
          user={user}
        />
      )}
    </div>
  );
};

export default MyProfile;
