import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles/';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { GET_ADDRESSES_URL } from '../../../../configs';
import Axios from 'axios';
// import { useHistory } from "react-router-dom";
// import { useSnackbar } from 'notistack';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/lab/Skeleton';
import CardActions from '@mui/material/CardActions';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: useTheme().spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function AddressDrawer(props) {
  const classes = useStyles();
  // const history = useHistory();
  // const { enqueueSnackbar } = useSnackbar();

  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [manageAddr, setManageAddr] = useState(null);
  const [user, setUser] = useState(null);
  const [myAddress, setMyAddress] = useState([]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user'));
    setUser(user);
    loadAddress();
  }, []);

  function loadAddress() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user === null) return;
    Axios.post(GET_ADDRESSES_URL, { token: user.auth_token, user_id: user.id })
      .then((res) => {
        console.log(res);
        setMyAddress(res.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }

  const initManageAddr = (type, item) => {
    item.type = type;
    setManageAddr(item);
    console.log(manageAddr);
    // setOpen(true);
  };

  return (
    <>
      {user !== null && (
        <Button
          disabled={loading}
          onClick={() => setDrawer(true)}
          variant="outlined"
          color="primary"
          style={{ position: 'absolute', right: 40, top: 42 }}
        >
          CHANGE
        </Button>
      )}

      <Drawer anchor={'left'} open={drawer} onClose={() => setDrawer(false)}>
        <div style={{ padding: 1 }}>
          <IconButton
            onClick={() => setDrawer(false)}
            aria-label="close"
            className={classes.margin}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ padding: 25 }}>
          {loading && (
            <div className="row">
              {[...Array(4)].map((i) => {
                return (
                  <div className="col-12">
                    <div className="mb-3">
                      <Skeleton variant="rect" height={70} />
                      <Skeleton variant="text" style={{ marginTop: 5 }} />
                      <Skeleton variant="text" width={100} />
                      <Skeleton variant="text" width={50} />
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {myAddress !== null && (
            <div className="row">
              <div className="row">
                {myAddress.map((item) => {
                  return (
                    <div key={item.id} className="col-12 mb-3">
                      <div
                        style={{
                          borderBottom: '1px solid #bfbfbf',
                          borderRadius: 0,
                        }}
                      >
                        <CardContent>
                          <div className="row">
                            <div className="col-12">
                              <Typography variant="h6" component="h2">
                                {item.tag}
                                <span
                                  style={{ fontSize: 12 }}
                                  className="float-right"
                                >
                                  {item.house}
                                </span>
                              </Typography>
                              <Typography
                                variant="caption"
                                style={{ color: '#585858' }}
                              >
                                {item.address}
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                        <CardActions>
                          <Button
                            onClick={() => initManageAddr('delete', item)}
                            style={{ color: 'red' }}
                            size="small"
                          >
                            Delete
                          </Button>
                          <Button
                            onClick={() => initManageAddr('edit', item)}
                            style={{ color: 'rebeccapurple' }}
                            size="small"
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => initManageAddr('replace', item)}
                            style={{ color: 'green' }}
                            size="small"
                          >
                            Replace
                          </Button>
                        </CardActions>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}
