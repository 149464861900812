import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextStore } from '../../../configs/lib/ReactiveStatusProvider';
import { logoutUser } from '../../../services/user/actions';
import Header from '../../../components/Desktop/Layouts/Header';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useHistory, Redirect } from 'react-router-dom';

import MyProfile from './Profile/MyProfile';
import MyAddress from './Address/MyAddress';
import MyOrders from './Orders/MyOrders';
import MyWallet from './Wallet/MyWallet';
import colors from '../../Shared/Utils/Colors/colors';

export default function Account() {
  const { loggedIn, loggedInState } = useContext(ContextStore);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { user } = state;
  const history = useHistory();

  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  // Logout user from front -and server side
  const logout = () => {
    if (loggedIn) {
      loggedInState(false);
    }
    Object.keys(user.user).length > 0 && dispatch(logoutUser(user.user));
    localStorage.removeItem('user');
    history.push('/');
  };

  const changeAddress = () => {
    setTab(1);
  };

  if (!user?.loggedIn) {
    return <Redirect to="/" />;
  }

  console.log(colors());

  return (
    <div className="container-fluid p-0">
      <Header />
      <div
        className="row"
        style={{
          position: 'sticky',
          top: 0,
          marginTop: 64,
          padding: '30px 50px',
          color: 'white',
        }}
      >
        <div className="col-md-12">
          <h3 style={{ color: '#000', marginBottom: 5 }}>
            {'Welcome to your account'}
          </h3>
        </div>
      </div>

      <div
        className="row"
        style={{
          padding: 50,
          color: 'white',
          paddingTop: 0,
        }}
      >
        <div
          className="col-md-2 p-0 account-tabs-wrapper"
          style={{
            backgroundColor: 'rgb(238 233 233)',
            borderRadius: '5px 0px 0px 5px',
          }}
        >
          <Tabs
            value={tab}
            orientation="vertical"
            TabIndicatorProps={{
              style: { background: colors() },
            }}
            textColor="primary"
            variant="scrollable"
            onChange={handleTabChange}
            aria-label="Help"
            style={{ marginTop: 55 }}
          >
            <Tab
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              label="Profile"
              icon={<i className="si si-home mr-5"></i>}
            />
            <Tab
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                borderColor: colors(),
              }}
              label="Address"
              icon={<i className="si si-location-pin mr-5"></i>}
            />
            {!!user?.user?.id && (
              <Tab
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
                label="Orders"
                icon={<i className="si si-bag mr-5"></i>}
              />
            )}
            {!!user?.user?.id && (
              <Tab
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
                label="Wallet"
                icon={<i className="si si-wallet mr-5"></i>}
              />
            )}
            <Tab
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              label="Logout"
              icon={<i className="si si-logout mr-5"></i>}
              onClick={() => logout()}
            />
          </Tabs>
        </div>
        <div
          className="col-md-10"
          style={{
            backgroundColor: 'rgb(245,245,245)',
            padding: 40,
            minHeight: 540,
            borderRadius: '0px 5px 5px 0px',
          }}
        >
          {tab === 0 && (
            <div
              role="tabpanel"
              id="vertical-tabpanel-0"
              aria-labelledby="vertical-tabpanel-0"
            >
              <h3>{`Hello, ${
                (user && user?.user?.name) || user.user?.data?.name
              }`}</h3>
              <MyProfile user={user.user} changeAddress={changeAddress} />
            </div>
          )}
          {tab === 1 && (
            <div
              role="tabpanel"
              id="vertical-tabpanel-1"
              aria-labelledby="vertical-tabpanel-1"
            >
              <h3>My Addresses</h3>
              <MyAddress user={user} />
            </div>
          )}
          {tab === 2 && (
            <div
              role="tabpanel"
              id="vertical-tabpanel-2"
              aria-labelledby="vertical-tabpanel-2"
            >
              <h3>My Orders</h3>
              <MyOrders />
            </div>
          )}

          {tab === 3 && (
            <div
              role="tabpanel"
              id="vertical-tabpanel-3"
              aria-labelledby="vertical-tabpanel-3"
            >
              <h3>My Wallet</h3>
              <MyWallet />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
