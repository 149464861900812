import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { USER_CHANGE_PROFILE_DATA_URL } from '../../../../configs';
import { USER_CHANGE_PROFILE_DATA } from '../../../../services/user/actionTypes';
// import { loginUser } from '../../../services/user/actions';
import Axios from 'axios';
// import { useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSnackbar } from 'notistack';
import '../styles.scss';

export default function ChangeLoginCredentialsDrawer(props) {
  const { enqueueSnackbar } = useSnackbar();
  // const history = useHistory();
  //const location = useLocation();

  const dispatch = useDispatch();
  const [profile, setProfileDrawer] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [name, setName] = useState({ first: '', last: '' });
  const [phoneError, setPhoneError] = useState(false);
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  // const [otpSent, setOtpSent] = useState(false);
  // const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (props.trigger) {
      setProfileDrawer(true);
    }
  }, [props.trigger]);

  const doSubmitData = (type) => {
    if (type === 'name' && (name.first.length < 3 || name.last.length < 3)) {
      setNameError(true);
      return;
    }
    if (type === 'phone' && phone.length <= 5) {
      setPhoneError(true);
      return;
    }

    setNameError(false);
    setPhoneError(false);
    setLoading(true);

    let raw = {
      type: type,
      email: props.user.email,
      name: `${name.first} ${name.last}`,
      phone: phone,
      token: props.user.auth_token,
    };

    Axios.post(`${USER_CHANGE_PROFILE_DATA_URL}`, raw)
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            setLoading(false);
            props.triggerCallback(!profile);
            // history.push('/desktop/account');
            enqueueSnackbar('Profile data has been successfuly updated.', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              autoHideDuration: 5000,
            });
          }, 800);
          return dispatch({
            type: USER_CHANGE_PROFILE_DATA,
            payload: res.data.data,
          });
        } else {
          setLoading(false);
          setMessage(res.data.data);
          setShowMessage(true);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleProfileDrawer = () => {
    setProfileDrawer(!profile);
    props.triggerCallback(!profile);
  };

  return (
    <>
      <Drawer
        anchor={'right'}
        open={profile}
        onClose={() => handleProfileDrawer()}
      >
        <div style={{ padding: 1, color: 'white' }}>
          <IconButton onClick={() => handleProfileDrawer()} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ padding: 25 }}>
          <Typography variant="h5" gutterBottom>
            Change your profile data
          </Typography>
          <br />
          <>
            <Accordion style={{ boxShadow: 'none' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  style={nameError ? { color: 'red' } : { color: '#000' }}
                  variant="h6"
                >
                  Name
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  className="filled-input-white"
                  style={{
                    marginBottom: 30,
                    marginTop: 20,
                    backgroundColor: 'white',
                    borderColor: 'black',
                    width: '100%',
                  }}
                  label="First Name"
                  id="filled-size-small"
                  defaultValue=""
                  onChange={(e) => setName({ ...name, first: e.target.value })}
                  error={nameError}
                  type="text"
                  variant="outlined"
                />
                <TextField
                  className="filled-input-white"
                  style={{
                    marginBottom: 30,
                    marginTop: 20,
                    backgroundColor: 'white',
                    borderColor: 'black',
                    width: '100%',
                  }}
                  label="Last Name"
                  id="filled-size-small"
                  defaultValue=""
                  onChange={(e) => setName({ ...name, last: e.target.value })}
                  error={nameError}
                  type="text"
                  variant="outlined"
                />
                <Button
                  disabled={loading}
                  loading={loading}
                  onClick={() => doSubmitData('name')}
                  style={{
                    marginTop: 30,
                    width: '100%',
                    backgroundColor: '#8c1ed3',
                    borderRadius: '5px',
                    color: 'white',
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  {loading ? 'Please Wait...' : 'NAME UPDATE'}
                </Button>
              </AccordionDetails>
            </Accordion>

            {props.user.reg_type !== 'OTP' && (
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography
                    style={phoneError ? { color: 'red' } : { color: '#000' }}
                    variant="h6"
                  >
                    Phone
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    className="filled-input-white"
                    style={{
                      marginBottom: 10,
                      marginTop: 20,
                      backgroundColor: 'white',
                      borderColor: 'black',
                      width: '100%',
                    }}
                    label="Phone"
                    id="filled-size-small"
                    defaultValue=""
                    type="text"
                    onChange={(e) => setPhone(e.target.value)}
                    error={phoneError}
                    variant="outlined"
                  />
                  <Button
                    disabled={loading}
                    loading={loading}
                    onClick={() => doSubmitData('phone')}
                    style={{
                      marginTop: 30,
                      width: '100%',
                      backgroundColor: '#8c1ed3',
                      borderRadius: '5px',
                      color: 'white',
                    }}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    {loading ? 'Please Wait...' : 'PHONE UPDATE'}
                  </Button>
                </AccordionDetails>
              </Accordion>
            )}
          </>
        </div>
      </Drawer>

      <Snackbar
        open={showMessage}
        autoHideDuration={6000}
        onClose={() => setShowMessage(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setShowMessage(false)}
          severity="error"
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
