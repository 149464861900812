import React, { useState, useMemo, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ContextStore } from '../../../../configs/lib/ReactiveStatusProvider';
import { APIProvider } from '@vis.gl/react-google-maps';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HandleGooglePlacesAutocomplete from '../../../Shared/HandleGooglePlacesAutocomplete';
import { GoogleMaps } from './GoogleMap';
import Axios from 'axios';
import {
  GET_ADDRESS_FROM_COORDINATES,
  SAVE_ADDRESS_URL,
} from '../../../../configs';
import setAsDefaultAddress from '../../../Shared/Lib/setAsDefaultAddress';
import { useGetSettings } from '../../../Shared/Hooks/useGetSettings';
import TextField from '@mui/material/TextField';
import './styles.scss';

export default function AddAddressDrawer(props) {
  const { user } = props;
  const location = useLocation();
  const { changedAddressState } = useContext(ContextStore);
  const [loading, setLoading] = useState(false);
  const [login, setDrawer] = useState(false);
  const [locationCoordinates, setLocationCoordinates] = useState({
    lat: localStorage.getItem('userLat'),
    lng: localStorage.getItem('userLng'),
  });
  const [flat, setFlat] = useState('');
  const [tag, setTag] = useState('');
  const [dragging, setDragging] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const [placesAutocompleteLoading, setPlacesAutocompleteLoading] =
    useState(false);

  const settings = useGetSettings();

  const apiKey = useMemo(() => {
    if (Object.keys(settings)?.length < 1) {
      return null;
    }
    // eslint-disable-next-line no-prototype-builtins
    const apiKeyFiltered = settings?.hasOwnProperty('settings')
      ? settings?.settings?.filter((item) => item.key === 'googleApiKey')[0]
          ?.value
      : settings?.filter((item) => item.key === 'googleApiKey')[0]?.value;

    return apiKeyFiltered;
  }, [settings]);

  const reverseLookup = (lat, lng) => {
    try {
      Axios.post(GET_ADDRESS_FROM_COORDINATES, {
        lat: lat,
        lng: lng,
      })
        .then((response) => {
          console.log(response, lat, lng);
          setLocationData({ address: response.data, lat, lng });
          const userAddress = {
            formatted_address: response.data,
            geometry: {
              location: {
                lat: lat,
                lng: lng,
              },
            },
          };
          setLocationCoordinates({
            ...locationCoordinates,
            lat: lat,
            lng: lng,
          });
          localStorage.setItem('userSetAddress', JSON.stringify(userAddress));
          setDragging(false);
        })
        .catch(function (error) {
          alert('Please Enable Your device Location to continue.');
          console.warn(error.response.data);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onMarkerDragEnd = (map) => {
    localStorage.setItem('userLat', map.center.lat());
    localStorage.setItem('userLng', map.center.lng());
    reverseLookup(map.center.lat(), map.center.lng());
  };

  const handleDragging = () => {
    setDragging(true);
  };

  const saveAddress = () => {
    setLoading(true);
    if (user.id) {
      try {
        Axios.post(SAVE_ADDRESS_URL, {
          token: user.auth_token,
          user_id: user.id,
          latitude: locationData.lat,
          longitude: locationData.lng,
          address: locationData.address,
          house: flat,
          tag: tag,
          get_only_default_address: '',
        })
          .then((response) => {
            setAsDefaultAddress(
              user,
              response?.data[response?.data.length - 1]
            );
            setDrawer(false);
            props.refresh();
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    } else {
      const userAddress =
        !!localStorage.getItem('userSetAddress') &&
        JSON.parse(localStorage.getItem('userSetAddress'));
      setAsDefaultAddress(null, {
        address: userAddress?.formatted_address,
        latitude: userAddress?.geometry.location.lat,
        longitude: userAddress?.geometry.location.lng,
      });
      setDrawer(false);
      props.refresh();
    }
    changedAddressState(true);
    setLoading(false);
  };

  const callbackHandleSelect = (lat, lng) => {
    if (!lat || !lng) {
      return;
    }

    localStorage.setItem('userLat', lat);
    localStorage.setItem('userLng', lng);

    reverseLookup(lat, lng);
  };

  const inputFieldMargin = useCallback(
    () => (location.pathname === '/desktop/account' ? 10 : 20),
    [location.pathname]
  );

  return (
    <>
      <Button onClick={() => setDrawer(true)} color="secondary" size="medium">
        + {user.id ? 'Add New Delivery Address' : 'Change Delivery Address'}
      </Button>

      <Drawer
        PaperProps={{
          sx: { width: '35%', overflowY: 'visible' },
        }}
        anchor={'right'}
        open={login}
        onClose={() => setDrawer(false)}
      >
        {!!placesAutocompleteLoading && (
          <div className="height-100 overlay-loading overlay-loading--address-drawer ongoing-payment-spin">
            <div className="spin-load spin-load--address-drawer"></div>
          </div>
        )}
        <div style={{ padding: 1 }}>
          <IconButton onClick={() => setDrawer(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        {
          <div className="row">
            <div className="add-address-drawer-map-information-wrapper">
              <InfoOutlinedIcon />
              <div className="add-address-drawer-map-information-text">
                Use drag map for pointing new locations with the marker.
              </div>
            </div>
            <div style={{ height: 254 }} className="col-md-12">
              {!!apiKey && (
                <APIProvider
                  apiKey={apiKey}
                  status={(status) => {
                    if (status.error) {
                      console.error('Google Maps API Error:', status.error);
                    } else {
                      console.log('Google Maps API Status:', status);
                    }
                  }}
                  onLoad={(e) => console.log(e)}
                >
                  <GoogleMaps
                    mapId="ADDRESS_MAP"
                    reverseLookup={reverseLookup}
                    isDragging={dragging}
                    onMarkerDragEnd={onMarkerDragEnd}
                    handleDragging={handleDragging}
                    centerMap={locationCoordinates}
                  ></GoogleMaps>
                </APIProvider>
              )}
            </div>
          </div>
        }

        <div className="row" style={{ padding: 25, overflow: 'auto' }}>
          <div
            className={`col-md-12 ${
              inputFieldMargin() === 10
                ? 'filled-input-myaccount-address-wrapper'
                : ''
            }`}
          >
            <div
              style={{ margin: `${inputFieldMargin() === 20 ? '20px 0' : ''}` }}
            >
              {!!apiKey && (
                <HandleGooglePlacesAutocomplete
                  device="desktop--special"
                  disableReversLookup={false}
                  user={user}
                  callbackHandleSelect={callbackHandleSelect}
                  callbackLoading={(state) => {
                    setPlacesAutocompleteLoading(state);
                  }}
                />
              )}
            </div>
            <TextField
              id="filled-size-small"
              className="filled-input-myaccount-address filled-input-white"
              style={{
                marginBottom: 20,
                marginTop: inputFieldMargin(),
                backgroundColor: 'white',
                borderColor: 'black',
                width: '100%',
              }}
              label="Address"
              disabled={true}
              value={locationData?.address}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />

            <div className="optional-or">optional</div>
            <div className="add-address-drawer-subaddress-wrapper">
              <TextField
                className="filled-input-white"
                style={{
                  marginBottom: 20,
                  marginTop: inputFieldMargin(),
                  marginRight: 20,
                  backgroundColor: 'white',
                  borderColor: 'black',
                  width: '100%',
                }}
                label="Flat / Door Number"
                id="filled-size-small"
                onChange={(e) => setFlat(e.target.value)}
                variant="outlined"
              />

              <TextField
                className="filled-input-white"
                style={{
                  marginBottom: 20,
                  marginTop: inputFieldMargin(),
                  backgroundColor: 'white',
                  borderColor: 'black',
                  width: '100%',
                }}
                label="Desc"
                id="filled-size-small"
                defaultValue=""
                onChange={(e) => setTag(e.target.value)}
                variant="outlined"
              />
            </div>

            <Button
              disabled={loading || dragging ? true : false}
              onClick={() => saveAddress()}
              style={{
                marginTop: 20,
                height: '50px',
                width: '100%',
                backgroundColor: 'rgb(96, 178, 70)',
                bordeRadius: '4px',
                color: 'white',
              }}
              variant="contained"
              size="large"
              color="primary"
            >
              {loading ? 'Please Wait...' : 'Save Address'}
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}
