import React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MainSlider from '../../Shared/MainSlider/MainSlider';
import colors from '../../Shared/Utils/Colors/colors';
/* eslint-disable */
const Footer = () => {
  colors();
  const localstorage = Object.assign({}, window.localStorage);
  const state = localstorage?.state && JSON.parse(localstorage?.state).settings;
  return (
    <>
      <MainSlider device="desktop" />
      {/*<footer className="desktop-footer bg-white opacity-1">
          <div className="container">
            <div className="content content-full">
              <div
                className="row items-push-2x mt-30"
                style={{ marginLeft: '25rem' }}
              >
                <div className="col-md-6">
                  <img
                    src="/assets/img/logo_w.png"
                    alt={localStorage.getItem('storeName')}
                    className="footer-logo mb-20"
                  />
                  <div
                    className="font-size-sm mb-30"
                    dangerouslySetInnerHTML={{
                      __html: localStorage.getItem('desktopFooterAddress'),
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <h3 className="h5 font-w700">
                    {localStorage.getItem('desktopFooterSocialHeader')}
                  </h3>
                  {localStorage.getItem('desktopSocialFacebookLink') !==
                    'null' && (
                    <a
                      href={localStorage.getItem('desktopSocialFacebookLink')}
                      className="btn btn-sm btn-rounded btn-alt-secondary mr-10"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-fw fa-facebook" />
                    </a>
                  )}

                  {localStorage.getItem('desktopSocialGoogleLink') !==
                    'null' && (
                    <a
                      href={localStorage.getItem('desktopSocialGoogleLink')}
                      className="btn btn-sm btn-rounded btn-alt-secondary mr-10"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-fw fa-google-plus" />
                    </a>
                  )}

                  {localStorage.getItem('desktopSocialYoutubeLink') !==
                    'null' && (
                    <a
                      href={localStorage.getItem('desktopSocialYoutubeLink')}
                      className="btn btn-sm btn-rounded btn-alt-secondary mr-10"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-fw fa-youtube-play" />
                    </a>
                  )}

                  {localStorage.getItem('desktopSocialInstagramLink') !==
                    'null' && (
                    <a
                      href={localStorage.getItem('desktopSocialInstagramLink')}
                      className="btn btn-sm btn-rounded btn-alt-secondary mr-10"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-fw fa-instagram" />
                    </a>
                  )}
                  {this.props.languages && this.props.languages.length > 1 && (
                    <div className="mt-4 d-flex align-items-center">
                      <div className="mr-2">
                        {localStorage.getItem('changeLanguageText')}
                      </div>
                      <select
                        onChange={this.props.handleOnChange}
                        defaultValue={
                          localStorage.getItem('userPreferedLanguage')
                            ? localStorage.getItem('userPreferedLanguage')
                            : this.props.languages.filter(
                                (lang) => lang.is_default === 1
                              )[0].id
                        }
                        className="form-control language-select"
                      >
                        {this.props.languages.map((language) => (
                          <option value={language.id} key={language.id}>
                            {language.language_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </footer>*/}
      <div
        className="row"
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          padding: 25,
          backgroundColor: '#2A1545',
        }}
      >
        <div className="col-sm-12 col-md-4">
          <div style={{ textAlign: 'center', padding: 10 }}>
            <img
              style={{ width: '54', marginBottom: 15 }}
              src="/assets/img/desktop/m1.png"
              alt="HotPot Africa"
              className="mr-2"
            />
            <Typography
              variant="body1"
              style={{
                color: 'white',
                marginBottom: 20,
                fontSize: 20,
                fontWeight: 'bold',
              }}
              gutterBottom
            >
              Restaurants
            </Typography>
            <Typography
              variant="body2"
              style={{ minHeight: '70px', color: '#D8D8D8', fontSize: 13 }}
              gutterBottom
            >
              Here at Hotpot Africa we provide a platform where you register
              your business needs gain global presence and increase your
              customer base
            </Typography>
            <div className="text-center">
              <Button
                style={{
                  background: localstorage.storeColor || '#EB881D',
                  borderRadius: 5,
                  marginTop: 20,
                }}
                onClick={() =>
                  (window.location = 'https://admin.hotpotafrica.com')
                }
                variant="contained"
                color="secondary"
              >
                Contact Us
              </Button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <div style={{ textAlign: 'center', padding: 10 }}>
            <img
              style={{ width: '54', marginBottom: 15 }}
              src="/assets/img/desktop/m2.png"
              alt="HotPot Africa"
              className="mr-2"
            />
            <Typography
              variant="body1"
              style={{
                color: 'white',
                marginBottom: 20,
                fontSize: 20,
                fontWeight: 'bold',
              }}
              gutterBottom
            >
              Business Groups
            </Typography>
            <Typography
              variant="body2"
              style={{ minHeight: '70px', color: '#D8D8D8', fontSize: 13 }}
              gutterBottom
            >
              Register your business needs with us and we will take care of the
              rest by making sure you and your clients have the best eatout
              experience ever every time you order
            </Typography>
            <div className="text-center">
              <Button
                style={{
                  background: localstorage.storeColor || '#EB881D',
                  borderRadius: 5,
                  marginTop: 20,
                }}
                onClick={() =>
                  (window.location = 'https://admin.hotpotafrica.com')
                }
                variant="contained"
                color="secondary"
              >
                Contact Us
              </Button>
            </div>
          </div>
        </div>
        {/*<div className="col-sm-12 col-md-4">
          <div style={{ textAlign: 'center', padding: 10 }}>
            <img
              style={{ width: '54', marginBottom: 15 }}
              src="/assets/img/desktop/m3.png"
              alt="HotPot Africa"
              className="mr-2"
            />
            <Typography
              variant="body1"
              style={{
                color: 'white',
                marginBottom: 20,
                fontSize: 20,
                fontWeight: 'bold',
              }}
              gutterBottom
            >
              Riders
            </Typography>
            <Typography
              variant="body2"
              style={{ color: '#D8D8D8', fontSize: 13 }}
              gutterBottom
            >
              Join us and earn yourself some extra cash whilst keeping yourself
              active. Deliver the food to the customers. Apply today!
            </Typography>
            <div className="text-center">
              <Button
                style={{
                  background: '#EB881D',
                  borderRadius: 5,
                  marginTop: 20,
                }}
                onClick={() =>
                  (window.location = 'https://admin.hotpotafrica.com')
                }
                variant="contained"
                color="secondary"
              >
                Join Us!
              </Button>
            </div>
          </div>
        </div>*/}
      </div>

      <div className="bottom-wrapper">
        <div className="bottom-container">
          <div className="bottom-row">
            <div className="bottom-footer-col">
              <h4>company</h4>
              <ul>
                <li>
                  <a href="#">about us</a>
                </li>
                <li>
                  <a href="#">our services</a>
                </li>
                <li>
                  <a href="#">privacy policy</a>
                </li>
                <li>
                  <a href="#">affiliate program</a>
                </li>
              </ul>
            </div>
            <div className="bottom-footer-col">
              <h4>get help</h4>
              <ul>
                <li>
                  <a href="#">FAQ</a>
                </li>
                <li>
                  <a href="#">shipping</a>
                </li>
                <li>
                  <a href="#">returns</a>
                </li>
                <li>
                  <a href="#">order status</a>
                </li>
                <li>
                  <a href="#">payment options</a>
                </li>
              </ul>
            </div>
            <div className="bottom-footer-col">
              <h4>online shop</h4>
              <ul>
                <li>
                  <a href="#">watch</a>
                </li>
                <li>
                  <a href="#">bag</a>
                </li>
                <li>
                  <a href="#">shoes</a>
                </li>
                <li>
                  <a href="#">dress</a>
                </li>
              </ul>
            </div>
            <div className="bottom-footer-col">
              <h4>follow us</h4>
              <div className="bottom-social-links">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
              <div className="bottom-store-name-wrapper">
                {state?.find((x) => x.key === 'storeName')?.value}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
