import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import ModalPopup from './Pages/Modal';
import Button from '@mui/material/Button';
import PlaceIcon from '@mui/icons-material/Place';
import NearMeIcon from '@mui/icons-material/NearMe';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { GetAddressFromCoordinates } from '../Shared/GetAddressFromCoordinates';
import { connect } from 'react-redux';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { getSettings } from '../../services/settings/actions';
import { getSingleLanguageData } from '../../services/languages/actions';
import {
  setDefaultAddress,
  getAddresses,
  //saveAddress /* = (user_id, token, lat, lng, address, house, tag, get_only_default_address)*/,
} from '../../services/addresses/actions';
import { useSnackbar } from 'notistack';
import '../Mobile/Home/styles.scss';
// import './styles.scss';

const HandleGooglePalcesAutocomplete = ({
  disableComponent,
  user,
  styleClass,
  device,
  callbackHandleSelect,
  disableReversLookup,
  loading,
  callbackLoading,
}) => {
  const localstorage = Object.assign({}, window.localStorage);
  const settings = useSelector((state) => state.settings.settings);
  const currLanguage = useSelector((state) => state.languages.language);
  const addresses = useSelector((state) => state.addresses.addresses);
  const googlePlacesRef = useRef(null);
  const reactGooglePlacesInputRef = useRef(null | '');
  const reactGooglePlacesAutocompleteButtons = useRef(null);
  const [apiKey, setApiKey] = useState('');
  const [rerendered, setRerender] = useState(false);
  const [gpsLoading, setGpsLoading] = useState(false);
  const [loadedLocations, getLoadedLocation] = useState(false);
  const [showModalPopup, setShowModalPopup] = useState({
    state: false,
    res: '',
  });
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  let [language, setLanguage] = useState();
  language = language ? language : localstorage;

  const changeGooglePlacesControlIcon = () => {
    if (
      reactGooglePlacesInputRef?.current?.value?.length >= 3 &&
      reactGooglePlacesAutocompleteButtons?.current
    ) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      [...reactGooglePlacesAutocompleteButtons?.current?.children]?.map(
        (child) => {
          return [
            child
              ?.getElementsByClassName('react-google-highlight-off-icon')[0]
              ?.style.setProperty('display', 'block'),
            child
              ?.getElementsByClassName('react-google-near-me-icon')[0]
              ?.style.setProperty('display', 'none'),
          ];
        }
      );
    } else if (reactGooglePlacesAutocompleteButtons?.current) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      [...reactGooglePlacesAutocompleteButtons?.current?.children]?.map(
        (child) => {
          return [
            child
              ?.getElementsByClassName('react-google-highlight-off-icon')[0]
              ?.style.setProperty('display', 'none'),
            child
              ?.getElementsByClassName('react-google-near-me-icon')[0]
              ?.style.setProperty('display', 'block'),
          ];
        }
      );
    }
  };

  const setDefaultLocation = (address, lat, lng) => {
    const myLocation = {
      formatted_address: address,
      geometry: {
        location: {
          lat: address?.latitude || lat,
          lng: address?.longitude || lng,
        },
      },
    };

    localStorage.setItem('geoLocation', JSON.stringify(myLocation));
    localStorage.setItem('userSetAddress', JSON.stringify(myLocation));
  };

  const clearInput = () => {
    if (googlePlacesRef?.current) {
      googlePlacesRef.current.changeValue('');
    }
  };

  const handleSelect = ({ description }) => {
    geocodeByAddress(description)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        localStorage.setItem('userLat', lat);
        localStorage.setItem('userLng', lng);
        localStorage.setItem('address', description);
        setDefaultLocation(description, lat, lng);
        callbackHandleSelect(lat, lng, description, user);
        !disableReversLookup &&
          device !== 'desktop--special' &&
          history.push(
            device === 'desktop' ? '/desktop/restaurants' : '/my-location'
          );
      })
      .catch((error) => console.error('Error', error));
  };

  useEffect(() => {
    if (!settings) {
      dispatch(getSettings());
    }

    if (!addresses.length && user.length) {
      dispatch(getAddresses(user?.data?.id, user?.data?.auth_token));
    }

    if (!language && !currLanguage) {
      dispatch(getSingleLanguageData(localstorage.userPreferedLanguage));
    }

    if (!language && currLanguage) {
      setLanguage(currLanguage);
    }

    if (addresses.length) {
      localStorage.setItem('addresses', JSON.stringify(addresses));
    }
  }, [
    settings,
    language,
    currLanguage,
    dispatch,
    localstorage.userPreferedLanguage,
    addresses,
    user,
  ]);

  useEffect(() => {
    if (settings && settings?.length) {
      setApiKey(
        settings?.filter((item) => item?.key === 'googleApiKey')[0]?.value
      );
    }

    if (rerendered) {
      console.log('Google API was reloaded.');
    }
  }, [settings, apiKey, rerendered, dispatch]);

  useEffect(() => {
    device === 'desktop' && loading(gpsLoading);
    typeof callbackLoading === 'function' && callbackLoading(gpsLoading);
  }, [callbackLoading, device, gpsLoading, loading]);

  const reverseLookup = (lat, lng, isRestricted) => {
    GetAddressFromCoordinates(lat, lng, apiKey)
      .then((response) => {
        if (response.error) {
          setShowModalPopup({
            ...showModalPopup,
            state: response.state,
            res: response.res,
          });
        }
        setGpsLoading(false);
        localStorage.setItem('userLat', lat);
        localStorage.setItem('userLng', lng);
        localStorage.setItem('address', response.data);

        setDefaultLocation(response.data, lat, lng);

        // fetch the near popular restaurants if exists
        callbackHandleSelect(lat, lng);
        !isRestricted &&
          device !== 'desktop--special' &&
          history.push(
            device === 'desktop' ? '/desktop/restaurants' : '/my-location'
          );
      })
      .catch(function (error) {
        //  console.warn(error.response.data);
        enqueueSnackbar(
          'Ohoo, something went wrong. Probably no internet connection :(',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }
        );
      });
  };

  const determinCurrentUserLocation = (isRestricted) => {
    const location = navigator && navigator.geolocation;
    location.getCurrentPosition(
      (position) => {
        reverseLookup(
          position.coords.latitude,
          position.coords.longitude,
          isRestricted
        );
      },
      (error) => {
        setGpsLoading(false);
        console.log(error);
        // this.setState({locationModal: true});
        // alert(localStorage.getItem("gpsAccessNotGrantedMsg"));
        localStorage.setItem(
          'address',
          'Please enable internet connection and the location tracking in your browser.'
        );
      },
      {
        maximumAge: 1000,
        timeout: 5000,
        enableHighAccuracy: true,
      }
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMyLocation = useCallback((isRestricted) => {
    setGpsLoading(true);
    // verify if the current location already stored into localstorage to avoid unecessary g-requests
    if ((!localstorage.userLat && !localstorage.userLng) || !isRestricted) {
      determinCurrentUserLocation(isRestricted);
    } else {
      !disableComponent &&
        reverseLookup(localstorage.userLat, localstorage.userLng, isRestricted);
    }
    !disableComponent && getLoadedLocation(true);
  });

  useEffect(() => {
    if (
      !disableComponent &&
      !disableReversLookup &&
      apiKey &&
      !loadedLocations
    ) {
      getMyLocation(true);
    }
  }, [
    apiKey,
    disableReversLookup,
    disableComponent,
    getMyLocation,
    loadedLocations,
  ]);

  if (disableComponent) {
    return null;
  }

  return (
    <>
      {!!showModalPopup.state && (
        <ModalPopup
          state={showModalPopup.state}
          headline={'Error!'}
          content={<div>{showModalPopup.res}</div>}
          option={{ animation: false, button: true }}
          callbackState={() =>
            setShowModalPopup({ ...showModalPopup, state: false })
          }
        />
      )}
      {gpsLoading && device !== 'desktop' && device !== 'desktop--special' && (
        <div className="height-100 overlay-loading ongoing-payment-spin">
          <div className="spin-load"></div>
        </div>
      )}
      <div className="col-12 p-0 pt-0">
        {apiKey && (
          <div
            className={`${styleClass} google-places-wrapper google-places-wrapper_location-mobile`}
          >
            <PlaceIcon
              fontSize="large"
              style={{
                position: 'absolute',
                width: 30,
                height: 30,
                alignSelf: 'center',
                marginLeft: 10,
                fontSize: '20px',
                color:
                  settings?.filter((item) => item?.key === 'storeColor')[0]
                    ?.value || '#000',
              }}
            />
            <GooglePlacesAutocomplete
              ref={googlePlacesRef}
              debounce={200}
              apiKey={`${apiKey}&callback=&initMap`}
              minLength={2}
              placeholder={language?.searchAreaPlaceholder}
              clearButtonMode={'always'}
              renderInput={(props) => (
                <input
                  id="react-google-places-autocomplete-input"
                  ref={reactGooglePlacesInputRef}
                  onChange={changeGooglePlacesControlIcon()}
                  {...props}
                />
              )}
              onSelect={handleSelect}
              minLengthAutocomplete={2}
              onLoadFailed={(error) => {
                console.error('Could not inject Google script', error);
                setRerender(true);
              }}
              currentLocationLabel="Current location"
              defaultValue={() => {
                return ''; // text input default value
              }}
              renderSuggestions={(
                activeSuggestion,
                suggestions,
                onSelectSuggestion
              ) => (
                <div className="suggestions-container autocomplete-dropdown-container">
                  {suggestions.map((suggestion, i) => (
                    <div
                      key={suggestion + i}
                      className="suggestion"
                      onClick={(event) => onSelectSuggestion(suggestion, event)}
                    >
                      <PlaceIcon
                        fontSize="large"
                        style={{
                          position: 'relative',
                          alignSelf: 'center',
                          margin: '0 3px',
                          fontSize: '20px',
                          color:
                            settings?.filter(
                              (item) => item?.key === 'storeColor'
                            )[0]?.value || '#000',
                        }}
                      />
                      <div>{suggestion.description}</div>
                    </div>
                  ))}
                </div>
              )}
            ></GooglePlacesAutocomplete>

            <Button
              ref={reactGooglePlacesAutocompleteButtons}
              startIcon={
                <>
                  <HighlightOffIcon
                    className="react-google-highlight-off-icon"
                    fontSize="large"
                    style={{
                      display: 'none',
                      width: 30,
                      height: 30,
                      marginLeft: 10,
                      fontSize: '20px',
                      color:
                        settings?.filter(
                          (item) => item?.key === 'storeColor'
                        )[0]?.value || '#000',
                    }}
                  />

                  {!disableReversLookup && (
                    <NearMeIcon
                      className="react-google-near-me-icon"
                      style={{
                        width: 30,
                        height: 30,
                        marginLeft: 20,
                        fontSize: '20px',
                        color:
                          settings?.filter(
                            (item) => item?.key === 'storeColor'
                          )[0]?.value || '#000',
                      }}
                    />
                  )}
                </>
              }
              className="desktop-locate-me-btn"
              onClick={() =>
                reactGooglePlacesInputRef?.current?.value.length >= 3
                  ? clearInput()
                  : !disableReversLookup && getMyLocation()
              }
            >
              {gpsLoading && device === 'desktop' ? '...' : ''}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  settings: state.settings.settings,
  addresses: state.addresses.addresses,
  language: state.languages.language,
});

export default withRouter(
  connect(mapStateToProps, {
    getSettings,
    setDefaultAddress,
    getSingleLanguageData,
  })(HandleGooglePalcesAutocomplete)
);
