import { GET_ORDERS, CANCEL_ORDER, ORDER_DETAIL } from './actionTypes';

const initialState = {
  orders: [],
  cancel: null,
  currentOrder: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ORDERS:
      return { ...state, orders: action.payload };
    case CANCEL_ORDER:
      return { ...state, cancel: action.payload };
    case ORDER_DETAIL:
      return { ...state, currentOrder: action.payload };
    default:
      return state;
  }
}
