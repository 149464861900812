import React, { useState, useEffect } from 'react';

// import { connect } from "react-redux";
// import { getSettings } from "../../../services/settings/actions";
import { makeStyles } from '@mui/styles/';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import { useHistory } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Axios from 'axios';
import {
  ROOT_URL,
  GET_RESTAURANTS_SLIDES_URL,
  GET_FILTERED_RESTAURANTS_URL,
} from '../../../configs';
import Skeleton from '@mui/lab/Skeleton';
import Header from '../Layouts/Header';
// import Paper from '@mui/material/Paper';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import { useSnackbar } from 'notistack';
import EmptyResult from '../../Loading/EmptyResult';
import { useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: useTheme().spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Categories() {
  const classes = useStyles();
  const { categoryId } = useParams();
  const history = useHistory();
  // const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  // const [tab, setTab] = useState(0);
  const [restaurants, setRestaurants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    Axios.post(GET_RESTAURANTS_SLIDES_URL)
      .then((res) => {
        setCategories(res.data);
        setTimeout(() => {
          setFilters([parseInt(categoryId)]);
        }, 500);
        // let id = res.data[tab].id;
        // Axios.post(GET_FILTERED_RESTAURANTS_URL, {
        //     latitude: localStorage.getItem("userLat"),
        //     longitude: localStorage.getItem("userLng"),
        //     category_ids: [id]
        // })
        // .then(response => {
        //     setRestaurants(response.data)
        //     setLoading(false)
        // })
        // .catch(function(error) {
        //     console.log(error);
        //     setLoading(false)
        // });
      })
      .catch((e) => console.log(e));
  }, [categoryId]);

  // const handleTabChange = (event, newValue) => {
  //   setTab(newValue);
  // };

  // useEffect(()=>{
  //   // setTab(categoryId);
  //   setFilters([categoryId])
  // },[categoryId])

  useEffect(() => {
    if (categories.length === 0) return;

    if (filters.length === 0) {
      history.push('/desktop/restaurants/');
    }

    // let id = categories[filters[0]].id;
    setLoading(true);
    console.log(filters);
    Axios.post(GET_FILTERED_RESTAURANTS_URL, {
      latitude: localStorage.getItem('userLat'),
      longitude: localStorage.getItem('userLng'),
      category_ids: filters,
    })
      .then((response) => {
        setRestaurants(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, [categories.length, filters, history]);

  // const restaurantsByCategory=(id)=>{

  // }

  return (
    <div className="container-fluid p-0">
      <Header />

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ padding: 35 }}>
              <div>
                <CarouselProvider
                  naturalSlideWidth={45}
                  naturalSlideHeight={45}
                  totalSlides={categories.length}
                  visibleSlides={10}
                  style={{ backgroundColor: '#fff' }}
                >
                  <Slider>
                    {categories.map((item, index) => {
                      return (
                        <Slide
                          style={{ paddingBottom: '4%' }}
                          key={index}
                          className={'slider-div'}
                        >
                          <Chip
                            style={{
                              marginLeft: 16,
                              marginRight: 16,
                              fontSize: 18,
                            }}
                            clickable
                            label={item.categories_ids[0].label}
                            color={
                              filters.includes(item.categories_ids[0].value)
                                ? 'primary'
                                : ''
                            }
                            onClick={() =>
                              filters.includes(item.categories_ids[0].value)
                                ? setFilters(
                                    filters.filter(
                                      (i) => i !== item.categories_ids[0].value
                                    )
                                  )
                                : setFilters([
                                    ...filters,
                                    item.categories_ids[0].value,
                                  ])
                            }
                          />
                        </Slide>
                      );
                    })}
                  </Slider>
                  <ButtonBack
                    style={{
                      background: 'none',
                      border: 'none',
                      top: '4%',
                      left: 0,
                    }}
                    className="slider-left-button"
                  >
                    <ChevronLeftIcon />
                  </ButtonBack>
                  <ButtonNext
                    style={{
                      background: 'none',
                      border: 'none',
                      top: '4%',
                      right: 0,
                    }}
                    className="slider-right-button"
                  >
                    <ChevronRightIcon />
                  </ButtonNext>
                </CarouselProvider>
              </div>

              {loading && (
                <div className="row" style={{ marginTop: '1rem' }}>
                  {[...Array(8)].map((i) => {
                    return (
                      <div className="col-md-3">
                        <div className="mb-3">
                          <Skeleton variant="rect" height={150} />
                          <Skeleton variant="text" style={{ marginTop: 5 }} />
                          <Skeleton variant="text" width={100} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {!loading && restaurants.length === 0 && <EmptyResult />}

              {!loading && restaurants.length !== 0 && (
                <div>
                  {/*<h4 style={{marginTop: '2rem', marginBottom: 0}}><b>All offers ({restaurants.length})</b></h4>
                            <Typography variant="caption">All offers and deals, from restaurants near you</Typography>*/}

                  {restaurants.length > 0 && (
                    <div
                      className="row dashed-bottom"
                      style={{ marginTop: '2rem' }}
                    >
                      {restaurants.map((item) => {
                        return (
                          <div key={item.id} className="col-md-3">
                            <div className="mb-3">
                              <div className={classes.root}>
                                <CardActionArea
                                  onClick={() =>
                                    history.push(
                                      '/desktop/restaurantDetail/' + item.slug
                                    )
                                  }
                                >
                                  <img
                                    src={ROOT_URL + item.image}
                                    alt="HotpotAfrica"
                                    style={{
                                      width: '100%',
                                      borderRadius: '5px 5px 0 0',
                                    }}
                                  />

                                  <CardContent>
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      component="h2"
                                    >
                                      {item.name}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                    >
                                      {item.description}
                                    </Typography>

                                    <div className="mt-2">
                                      <span
                                        style={{
                                          marginRight: 2,
                                          fontSize: 13,
                                          backgroundColor: 'green',
                                          padding: '3px 6px',
                                          borderRadius: 2,
                                          color: 'white',
                                        }}
                                      >
                                        <StarIcon style={{ fontSize: 12 }} />{' '}
                                        {item.rating}
                                      </span>
                                      <span
                                        style={{
                                          color: '#6f6f6f',
                                          padding: '0 6px',
                                        }}
                                      >
                                        •
                                      </span>
                                      <span style={{ fontSize: 11 }}>
                                        {item.delivery_time} MINS
                                      </span>
                                      <span
                                        style={{
                                          color: '#6f6f6f',
                                          padding: '0 6px',
                                        }}
                                      >
                                        •
                                      </span>
                                      <span style={{ fontSize: 11 }}>
                                        {item.price_range} FOR TWO
                                      </span>
                                    </div>
                                  </CardContent>
                                </CardActionArea>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
