import React, { useState, useEffect } from 'react';

// import { connect } from "react-redux";
// import { getSettings } from "../../../services/settings/actions";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import StarIcon from '@mui/icons-material/Star';
// import { useHistory } from "react-router-dom";
// import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Axios from 'axios';
import {
  GET_DELIVERY_RESTAURANTS_URL,
  ALL_COUPONS_URL,
} from '../../../configs';
import Skeleton from '@mui/lab/Skeleton';
import Header from '../Layouts/Header';
// import Paper from '@mui/material/Paper';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import { useSnackbar } from 'notistack';

export default function Offers() {
  // const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  // const [tab, setTab] = useState(0);
  // const [restaurants, setRestaurants] = useState([]);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    Axios.post(GET_DELIVERY_RESTAURANTS_URL, {
      latitude: '22.5726',
      longitude: '88.3639',
    })
      .then((res) => {
        console.log(res.data);
        // setRestaurants(res.data)
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    Axios.post(ALL_COUPONS_URL)
      .then((res) => {
        console.log(res.data);
        setCoupons(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  // const handleTabChange = (event, newValue) => {
  //   setTab(newValue);
  // };

  const copyCode = (code) => {
    navigator.clipboard.writeText(code);
    enqueueSnackbar('Code Copied!', { variant: 'success' });
  };

  return (
    <div className="container-fluid p-0">
      <Header />

      <div
        className="container"
        style={{
          position: 'sticky',
          top: 0,
          marginTop: 64,
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div style={{ padding: 35 }}>
              {loading && (
                <div className="row" style={{ marginTop: '1rem' }}>
                  {[...Array(8)].map((i) => {
                    return (
                      <div key={i} className="col-md-3">
                        <div className="mb-3">
                          <Skeleton variant="rect" height={150} />
                          <Skeleton variant="text" style={{ marginTop: 5 }} />
                          <Skeleton variant="text" width={100} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {!loading && (
                <div>
                  <h4 style={{ marginTop: '2rem', marginBottom: 0 }}>
                    <b>Available Coupons</b>
                  </h4>

                  <div className="row" style={{ marginTop: '2rem' }}>
                    {coupons.map((item) => {
                      return (
                        <div key={item.id} className="col-md-3">
                          <div className="mb-3">
                            <div style={{ border: '1px dashed grey' }}>
                              <div>
                                <CardContent style={{ height: 214 }}>
                                  <p
                                    style={{
                                      fontWeight: 'bold',
                                      textAlign: 'center',
                                      backgroundImage:
                                        'linear-gradient(to right, rgb(196, 25, 175), rgb(114, 32, 227))',
                                      padding: 5,
                                      fontSize: 18,
                                      color: 'white',
                                    }}
                                  >
                                    {item.code}
                                  </p>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                  >
                                    {item.description}
                                  </Typography>
                                </CardContent>
                                <Button
                                  onClick={() => copyCode(item.code)}
                                  style={{ margin: 5 }}
                                  color="primary"
                                >
                                  Copy Code
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
