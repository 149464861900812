import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles/';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ROOT_URL, UPDATE_USER_INFO_URL } from '../../../../configs';
import Axios from 'axios';
// import { useHistory } from "react-router-dom";
import Map from '../../../Mobile/RunningOrder/Map';
import Moment from 'react-moment';
import CountTo from 'react-count-to';
import OrderCancelPopup from '../../../Mobile/Account/Orders/OrderList/OrderCancelPopup';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: useTheme().spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function OrderTrackDrawer(props) {
  const classes = useStyles();
  // const history = useHistory();

  // const [drawer, setDrawer] = useState(false);
  // const [loading, setLoading] = useState(true);
  // const [order, setOrder] = useState({});
  // const [restaurant, setRestaurant] = useState({});
  const [user, setUser] = useState({});
  const [show_delivery_details, setShow_delivery_details] = useState(false);

  // useEffect(()=>{
  //   setDrawer(props.drawer)
  // },[props.drawer])

  // useEffect(()=>{
  //   props.setDrawer(drawer)
  // },[props, drawer])

  useEffect(() => {
    if (props.order_id === null) return;

    let user = JSON.parse(localStorage.getItem('user'));
    // setAccount(user)

    Axios.post(UPDATE_USER_INFO_URL, {
      token: user.auth_token,
      user_id: user.id,
      unique_order_id: props.order_id,
    })
      .then((response) => {
        const user = response.data;
        if (user.delivery_details) {
          setShow_delivery_details(true);
        }
        setUser(user);
        // return dispatch({ type: UPDATE_USER_INFO, payload: user });
      })
      .catch(function (error) {
        console.log(error);
      });

    // Axios.post(GET_ORDER_DETAIL_URL, {token:user.auth_token, user_id: user.id, order_id: props.order_id})
    // .then(res=>{
    //   // setOrder(res.data.order);
    //   setRestaurant(res.data.resturant_details)
    //   // setLoading(false);
    // })
    // .catch(e=>console.log(e));
  }, [props.order_id]);

  const cancelOrder = () => {
    console.log('cancelOrder');
  };

  const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    let disp = minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    return (
      <div style={{ borderWidth: 1, display: 'inline', padding: 5 }}>
        {disp}
      </div>
    );
  };

  return (
    <>
      <Drawer
        className="order-detail-drawer-wrapper"
        anchor={'right'}
        open={props.drawer}
        onClose={() => props.setDrawer(false)}
        PaperProps={{
          sx: { width: '35%' },
        }}
      >
        <div style={{ padding: 1 }}>
          <IconButton
            onClick={() => props.setDrawer(false)}
            aria-label="close"
            className={classes.margin}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className="container-fluid bg-app">
          {user.running_order && (
            <React.Fragment>
              {localStorage.getItem('showMap') === 'true' && (
                <Map
                  restaurant_latitude={user.running_order.restaurant.latitude}
                  restaurant_longitude={user.running_order.restaurant.longitude}
                  order_id={user.running_order.id}
                  orderstatus_id={user.running_order.orderstatus_id}
                  deliveryLocation={user.running_order.location}
                  order={user.running_order}
                />
              )}

              <div
                className="col-md-12 height-100 p-0"
                style={{
                  position: 'absolute',
                  top:
                    localStorage.getItem('showMap') === 'true' ? '60%' : '4rem',
                }}
              >
                {show_delivery_details && (
                  <div className="block block-link-shadow pb-2 m-0 delivery-assigned-block">
                    <div className="block-content block-content-full clearfix py-0">
                      <div className="float-right">
                        <img
                          src={
                            ROOT_URL +
                            '/public/assets/img/delivery/' +
                            user.delivery_details.photo
                          }
                          className="img-fluid img-avatar"
                          alt={user.delivery_details.name}
                        />
                      </div>
                      <div
                        className="float-left mt-20"
                        style={{ width: '75%' }}
                      >
                        <div className="font-w600 font-size-h5 mb-5">
                          {user.delivery_details.name}{' '}
                          {localStorage.getItem('deliveryGuyAfterName')}
                        </div>
                        <div className="font-size-sm text-muted">
                          {user.delivery_details.description} <br />
                          <span>
                            {localStorage.getItem('vehicleText')}{' '}
                            {user.delivery_details.vehicle_number}
                          </span>
                        </div>
                        <div className="">
                          <a
                            className="btn btn-get-direction mt-2"
                            href={'tel:' + user.delivery_details.phone}
                          >
                            {localStorage.getItem('callNowButton')}{' '}
                            <i className="si si-call-out"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-15 mb-200">
                  {user.running_order.orderstatus_id === 1 && (
                    <React.Fragment>
                      <div className="row d-none">
                        <div className="col-md-12 text-center">
                          <div className="">
                            <OrderCancelPopup
                              order={props.order_id}
                              user={user}
                              cancelOrder={cancelOrder}
                            ></OrderCancelPopup>
                          </div>
                        </div>
                        <div className="col-md-12 text-center">
                          <CountTo to={0} from={120000} speed={120000}>
                            {millisToMinutesAndSeconds}
                          </CountTo>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-placed.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderPlacedTitle'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderPlacedTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderPlacedSub'
                                  )}
                                </div>
                                <div className="font-size-xs text-muted">
                                  <Moment format="MMM Do YYYY, h:mm:ss a">
                                    {user.running_order.created_at}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                    </React.Fragment>
                  )}
                  {user.running_order.orderstatus_id === 2 && (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-preparing.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderPreparingTitle'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderPreparingTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderPreparingSub'
                                  )}
                                </div>
                                <div className="font-size-xs text-muted">
                                  <Moment format="MMM Do YYYY, h:mm:ss a">
                                    {user.running_order.updated_at}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-placed.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderPlacedTitle'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderPlacedTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderPlacedSub'
                                  )}
                                </div>
                                <div className="font-size-xs text-muted">
                                  <Moment format="MMM Do YYYY, h:mm:ss a">
                                    {user.running_order.created_at}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                    </React.Fragment>
                  )}
                  {user.running_order.orderstatus_id === 3 && (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-onway.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderDeliveryAssignedTitle'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderDeliveryAssignedTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderDeliveryAssignedSub'
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-preparing.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderPreparingTitle'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderPreparingTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderPreparingSub'
                                  )}
                                </div>
                                <div className="font-size-xs text-muted">
                                  <Moment format="MMM Do YYYY, h:mm:ss a">
                                    {user.running_order.updated_at}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-placed.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderPlacedTitle'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderPlacedTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderPlacedSub'
                                  )}
                                </div>
                                <div className="font-size-xs text-muted">
                                  <Moment format="MMM Do YYYY, h:mm:ss a">
                                    {user.running_order.created_at}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                    </React.Fragment>
                  )}
                  {user.running_order.orderstatus_id === 4 && (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-md-12">
                          {localStorage.getItem('enableDeliveryPin') ===
                            'true' && (
                            <React.Fragment>
                              <div className="font-size-h4 mb-5 px-15 text-center">
                                <div className="font-w600 btn-deliverypin">
                                  <span className="text-muted">
                                    {localStorage.getItem(
                                      'runningOrderDeliveryPin'
                                    )}{' '}
                                  </span>
                                  {this.props.user.running_order.delivery_pin}
                                </div>
                              </div>
                              <hr />
                            </React.Fragment>
                          )}

                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-onway.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderOnwayTitle'
                                  )}
                                  style={{
                                    transform: 'scaleX(-1)',
                                  }}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderOnwayTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem('runningOrderOnwaySub')}
                                </div>
                                <div className="font-size-xs text-muted">
                                  <Moment format="MMM Do YYYY, h:mm:ss a">
                                    {
                                      this.props.delivery_gps_location
                                        .updated_at
                                    }
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-onway.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderDeliveryAssignedTitle'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderDeliveryAssignedTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderDeliveryAssignedSub'
                                  )}
                                </div>
                                <div className="font-size-xs text-muted">
                                  <Moment format="MMM Do YYYY, h:mm:ss a">
                                    {
                                      this.props.delivery_gps_location
                                        .created_at
                                    }
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-preparing.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderPreparingTitle'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderPreparingTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderPreparingSub'
                                  )}
                                </div>
                                <div className="font-size-xs text-muted">
                                  <Moment format="MMM Do YYYY, h:mm:ss a">
                                    {user.running_order.updated_at}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-placed.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderPlacedTitle'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderPlacedTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderPlacedSub'
                                  )}
                                </div>
                                <div className="font-size-xs text-muted">
                                  <Moment format="MMM Do YYYY, h:mm:ss a">
                                    {user.running_order.created_at}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                    </React.Fragment>
                  )}
                  {user.running_order.orderstatus_id === 6 && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="block block-link-shadow">
                          <div className="block-content block-content-full clearfix py-0">
                            <div className="float-right">
                              <img
                                src="/assets/img/order-canceled.png"
                                className="img-fluid img-avatar"
                                alt={localStorage.getItem(
                                  'runningOrderCanceledTitle'
                                )}
                                style={{ transform: 'scaleX(-1)' }}
                              />
                            </div>
                            <div
                              className="float-left mt-20"
                              style={{ width: '75%' }}
                            >
                              <div className="font-w600 font-size-h4 mb-5">
                                {localStorage.getItem(
                                  'runningOrderCanceledTitle'
                                )}
                              </div>
                              <div className="font-size-sm text-muted">
                                {localStorage.getItem(
                                  'runningOrderCanceledSub'
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                    </div>
                  )}
                  {user.running_order.orderstatus_id === 7 && (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/ready-for-selfpickup.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderReadyForPickup'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderReadyForPickup'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderReadyForPickupSub'
                                  )}
                                </div>
                              </div>

                              <button
                                className="btn btn-get-direction mt-2"
                                onClick={() =>
                                  this.__getDirectionToRestaurant(
                                    user.running_order.restaurant.latitude,
                                    user.running_order.restaurant.longitude
                                  )
                                }
                              >
                                <i className="si si-action-redo mr-5" />
                                {localStorage.getItem(
                                  'deliveryGetDirectionButton'
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-preparing.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderPreparingTitle'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderPreparingTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderPreparingSub'
                                  )}
                                </div>
                                <div className="font-size-xs text-muted">
                                  <Moment format="MMM Do YYYY, h:mm:ss a">
                                    {user.running_order.updated_at}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="block block-link-shadow">
                            <div className="block-content block-content-full clearfix py-0">
                              <div className="float-right">
                                <img
                                  src="/assets/img/order-placed.gif"
                                  className="img-fluid img-avatar"
                                  alt={localStorage.getItem(
                                    'runningOrderPlacedTitle'
                                  )}
                                />
                              </div>
                              <div
                                className="float-left mt-20"
                                style={{ width: '75%' }}
                              >
                                <div className="font-w600 font-size-h4 mb-5">
                                  {localStorage.getItem(
                                    'runningOrderPlacedTitle'
                                  )}
                                </div>
                                <div className="font-size-sm text-muted">
                                  {localStorage.getItem(
                                    'runningOrderPlacedSub'
                                  )}
                                </div>
                                <div className="font-size-xs text-muted">
                                  <Moment format="MMM Do YYYY, h:mm:ss a">
                                    {user.running_order.created_at}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" />
                    </React.Fragment>
                  )}
                </div>
              </div>
              {/*<div>
                      <button
                        className="btn btn-lg btn-refresh-status"
                        ref="refreshButton"
                        onClick={this.__refreshOrderStatus}
                        style={{
                          backgroundColor: localStorage.getItem("cartColorBg"),
                          color: localStorage.getItem("cartColorText")
                        }}
                      >
                        {localStorage.getItem("runningOrderRefreshButton")}{" "}
                        <span ref="btnSpinner" className="hidden">
                          <i className="fa fa-refresh fa-spin" />
                        </span>
                      </button>
                    </div>*/}
            </React.Fragment>
          )}
        </div>
      </Drawer>
    </>
  );
}
