import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { ContextStore } from '../../../../configs/lib/ReactiveStatusProvider';
import { useHistory } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import ModalPopup from '../../Pages/Modal';
import { loginUser, registerUser } from '../../../../services/user/actions';
import { otpRegistry, otpVerify } from '../../Auth/Otp';
import { useSnackbar } from 'notistack';
import animationDataSentEmail from '../../../../assets/lottie/sent-email.json';
import animationAccountCreated from '../../../../assets/lottie/account-created.json';
import animationAccountFailed from '../../../../assets/lottie/account-failed.json';
import animationFormFillout from '../../../../assets/lottie/form-fillout.json';
import './styles.scss';

export default function SignupHandler({
  name,
  email,
  phone,
  password1,
  password2,
  otp,
  callbackSignupDrawer,
  callbackOtpSent,
  callbackIsLoading,
  callbackInputEmailError,
  callbackInputPasswordError,
  callbackOtpFailed,
  callbackSignupFailed,
  callbackSignupCompleted,
  isMobile,
  isDoOtp,
  isDoSignup,
  signupType,
  socialUser,
}) {
  const cookies = useMemo(() => new Cookies(), []);
  const history = useHistory();
  const { loggedInState } = useContext(ContextStore);
  const localstorage = Object.assign({}, window.localStorage);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [otpSent, setOtpSent] = useState(false);
  const [modalData, setModalData] = useState({
    modalHeadline: '',
    modalMessage: '',
    lottieData: null,
    state: false,
    loop: false,
  });

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validateInput = useCallback(() => {
    callbackInputPasswordError({
      ...callbackInputPasswordError,
      validation: false,
      identic: false,
      chars: false,
    });
    callbackInputEmailError(false);
    if (!validateEmail(email)) {
      callbackInputEmailError(true);
      return false;
    }

    if (password1 !== password2) {
      callbackInputPasswordError({
        ...callbackInputPasswordError,
        validation: true,
        identic: true,
        chars: false,
      });
      return false;
    }

    if (password1?.length < 8 || password2?.length < 8) {
      callbackInputPasswordError({
        ...callbackInputPasswordError,
        validation: true,
        identic: false,
        chars: true,
      });
      setModalData({
        ...modalData,
        state: true,
        modalHeadline: 'Password too short.',
        modalMessage: 'Please set for password as less 8 characters.',
        lottieData: animationFormFillout,
        loop: true,
      });
      return false;
    }
    return true;
  }, [
    callbackInputPasswordError,
    callbackInputEmailError,
    email,
    password1,
    password2,
    modalData,
  ]);

  const doSignup = useCallback(async () => {
    if (!validateInput) {
      callbackSignupFailed();
      return;
    }
    let user = null;
    let phoneNo = phone ? phone : '123456789';
    let otpResponse = null;
    let raw = {};
    if (!signupType) {
      raw = {
        name,
        email,
        phoneNo,
        password1,
        password2,
        otp,
      };
    } else if (signupType && signupType === 'signup0Auth') {
      user = JSON.parse(localStorage?.user);
      raw = {
        provider: user.provider,
        auth_token: user.auth_token,
        name: user.name || socialUser.name,
        email: user.email || socialUser.email,
        address: {
          address: user?.default_address?.address,
          lat: user?.default_address?.latitude,
          lng: user?.default_address?.longitude,
          house: '',
          tag: '',
        },
        phone: phoneNo,
        password: user.password || socialUser.password,
        otp: '',
      };
    } else if (signupType && signupType === 'otp') {
      const loc = localstorage?.geoLocation
        ? JSON.parse(localstorage?.geoLocation)
        : null;
      const body = {
        email: email,
        otp: otp,
      };
      otpResponse = await otpVerify(body);
      if (otpResponse?.error?.response?.data?.error) {
        callbackIsLoading(false);
        setModalData({
          ...modalData,
          state: true,
          modalHeadline: 'OTP Entry Failed',
          modalMessage: otpResponse?.error?.response?.data?.error,
          lottieData: animationAccountFailed,
        });
        callbackOtpFailed();
      } else {
        raw = {
          provider: 'otp',
          name: name,
          email: email,
          phone: phoneNo,
          address: {
            address: loc?.formatted_address,
            lat: loc?.geometry.location.lat,
            lng: loc?.geometry.location.lng,
            house: '',
            tag: '',
          },
          password: password1,
          otp: '',
        };
      }
    }

    if (otpResponse?.error?.response?.data?.error) {
      callbackOtpFailed();
      return;
    }
    callbackIsLoading(true);

    dispatch(
      registerUser(
        // name, email, phone, password, address, otp, provider
        raw.name,
        raw.email,
        raw.phone,
        raw.password,
        raw.address,
        raw.otp,
        raw.provider
      )
    )
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem('user', JSON.stringify(res.data.data));
          setTimeout(() => {
            callbackIsLoading(false);
            callbackSignupDrawer(false);

            if (!isMobile) {
              setModalData({
                ...modalData,
                state: true,
                modalHeadline: 'Successful Registered',
                modalMessage: 'Account has been successfuly created.',
                lottieData: animationAccountCreated,
              });
            }
          }, 1000);

          cookies.set('isLoggedIn', true, {
            path: '/',
            maxAge: '3600', //after 1h the session should close
          });
          dispatch(
            loginUser(
              // name, email, password, accessToken, phone, provider, address
              raw.name,
              raw.email,
              raw.password,
              raw.auth_token,
              '12345678',
              raw.provider,
              raw.default_address?.address
            )
          ).then(() => {
            loggedInState(true);
            isMobile &&
              history.push({
                pathname: '/my-account',
                state: { signupCreated: true },
              });
          });

          callbackSignupCompleted();
        } else {
          callbackSignupFailed();
          callbackIsLoading(false);
          // callbackOtpSent(true);
          callbackSignupDrawer(false);
          loggedInState(false);
          localStorage.removeItem('user');
          setModalData({
            ...modalData,
            state: true,
            modalHeadline: 'Failed Registration',
            modalMessage:
              res.data.message === 'email_already_used'
                ? 'The email you entered has been already used.'
                : 'Sorry, something went wrong. Please try again.',
            lottieData: animationAccountFailed,
          });
        }
      })
      .catch((e) => console.log(e));
  }, [
    validateInput,
    phone,
    signupType,
    callbackIsLoading,
    dispatch,
    callbackSignupFailed,
    name,
    email,
    password1,
    password2,
    otp,
    socialUser.name,
    socialUser.email,
    socialUser.password,
    localstorage?.geoLocation,
    modalData,
    callbackOtpFailed,
    cookies,
    loggedInState,
    callbackSignupCompleted,
    callbackSignupDrawer,
    isMobile,
    history,
  ]);

  const doOtp = useCallback(async () => {
    if (
      name === '' ||
      password1 === '' ||
      email === '' ||
      password1 === '' ||
      password2 === ''
    ) {
      setModalData({
        ...modalData,
        state: true,
        modalHeadline: 'Missing entries.',
        modalMessage: 'Please fill out all required fileds.',
        lottieData: animationFormFillout,
        loop: true,
      });
      setOtpSent(false);
      callbackOtpFailed();
      return;
    }

    if (!validateInput()) {
      setOtpSent(false);
      callbackOtpFailed();
      return;
    }

    callbackIsLoading(true);

    const bodyObj = {
      email: email,
      organization: localstorage?.storeName,
    };
    const otpResponse = await otpRegistry(bodyObj);

    if (otpResponse?.error?.response?.data?.error) {
      enqueueSnackbar(otpResponse?.error?.response?.data?.error, {
        variant: 'error',
      });
      callbackIsLoading(false);
      callbackInputEmailError(true);
      callbackOtpFailed();
    } else {
      if (otpResponse) {
        callbackIsLoading(false);
        callbackOtpSent(true);
        setModalData({
          ...modalData,
          state: true,
          modalHeadline: 'Please verify OTP Request',
          modalMessage:
            "Verification OTP code has been sent to your email address. Please copy the code and paste it into the required field. Please don't close your browser window",
          lottieData: animationDataSentEmail,
        });
      } else {
        callbackIsLoading(false);
        callbackOtpFailed();
        enqueueSnackbar('Something went wrong. Please try again.', {
          variant: 'error',
        });
      }
    }
  }, [
    callbackInputEmailError,
    callbackIsLoading,
    callbackOtpFailed,
    callbackOtpSent,
    email,
    enqueueSnackbar,
    localstorage?.storeName,
    modalData,
    name,
    password1,
    password2,
    validateInput,
  ]);

  useEffect(() => {
    if (isDoOtp && !otpSent) {
      setOtpSent(true);
      doOtp();
    }
    if (isDoSignup) {
      doSignup();
    }
  }, [doOtp, doSignup, isDoOtp, isDoSignup, otpSent]);

  return (
    <>
      {modalData.state && (
        <ModalPopup
          state={modalData.state}
          headline={modalData.modalHeadline}
          content={modalData.modalMessage}
          option={{ animation: false, button: true }}
          lottie={{ loop: modalData.loop, autoplay: true }}
          animationData={modalData.lottieData}
          callbackState={() => setModalData({ ...modalData, state: false })}
        />
      )}
    </>
  );
}
