import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, NavLink, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import SideDrawer from '../Navigation/SideDrawer';
import {
  getAllLanguages,
  getSingleLanguageData,
} from '../../../services/languages/actions';
import getLanguageWord from '../../Shared/Lib/getLanguageWord';
import { useOnlineStatus } from '../../../configs/lib/OnlineStatusProvider';
import MainSlider from '../../Shared/MainSlider/MainSlider';
import OffLine from '../../Shared/Pages/OffLine';
import './styles.scss';

const Footer = ({ cartTotal }) => {
  const isOnline = useOnlineStatus();
  const localstorage = Object.assign({}, window.localStorage);
  const curLanguages = useSelector((state) => state.languages.languages);
  const language = useSelector((state) => state.languages.language);
  const [drawerState, setDrawerState] = useState(false);
  const [languageWord, setLanguageWord] = useState();
  const { location } = useHistory();
  const dispatch = useDispatch();

  const closeDrawer = (status) => {
    return setDrawerState(status);
  };

  useEffect(() => {
    if (!curLanguages) {
      dispatch(getAllLanguages());
      dispatch(getSingleLanguageData(localstorage.usePreferedLanguage));
    }
    if (curLanguages) {
      setLanguageWord(getLanguageWord(curLanguages));
    }
  }, [curLanguages, dispatch, language, localstorage.usePreferedLanguage]);

  return (
    <>
      {!isOnline && <OffLine />}
      {location?.pathname === '/search-location' && (
        <div className="mobile-main-slider-wrapper">
          <MainSlider device="mobile" />
        </div>
      )}
      {curLanguages && (
        <SideDrawer
          state={drawerState}
          close={closeDrawer}
          language={language || localstorage}
          languages={curLanguages}
          languageWord={languageWord}
        />
      )}
      <div className="content pt-10 py-5 font-size-xs clearfix footer-fixed">
        <NavLink to="/search-location" className="col footer-links px-2 py-1">
          <i className="si si-pointer fa-2x" /> <br />
          <span
            className={
              location.pathname === '/search-location'
                ? 'active-footer-tab'
                : ''
            }
          >
            <span>
              {' '}
              {language?.sideDrawerMenuItemRestaurants ||
                localstorage?.sideDrawerMenuItemRestaurants}
            </span>
          </span>
        </NavLink>
        <NavLink to="/cart" className="col footer-links px-2 py-1">
          <div className="cart-quantity-badge-wrapper">
            {cartTotal && cartTotal.productQuantity > 0 ? (
              <div
                className="cart-quantity-badge"
                style={{ backgroundColor: localstorage.storeColor }}
              >
                {cartTotal.productQuantity}
              </div>
            ) : (
              ''
            )}
            <i
              className={`${
                !!cartTotal?.productQuantity > 0 && 'cart-quantity-badge-icon'
              } si si-bag fa-2x`}
            />
            <div
              className={
                location.pathname === '/cart'
                  ? `${
                      cartTotal?.productQuantity > 0
                        ? 'cart-quantity-badge-text active-footer-tab'
                        : 'active-footer-tab'
                    }`
                  : location.pathname !== '/cart' &&
                    cartTotal?.productQuantity > 0
                  ? 'cart-quantity-badge-text'
                  : ''
              }
            >
              {language?.footerCart || localstorage?.footerCart}
            </div>
          </div>
        </NavLink>
        <NavLink to="/my-account" className="col footer-links px-2 py-1">
          <i className="si si-user fa-2x" /> <br />
          <span
            className={
              location.pathname === '/my-account' ||
              location.pathname === '/login'
                ? 'active-footer-tab'
                : ''
            }
          >
            {language?.footerAccount || localstorage?.footerAccount}
          </span>
        </NavLink>
        <div
          className="col footer-links px-2 py-1"
          onClick={() => setDrawerState(!drawerState)}
        >
          <i className="si si-list fa-2x" /> <br />
          <span
            className={
              location.pathname === '/explore' ? 'active-footer-tab' : ''
            }
          >
            {localstorage?.footerExplore || localstorage?.footerExplore}
          </span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  cartTotal: state.total.data,
});

export default withRouter(connect(mapStateToProps)(Footer));
