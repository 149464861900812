import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import HandleLanguages from './HandleLanguages';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import changeLanguage from '../Lib/changeLanguageId';
import '../../Desktop/styles.scss';

export default function LanguageDrawer({
  device,
  drawerTitleText,
  languages,
  languageWord,
  deviceLanguageImage,
}) {
  const history = useHistory();
  const [languageDrawer, setLanguageDrawer] = useState(false);
  const [isPreferedLanguage, setPreferedLanguage] = useState(false);

  const handleLanguageDrawer = () => {
    setLanguageDrawer(!languageDrawer);
  };

  const getChangeLanguage = (id) => {
    changeLanguage(id);
    setPreferedLanguage(true);
  };

  useEffect(() => {
    if (isPreferedLanguage) {
      setTimeout(() => {
        history.go(0);
        setPreferedLanguage(false);
      }, 1000);
    }
  }, [history, isPreferedLanguage]);

  return (
    <>
      <div
        type="button"
        className={
          languageDrawer
            ? 'desktop-header-nav-btns desktop-header-nav-btn--active'
            : 'desktop-header-nav-btns desktop-header-nav-btn'
        }
        onClick={() => handleLanguageDrawer()}
      >
        {deviceLanguageImage}
        {languageWord}
      </div>
      <Drawer
        anchor={'top'}
        open={languageDrawer}
        onClose={() => handleLanguageDrawer()}
        PaperProps={{
          sx: {
            maxHeight: 220,
          },
        }}
      >
        <div className="shared-language-wrapper" style={{ padding: 25 }}>
          <Typography variant="h5" gutterBottom>
            {drawerTitleText}
          </Typography>
          <IconButton onClick={() => handleLanguageDrawer()} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>

        {!isPreferedLanguage ? (
          <HandleLanguages
            device={device}
            languages={languages}
            selectLanguage={getChangeLanguage}
            position={'body'}
          />
        ) : (
          <img
            src="/assets/img/various/spinner.svg"
            className="location-loading-spinner"
            alt="loading"
          />
        )}
      </Drawer>
    </>
  );
}
