import React from 'react';

import Header from '../Layouts/Header';
import Typography from '@mui/material/Typography';

export default function TermsAndConditions() {
  return (
    <div className="container-fluid p-0">
      <Header />
      <div
        className="row"
        style={{ padding: '40px 50px', backgroundColor: '#eb4b60' }}
      >
        <div className="col-md-12">
          <h3 style={{ color: 'white', marginBottom: 5 }}>
            Terms & Conditions
          </h3>
        </div>
      </div>

      <div
        className="row"
        style={{ padding: '0px 50px', backgroundColor: '#eb4b60' }}
      >
        <div
          className="col-md-12"
          style={{ backgroundColor: '#fff', padding: 45 }}
        >
          <Typography variant="body1">CONTENT HERE...</Typography>
        </div>
      </div>
    </div>
  );
}
