import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ContextStore } from '../../../configs/lib/ReactiveStatusProvider';
import { Cookies } from 'react-cookie';
import ModalPopup from '../../Shared/Pages/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LOGIN_USER_URL } from '../../../configs';
import { LOGIN_USER } from '../../../services/user/actionTypes';
import Social0Auth from '../../Shared/Auth/Social';
// import { loginUser } from '../../../services/user/actions';
import Axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import animationAccountFailed from '../../../assets/lottie/account-failed.json';
import animationAccountLocked from '../../../assets/lottie/account-locked.json';
import '../styles.scss';

const LoginDrawer = (props) => {
  const cookies = new Cookies();
  const localstorage = Object.assign({}, window.localStorage);

  const history = useHistory();
  const location = useLocation();
  const { loggedIn, loggedInState } = useContext(ContextStore);

  const dispatch = useDispatch();
  const [login, setLoginDrawer] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({
    modalHeadline: '',
    modalMessage: '',
    lottieData: null,
    state: false,
    loop: false,
  });

  const user = localstorage?.user;

  const handleSetLoginCookie = () => {
    let expires = new Date();
    expires.setTime(expires.getTime() + 60 * 60 * 1000);
    cookies.set('isLoggedIn', true, {
      path: '/',
      expires, //after 1h the session should close
    });
  };

  const setLoggedInState = () => {
    if (!loggedIn) {
      handleSetLoginCookie();
      if (user && user?.length > 0) {
        const socialAlteredUser = {
          ...user,
          accessToken: user.auth_token,
          type: '0Auth',
          success: true,
        };
        setTimeout(() => {
          setLoading(false);
          setLoginDrawer(false);
          location?.pathname === '/desktop/cart'
            ? setLoggedInState()
            : history.push('/desktop/restaurants');
        }, 800);
        return dispatch({ type: LOGIN_USER, payload: socialAlteredUser });
      }
      loggedInState(true);
      setLoginDrawer(false);
    }
  };

  useEffect(() => {
    if (props.trigger) {
      setLoginDrawer(!props.registry ? true : false);
    }
  }, [props.registry, props.trigger]);

  const doLogin = () => {
    if (email === '') {
      setEmailError(true);
      return;
    }
    if (password === '') {
      setPasswordError(true);
      return;
    }

    setEmailError(false);
    setPasswordError(false);
    setLoading(true);

    let raw = {
      email,
      password,
    };

    Axios.post(`${LOGIN_USER_URL}`, raw)
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem('user', JSON.stringify(res.data.data));
          setTimeout(() => {
            setLoading(false);
            setLoginDrawer(false);
            location?.pathname === '/desktop/cart'
              ? setLoggedInState()
              : history.push('/desktop/restaurants');
          }, 800);
          const user = {
            ...res.data.data,
            success: true,
          };

          handleSetLoginCookie();

          loggedInState(true);
          return dispatch({ type: LOGIN_USER, payload: user });
        } else {
          setLoading(false);
          // setMessage(res.data.data);
          // setShowMessage(true);
          // eslint-disable-next-line no-unused-expressions
          if (res.data.data === 'DONOTMATCH') {
            setEmailError(true);
            setPasswordError(true);
          }
          setModalData({
            ...modalData,
            state: true,
            modalHeadline:
              res.data.data === 'DONOTMATCH'
                ? "Credentials doesn't match!"
                : 'Error',
            modalMessage:
              res.data.data === 'DONOTMATCH'
                ? 'Your entered email or password is wrong. Please try again.'
                : 'Sorry, something went wrong. Please try again.',
            lottieData: animationAccountFailed,
            loop: false,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message === 'Too Many Attempts.') {
          setModalData({
            ...modalData,
            state: true,
            modalHeadline: 'Too Many Attempts.',
            modalMessage:
              'You tried too many times to enter wrong password. Your account has been locked for 1 minute.',
            lottieData: animationAccountLocked,
            loop: true,
          });
          setLoginDrawer(false);
          setLoading(false);
        }
      });
  };

  const handleLoginDrawer = () => {
    setLoginDrawer(!login);
    props.triggerCallback();
  };

  return (
    <>
      {(!user || user === 'undefined' || Object.keys(user)?.length < 1) &&
        location.pathname === '/' && (
          <div
            type="button"
            className={
              login
                ? 'desktop-header-nav-btns desktop-header-nav-btn--active'
                : 'desktop-header-nav-btns desktop-header-nav-btn'
            }
            onClick={() => handleLoginDrawer()}
          >
            {props.login}
          </div>
        )}

      {modalData.state && (
        <ModalPopup
          state={modalData.state}
          headline={modalData.modalHeadline}
          content={modalData.modalMessage}
          option={{ animation: false, button: true }}
          lottie={{ loop: modalData.loop, autoplay: true }}
          animationData={modalData.lottieData}
          callbackState={() => setModalData({ ...modalData, state: false })}
        />
      )}

      <Drawer anchor={'right'} open={login} onClose={() => handleLoginDrawer()}>
        <div style={{ padding: 1, color: 'white' }}>
          <IconButton onClick={() => handleLoginDrawer()} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ padding: 25 }}>
          <Typography variant="h5" gutterBottom>
            Login
          </Typography>
          <Typography variant="body" gutterBottom>
            or{' '}
          </Typography>
          <Typography
            style={{ color: '#8c1ed3', cursor: 'pointer' }}
            variant="body"
            gutterBottom
            onClick={() => {
              setLoginDrawer(!login);
              props.triggerCallback('registry');
            }}
          >
            Create an account
          </Typography>

          <br />

          <div className="login-text-fields-wrapper">
            <TextField
              className="filled-input-white"
              style={{
                marginTop: 20,
                backgroundColor: 'white',
                borderColor: 'black',
                width: '100%',
              }}
              label="Email"
              id="filled-size-small"
              defaultValue=""
              onChange={(e) => setEmail(e.target.value)}
              error={emailError}
              type="email"
              variant="outlined"
            />

            <TextField
              className="filled-input-white"
              style={{
                marginBottom: 30,
                marginTop: 20,
                backgroundColor: 'white',
                borderColor: 'black',
                width: '100%',
              }}
              label="Password"
              id="filled-size-small"
              defaultValue=""
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              variant="outlined"
            />

            <Button
              disabled={loading}
              loading={loading}
              onClick={() => doLogin()}
              style={{
                marginTop: 20,
                padding: 10,
                width: '100%',
                backgroundColor: '#8c1ed3',
                borderRadius: 4,
                color: 'white',
              }}
              variant="contained"
              size="large"
              color="primary"
            >
              {loading ? 'Please Wait...' : 'LOGIN'}
            </Button>
            <Social0Auth
              action="login"
              type="login0Auth"
              callback={setLoggedInState}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default LoginDrawer;
