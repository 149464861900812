import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import './styles.scss';

const OffLine = () => {
  const history = useHistory();
  let [countDown, setCountDown] = useState(10);
  const localstorage = Object.assign({}, window.localStorage);
  const { enqueueSnackbar } = useSnackbar();
  const countdown = () => {
    let time = null;
    let downloadTimer = setInterval(function () {
      countDown--;
      setCountDown(countDown);
      if (countDown <= 0) {
        clearInterval(downloadTimer);
        history.go(0);
      }
    }, 1000);
    return time;
  };
  countdown();
  return (
    <>
      <div className="snackbar-offline-advice">
        {countDown === 10 &&
          enqueueSnackbar(localstorage.offlineMessage, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            autoHideDuration: 9000,
          })}
      </div>
      <div className="countdown">{countDown < 10 && countDown}</div>
    </>
  );
};

export default OffLine;
