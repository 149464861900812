import { Map, useMap } from '@vis.gl/react-google-maps';
import React, { useState, useEffect, useMemo } from 'react';

import Axios from 'axios';
import { GET_ADDRESS_FROM_COORDINATES } from '../../../../configs';
import '../styles.scss';

const mapStyle = [
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

//alert Google Map Error
window.gm_authFailure = (error) => {
  alert(error);
};

export const GoogleMaps = ({ mapId, centerMap, onMarkerDragEnd }) => {
  const [loading, setLoading] = useState(true);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [address, setAddress] = useState('');

  const map = useMap();

  const initialPosition = useMemo(() => {
    return {
      lat: parseFloat(centerMap.lat),
      lng: parseFloat(centerMap.lng),
    };
  }, [centerMap.lat, centerMap.lng]);

  useEffect(() => {
    const loadingTime = setTimeout(() => {
      setLoading(false);
      clearTimeout(loadingTime);
    }, 3000);
  }, []);

  useEffect(() => {
    if (!map) return;
    map.setCenter(initialPosition);
  }, [map, initialPosition]);

  const onMarkerClick = () => {
    let markerInfo = null;
    try {
      Axios.post(GET_ADDRESS_FROM_COORDINATES, {
        lat: localStorage.getItem('userLat'),
        lng: localStorage.getItem('userLng'),
      }).then((res) => {
        setAddress(res.data);
        setShowingInfoWindow(true);
        clearTimeout(markerInfo);
        markerInfo = setTimeout(() => {
          setShowingInfoWindow(false);
        }, 3000);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {loading ? (
        <div>Loading ...</div>
      ) : (
        <Map
          mapId={mapId}
          map={map}
          defaultZoom={18}
          onDragend={(m) => {
            const { map } = m;
            onMarkerDragEnd(map);
            onMarkerClick();
          }}
          styles={{ mapStyle }}
          defaultCenter={{
            lat: parseFloat(
              initialPosition.lat || localStorage.getItem('userLat')
            ),
            lng: parseFloat(
              initialPosition.lng || localStorage.getItem('userLng')
            ),
          }}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
        />
      )}

      <div className="map-marker-wrapper">
        {showingInfoWindow && <div className="map-marker-info">{address}</div>}
        <div className="center-marker-pulse1" name={'Current location'}>
          <img
            style={{ width: 28, top: '35%', left: '50%' }}
            src="/assets/img/various/dragable-marker.png"
            alt="Marker"
            className="center-marker"
          />
        </div>
      </div>
    </>
  );
};
