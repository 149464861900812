export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const VERIFY_USER_IS_LOGGED_IN = 'VERIFY_USER_IS_LOGGED_IN';
export const USER_CHANGE_LOGIN_CREDENTIALS = 'USER_CHANGING_LOGIN_CREDENTIALS';
export const USER_CHANGE_PROFILE_DATA = 'USER_CHANGE_PROFILE_DATA';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const SEND_OTP = 'SEND_OTP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const RUNNING_ORDER = 'RUNNING_ORDER';
export const GET_WALLET_TRANSACTIONS = 'GET_WALLET_TRANSACTIONS';
export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL';
export const VERIFY_PASSWORD_RESET_OTP = 'VERIFY_PASSWORD_RESET_OTP';
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const SUBMIT_SUPPORT = 'SUBMIT_SUPPORT';
