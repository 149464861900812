import React, { useState, useEffect, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import { GET_ADDRESSES_URL } from '../../../../configs';
import {
  setDefaultAddress,
  deleteAddress,
} from '../../../../services/addresses/actions';
import { getSettings } from '../../../../services/settings/actions';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Axios from 'axios';
// import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
// import MapMarkerIcon from '@mui/icons-material/MyLocation';
/* import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';*/
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddAddressDrawer from './AddAddressDrawer';
import setAsDefaultAddress from '../../../Shared/Lib/setAsDefaultAddress';
import '../styles.scss';

export default function MyAddress(props) {
  const { user } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const settings = useSelector((state) => state.settings);

  const [loading, setLoading] = useState(true);
  const [myAddress, setMyAddress] = useState([]);
  const [isLoadedAddrList, loadedAddrList] = useState(false);
  const [open, setOpen] = useState(false);
  const [manageAddr, setManageAddr] = useState({});

  const loadAddress = useCallback(() => {
    loadedAddrList(true);
    const setUser =
      !user?.user?.id && localStorage.getItem('userSetAddress')
        ? JSON.parse(localStorage.getItem('userSetAddress'))
        : user?.user;
    console.log(setUser);
    // ID verified then user has been saved, otherwise social 0Auth login
    if (setUser?.id) {
      Axios.post(GET_ADDRESSES_URL, {
        token: setUser?.auth_token,
        user_id: setUser?.id,
      })
        .then((res) => {
          console.log(res);
          setMyAddress(res?.data);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    } else {
      setMyAddress([
        {
          id: 0,
          address:
            setUser?.default_address?.address || setUser?.formatted_address,
        },
      ]);
      setLoading(false);
    }
  }, [user.user]);

  useEffect(() => {
    if (!isLoadedAddrList) {
      loadAddress();
    }
    if (
      // eslint-disable-next-line no-prototype-builtins
      settings.hasOwnProperty('settings') &&
      !settings?.settings?.length
    ) {
      dispatch(getSettings());
    }
  }, [
    dispatch,
    isLoadedAddrList,
    loadAddress,
    loadedAddrList,
    settings,
    settings.length,
    settings?.settings?.length,
  ]);

  const deleteAddr = () => {
    dispatch(deleteAddress(user.user.id, manageAddr.id, user.user.auth_token));
    loadAddress();
  };

  const setAssDefaultAddress = () => {
    dispatch(
      setDefaultAddress(user.user.id, manageAddr.id, user.user.auth_token)
    );
    setAsDefaultAddress(user.user, manageAddr);
    loadAddress();
    let timer = null;
    timer = setTimeout(() => {
      history.go(0);
      clearTimeout(timer);
    }, [1000]);
  };

  const initManageAddr = (item, type) => {
    item.type = type;
    setManageAddr(item);
    setOpen(true);
  };

  const proceedManageAddr = () => {
    if (manageAddr.type === 'delete') {
      setOpen(false);
      deleteAddr();
    }

    if (manageAddr.type === 'default') {
      setOpen(false);
      // let user = JSON.parse(localStorage.getItem('user'));

      localStorage.setItem('userLat', manageAddr.latitude);
      localStorage.setItem('userLng', manageAddr.longitude);
      localStorage.setItem('address', manageAddr.address);

      user.user.default_address = manageAddr;
      user.user.default_address_id = manageAddr.id;
      localStorage.setItem('user', JSON.stringify(user.user));

      //history.push('/desktop/restaurants/');
      setAssDefaultAddress();
    }

    if (manageAddr.type === 'edit') {
      // localStorage.setItem('userLat', manageAddr.latitude)
      // localStorage.setItem('userLng', manageAddr.longitude)
      // localStorage.setItem('address', manageAddr.address)
      // history.goBack();
      history.push('setLocation', { edit: true, manageAddr });
    }
  };

  console.log(myAddress);
  return (
    <div className="container-fluid">
      {loading && (
        <div className="row">
          {[...Array(5)].map((_, index) => {
            const uniqueKey = `skelet-${index}`;
            return (
              <div key={uniqueKey} className="col-md-12">
                <div className="mb-3 bg-white p-3">
                  <Skeleton variant="rect" height={64} />
                  <Skeleton variant="text" style={{ marginTop: 5 }} />
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={50} />
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="row">
        {!loading &&
          myAddress?.length &&
          !myAddress.includes('error-to-determine-address') &&
          myAddress?.map((item, i) => {
            return (
              <div key={i} className="col-6 mb-3">
                <div
                  key={`${i}-address-block`}
                  className={`address-blocks-wrapper bg-white ${
                    item.id === item.default && 'address-block-wrapper--default'
                  }`}
                  style={{ borderBottom: '1px solid #bfbfbf' }}
                >
                  <CardContent>
                    <div className="row">
                      <div className="col-12">
                        <Typography variant="h6" component="h2">
                          {item.tag}
                          <span
                            style={{ fontSize: 12 }}
                            className="float-right"
                          >
                            {item.house}
                          </span>
                        </Typography>
                        <Typography
                          className="address-item"
                          variant="caption"
                          style={{ color: '#000', fontSize: '12px' }}
                        >
                          {item.address}
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                  {!!user?.user.id && (
                    <CardActions>
                      <Button
                        onClick={() => initManageAddr(item, 'delete')}
                        style={{ color: 'red' }}
                        size="small"
                      >
                        Delete
                      </Button>
                      {/*<Button onClick={()=>initManageAddr('edit', item)} style={{color: 'rebeccapurple'}} size="small">Edit</Button>*/}
                      <Button
                        onClick={() => initManageAddr(item, 'default')}
                        style={{ color: 'green' }}
                        size="small"
                      >
                        {item.id !== item.default && 'Set Default'}
                      </Button>
                    </CardActions>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <div className="row mt-5 mb-5">
        <div className="col-12">
          <AddAddressDrawer
            refresh={loadAddress}
            settings={settings}
            user={user?.user}
          />
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are You Sure !</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {manageAddr.type === 'delete' && (
              <>
                You are about to delete the saved address. Do you really want to
                proceed ?
              </>
            )}
            {manageAddr.type === 'default' && (
              <>
                You are about to change the default address. Do you really want
                to proceed ?
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            No
          </Button>
          <Button onClick={() => proceedManageAddr()} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
