export const formatPrice = (price) => {
  if (price) {
    let formatedPrice = parseFloat(price);
    formatedPrice = formatedPrice.toFixed(2);
    return formatedPrice;
  }
  return 0;
};

export const intlFormatNumber = (valuta, price, side) => {
  side = side === 'left' ? 'en-GB' : 'de-DE';
  return new Intl.NumberFormat(side, {
    style: 'currency',
    currency: valuta,
  }).format(price);
};
