import {
  LOGIN_USER,
  VERIFY_USER_IS_LOGGED_IN,
  REGISTER_USER,
  USER_CHANGE_LOGIN_CREDENTIALS,
  USER_CHANGE_PROFILE_DATA,
  LOGOUT_USER,
  UPDATE_USER_INFO,
  SEND_OTP,
  VERIFY_OTP,
  RUNNING_ORDER,
  GET_WALLET_TRANSACTIONS,
  SEND_PASSWORD_RESET_EMAIL,
  VERIFY_PASSWORD_RESET_OTP,
  CHANGE_USER_PASSWORD,
  UPDATE_USER_PROFILE,
  SUBMIT_SUPPORT,
} from './actionTypes';
import {
  LOGIN_USER_URL,
  LOGOUT_USER_URL,
  VERIFY_USER_IS_LOGGED_IN_URL,
  USER_CHANGE_LOGIN_CREDENTIALS_URL,
  USER_CHANGE_PROFILE_DATA_URL,
  REGISTER_USER_URL,
  UPDATE_USER_INFO_URL,
  SEND_REGISTRATION_OTP_URL,
  VERIFY_OTP_URL,
  CHECK_USER_RUNNING_ORDER_URL,
  GET_WALLET_TRANSACTIONS_URL,
  SEND_PASSWORD_RESET_EMAIL_URL,
  VERIFY_PASSWORD_RESET_OTP_URL,
  CHANGE_USER_PASSWORD_URL,
  POST_USER_PROFILE,
  USER_SUPPORT,
} from '../../configs';

import Axios from 'axios';

export const loginSocialUser =
  (name, email, password, accessToken, phone, provider, address) =>
  (dispatch) => {
    // dispatch({ type: LOGIN_USER, payload: {} });
    const user = {
      name: name,
      email: email,
      password: password,
      accessToken: accessToken,
      phone: phone,
      provider: provider,
      type: '0Auth',
      address: address,
      success: true,
    };
    console.log(user);
    return dispatch({ type: LOGIN_USER, payload: user });
  };

export const loginUser =
  (name, email, password, accessToken, phone, provider, address) =>
  async (dispatch) => {
    // dispatch({ type: LOGIN_USER, payload: {} });
    await Axios.post(LOGIN_USER_URL, {
      name: name,
      email: email,
      password: password,
      accessToken: accessToken,
      phone: phone,
      provider: provider,
      type: 'OTP',
      address: address,
    })
      .then((response) => {
        const user = response.data;
        return dispatch({ type: LOGIN_USER, payload: user });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

export const registerUser =
  (name, email, phone, password, address, otp, provider) =>
  async (dispatch) => {
    return await Axios.post(REGISTER_USER_URL, {
      name: name,
      email: email,
      phone: phone,
      password: password,
      address: address,
      otp: otp,
      provider: provider,
    })
      .then((response) => {
        const user = response.data;
        dispatch({ type: REGISTER_USER, payload: user });
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

export const logoutUser = (user) => (dispatch) => {
  const { email, auth_token, provider } = user?.data || user;

  Axios.post(LOGOUT_USER_URL, {
    email: email,
    auth_token: auth_token,
    provider: provider,
  })
    .then((response) => {
      console.log(response.data);
      user = [];
      const walletEmpty = [];
      dispatch({
        type: LOGOUT_USER,
        payload: user,
      });
      dispatch({
        type: RUNNING_ORDER,
        payload: false,
      });
      dispatch({
        type: GET_WALLET_TRANSACTIONS,
        payload: walletEmpty,
      });
    })
    .catch(function (error) {
      console.log(error);
    });

  //remove geoLocation and userSetAddress
  //localStorage.removeItem('userSetAddress');
  //localStorage.removeItem('geoLocation');

  localStorage.removeItem('addresses');
};

export const verifyUserIsLoggedIn = (user) => (dispatch) => {
  Axios.post(VERIFY_USER_IS_LOGGED_IN_URL, {
    email: user.email,
  }).then((response) => {
    const user = response.data;
    dispatch({
      type: VERIFY_USER_IS_LOGGED_IN,
      payload: user,
    });
  });
};

export const userChangeLoginCredentials =
  (email, password, token) => (dispatch) => {
    Axios.post(USER_CHANGE_LOGIN_CREDENTIALS_URL, {
      email: email,
      password: password,
      token: token,
    }).then((response) => {
      const user = response.data;
      dispatch({
        type: USER_CHANGE_LOGIN_CREDENTIALS,
        payload: user,
      });
    });
  };

export const userChangeProfileData =
  (email, name, phone, token) => (dispatch) => {
    Axios.post(USER_CHANGE_PROFILE_DATA_URL, {
      email: email,
      name: name,
      phone: phone,
      token: token,
    }).then((response) => {
      const user = response.data;
      dispatch({
        type: USER_CHANGE_PROFILE_DATA,
        payload: user,
      });
    });
  };

export const updateUserInfo =
  (user_id, token, unique_order_id) => (dispatch) => {
    return Axios.post(UPDATE_USER_INFO_URL, {
      token: token,
      user_id: user_id,
      unique_order_id: unique_order_id,
    })
      .then((response) => {
        const user = response.data;
        return dispatch({ type: UPDATE_USER_INFO, payload: user });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

export const checkUserRunningOrder = (user_id, token) => (dispatch) => {
  Axios.post(CHECK_USER_RUNNING_ORDER_URL, {
    token: token,
    user_id: user_id,
  })
    .then((response) => {
      const running_order = response.data;
      return dispatch({ type: RUNNING_ORDER, payload: running_order });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const sendOtp = (email, phone, accessToken, provider) => (dispatch) => {
  Axios.post(SEND_REGISTRATION_OTP_URL, {
    email: email,
    phone: phone,
    accessToken: accessToken,
    provider: provider,
  })
    .then((response) => {
      const user = response.data;
      return dispatch({ type: SEND_OTP, payload: user });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const verifyOtp = (phone, otp) => (dispatch) => {
  Axios.post(VERIFY_OTP_URL, {
    phone: phone,
    otp: otp,
  })
    .then((response) => {
      const user = response.data;
      return dispatch({ type: VERIFY_OTP, payload: user });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getWalletTransactions = (token, user_id) => (dispatch) => {
  Axios.post(GET_WALLET_TRANSACTIONS_URL, {
    token: token,
    user_id: user_id,
  })
    .then((response) => {
      const wallet = response.data;
      return dispatch({ type: GET_WALLET_TRANSACTIONS, payload: wallet });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const sendPasswordResetMail = (email) => (dispatch) => {
  Axios.post(SEND_PASSWORD_RESET_EMAIL_URL, {
    email: email,
  })
    .then((response) => {
      const data = response.data;
      return dispatch({ type: SEND_PASSWORD_RESET_EMAIL, payload: data });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const verifyPasswordResetOtp = (email, code) => (dispatch) => {
  Axios.post(VERIFY_PASSWORD_RESET_OTP_URL, {
    email: email,
    code: code,
  })
    .then((response) => {
      const data = response.data;
      return dispatch({ type: VERIFY_PASSWORD_RESET_OTP, payload: data });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const changeUserPassword = (email, code, password) => (dispatch) => {
  Axios.post(CHANGE_USER_PASSWORD_URL, {
    email: email,
    code: code,
    password: password,
  })
    .then((response) => {
      const data = response.data;
      return dispatch({ type: CHANGE_USER_PASSWORD, payload: data });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateUserProfile = (data) => (dispatch) => {
  Axios.post(POST_USER_PROFILE, data, {
    headers: {
      accept: 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
  })
    .then((response) => {
      const data = response.data;
      return dispatch({ type: UPDATE_USER_PROFILE, payload: data });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const submitSupport = (data) => (dispatch) => {
  Axios.post(USER_SUPPORT, data, {
    headers: {
      accept: 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
  })
    .then((response) => {
      const data = response.data;
      return dispatch({ type: SUBMIT_SUPPORT, payload: data });
    })
    .catch(function (error) {
      console.log(error);
    });
};
