import React from 'react';

import Typography from '@mui/material/Typography';
import Flip from 'react-reveal/Flip';
import Ink from 'react-ink';
import { useHistory } from 'react-router-dom';
import { GetAddressFromCoordinates } from '../GetAddressFromCoordinates';
import { ROOT_URL } from '../../../configs/index';
import '../styles.scss';

const PopularPlaces = ({
  loading,
  device,
  locations,
  handleGeoLocationClick,
  language,
}) => {
  const history = useHistory();
  const localstorage = Object.assign({}, window.localStorage);
  const handleLocationAddressRetry = async (location) => {
    const res = await GetAddressFromCoordinates(
      location.latitude,
      location.longitude
    );
    return res.data;
  };

  return (
    <>
      {localstorage?.popularLocations === '1' && !loading && (
        <div
          className={`${
            device === 'desktop'
              ? 'popular-places-title--desktop'
              : 'popular-places-title--mobile'
          }`}
        >
          <Typography
            variant="caption"
            style={{
              fontWeight: 'bold',
              fontSize: '0.9rem',
              color: '#9e9e9e',
            }}
            gutterBottom
          >
            {language?.searchPopularPlaces}
          </Typography>
        </div>
      )}

      {localstorage?.popularLocations !== '1' && !loading && (
        <div
          className={`${
            device === 'desktop'
              ? 'popular-places-title--desktop'
              : 'popular-places-title--mobile'
          }`}
        >
          <Typography
            variant="caption"
            style={{
              fontWeight: 'bold',
              fontSize: '0.9rem',
              color: '#9e9e9e',
            }}
            gutterBottom
          >
            {localstorage?.searchAllStores}
          </Typography>
        </div>
      )}
      <div className="popular-places-flip-btns-wrapper">
        {localstorage?.popularLocations === '1' &&
          locations
            ?.sort(() => Math.random() - 0.5)
            .map((location, index) => (
              <div key={index}>
                {(device === 'desktop' && index <= 4) ||
                (device === 'mobile' && index <= 2) ? (
                  <Flip top delay={index * 50} key={location.id}>
                    <button
                      type="button"
                      className="popular-places-flip-btn btn btn-rounded btn-alt-secondary btn-md mr-9"
                      style={{
                        position: 'relative',
                        height: '52px',
                      }}
                      onClick={async () => {
                        const restaurantAddress =
                          await handleLocationAddressRetry(location).then(
                            (res) => res
                          );
                        const geoLocation = [
                          {
                            formatted_address: restaurantAddress,
                            geometry: {
                              location: {
                                lat: location.latitude,
                                lng: location.longitude,
                              },
                            },
                            slug: location.slug,
                          },
                        ];
                        handleGeoLocationClick(geoLocation);
                      }}
                    >
                      <Ink duration="100" />
                      <img
                        src={ROOT_URL + location.image}
                        className="popular-places-circle-images"
                        alt={location.name}
                      />
                      {location.name}
                    </button>
                  </Flip>
                ) : null}
              </div>
            ))}
      </div>
      <Flip top delay={50}>
        <button
          type="button"
          className="popular-places-flip-btn popular-all-places-flip-btn btn btn-rounded btn-alt-secondary btn-md mb-15 mr-15"
          style={{ position: 'relative', height: '52px' }}
          onClick={() =>
            history.push(
              `${
                device === 'desktop' ? '/desktop/restaurants' : '/restaurants'
              }`
            )
          }
        >
          <Ink duration="100" />
          <img
            src={
              localstorage?.storeLogoSm
                ? `${ROOT_URL}/${localstorage?.storeLogoSm}`
                : `/assets/img/fav.png`
            }
            className="popular-places-circle-images"
            alt={'all-stores'}
          />
          {localstorage?.allStoresFlipButton}
        </button>
      </Flip>
    </>
  );
};

export default PopularPlaces;
