import * as firebase from 'firebase/app';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Meta from '../../helpers/meta';
import Navigation from '../Navigation';
import ProgressiveImage from 'react-progressive-image';
import PromoSlider from './PromoSlider';
import { withRouter } from 'react-router-dom';
import { ROOT_URL } from '../../../configs/index';
import { connect } from 'react-redux';
import { getPromoSlides } from '../../../services/promoSlider/actions';

import messaging from '../../../init-fcm';
import { saveNotificationToken } from '../../../services/notification/actions';
import { getUserNotifications } from '../../../services/alert/actions';
import './styles.scss';

const Home = ({ user, promo_slides, language, languages }) => {
  const localstorage = Object.assign({}, window.localStorage);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (localstorage.userSetAddress !== null) {
      dispatch(getPromoSlides());

      console.log(user);
      if (user.success && user?.type !== '0Auth') {
        dispatch(
          getUserNotifications(
            user?.data?.id || user?.id,
            user?.data?.auth_token || user?.auth_token
          )
        );
      }
    } else {
      //call to promoSlider API to fetch the slides
    }

    if (user.success) {
      if (firebase.messaging && firebase.messaging.isSupported()) {
        let handler = dispatch(saveNotificationToken());
        messaging
          .requestPermission()
          .then(async function () {
            const push_token = await messaging.getToken();
            handler(push_token, user?.data?.id, user?.data?.auth_token);
          })
          .catch(function (err) {
            console.log('Unable to get permission to notify.', err);
          });
        // navigator.serviceWorker.addEventListener("message", message =>
        //     console.log(message)
        // );
      }
    }
  }, [
    dispatch,
    localstorage.userSetAddress,
    user,
    user?.data?.auth_token,
    user?.data?.id,
    user.success,
  ]);

  return (
    <>
      <Meta
        seotitle={localstorage.seoMetaTitle}
        seodescription={localstorage.seoMetaDescription}
        ogtype="website"
        ogtitle={localstorage.seoOgTitle}
        ogdescription={localstorage.seoOgDescription}
        ogurl={window.location.href}
        twittertitle={localstorage.seoTwitterTitle}
        twitterdescription={localstorage.seoTwitterDescription}
      />
      <div className="height-100-percent bg-white mb-50">
        <Navigation
          logo={true}
          active_nearme={true}
          disable_back_button={true}
          history={history}
          loggedin={user.success}
          language={language}
          languages={languages}
        />
        {/* Passing slides as props to PromoSlider */}
        <>
          {promo_slides &&
            promo_slides.mainSlides &&
            promo_slides.mainSlides.length > 0 && (
              <PromoSlider
                slides={promo_slides.mainSlides}
                size={promo_slides.mainSlides[0]['promo_slider']['size']}
              />
            )}
        </>
        <div className="col-12 p-0">
          <div className="block m-0 ">
            <div className="block-content p-0">
              {localstorage?.firstScreenHeroImageSmVisibility !== 'false' && (
                <ProgressiveImage
                  delay={2000}
                  src={ROOT_URL + localstorage.firstScreenHeroImageSm}
                  placeholder={
                    localstorage.firstScreenHeroImageSm
                      ? `${ROOT_URL}/${localstorage.firstScreenHeroImageSm}`
                      : `/assets/img/sp_.jpg`
                  }
                >
                  {(src, loading) => (
                    <img
                      //src="/assets/img/sp_.jpg"
                      src={
                        localstorage.firstScreenHeroImageSm
                          ? `${ROOT_URL}/${localstorage.firstScreenHeroImageSm}`
                          : `/assets/img/sp_.jpg`
                      }
                      alt={localstorage.storeName}
                      className="img-fluid"
                      style={{
                        width: `100%`,
                        objectFit: 'contain',
                      }}
                    />
                  )}
                </ProgressiveImage>
              )}
            </div>
          </div>
        </div>
        <div className="block mt-5">
          <div
            className="block-content"
            style={{ paddingTop: '10%', paddingBottom: '10%' }}
          >
            <h1 style={{ fontSize: 32 }} className="welcome-heading mt-10">
              {language.firstScreenHeadingMobile}
            </h1>
            <h2
              style={{ fontSize: 18 }}
              className="welcome-subheading text-center mt-10 mb-10"
            >
              {language.firstScreenSubHeading}
            </h2>

            <div className="mt-20 px-15 pt-15 button-block">
              <button
                className="btn btn-main home-start-btn-main"
                style={{
                  backgroundColor: localstorage.storeColor,
                }}
                onClick={() => history.push('/search-location')}
              >
                {language.firstScreenSetupSearchLocation}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  promo_slides: state.promo_slides.promo_slides,
  user: state.user.user,
});

export default withRouter(
  connect(mapStateToProps, {
    getPromoSlides,
    saveNotificationToken,
    getUserNotifications,
  })(Home)
);
