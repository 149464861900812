import React, { useState } from 'react';
import { makeStyles } from '@mui/styles/';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import { useHistory } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TuneOutlined from '@mui/icons-material/TuneOutlined';
import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: useTheme().spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function FiltersDrawer(props) {
  const classes = useStyles();
  // const history = useHistory();

  const [login, setDrawer] = useState(false);
  // const [message, setMessage] = useState('');
  const [filters, setFilters] = useState([]);

  const toggleFilter = (v) => {
    if (filters.includes(v)) {
      setFilters(filters.filter((i) => i !== v));
    } else {
      setFilters([...filters, v]);
    }
  };

  return (
    <>
      <ListItem onClick={() => setDrawer(true)} button>
        <ListItemText primary="Filters" />
        <ListItemAvatar>
          <Avatar>
            <TuneOutlined />
          </Avatar>
        </ListItemAvatar>
      </ListItem>

      <Drawer
        style={{ width: '28%' }}
        anchor={'right'}
        open={login}
        onClose={() => setDrawer(false)}
      >
        <div style={{ padding: 1 }}>
          <IconButton
            onClick={() => setDrawer(false)}
            aria-label="close"
            className={classes.margin}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ padding: 25 }}>
          <div className="row bg-white">
            <div className="col-12">
              <div className="p-3">
                <FormControl className="w-100" component="fieldset">
                  {/*<Typography variant="h5" gutterBottom>Sort</Typography>
                            <RadioGroup style={{display: 'grid'}} aria-label="sort" name="sort" value={message} onChange={(e)=>setMessage(e.target.value)}>
                               <FormControlLabel className="m-0" value="sortRelevance" control={<Radio />} label="Relevance" />
                               <FormControlLabel className="m-0" value="sortCost" control={<Radio />} label="Cost for two" />
                               <FormControlLabel className="m-0" value="sortDelivery" control={<Radio />} label="Delivery Time" />
                               <FormControlLabel className="m-0" value="sortRating" control={<Radio />} label="Rating" />
                            </RadioGroup>*/}

                  <Typography variant="h5" gutterBottom className="mt-3">
                    Cusines
                  </Typography>
                  <div className="row">
                    <div className="col-6">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('African')}
                            onChange={() => toggleFilter('African')}
                            name="African"
                          />
                        }
                        label="African"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Beverages')}
                            onChange={() => toggleFilter('Beverages')}
                            name="Beverages"
                          />
                        }
                        label="Beverages"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Chinese')}
                            onChange={() => toggleFilter('Chinese')}
                            name="Chinese"
                          />
                        }
                        label="Chinese"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Dairy')}
                            onChange={() => toggleFilter('Dairy')}
                            name="Dairy"
                          />
                        }
                        label="Dairy"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Continental')}
                            onChange={() => toggleFilter('Continental')}
                            name="Continental"
                          />
                        }
                        label="Continental"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Fast Food')}
                            onChange={() => toggleFilter('Fast Food')}
                            name="Fast Food"
                          />
                        }
                        label="Fast Food"
                      />
                    </div>
                    <div className="col-6">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Jamaican')}
                            onChange={() => toggleFilter('Jamaican')}
                            name="Jamaican"
                          />
                        }
                        label="Jamaican"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Nigerian')}
                            onChange={() => toggleFilter('Nigerian')}
                            name="Nigerian"
                          />
                        }
                        label="Nigerian"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Thai')}
                            onChange={() => toggleFilter('Thai')}
                            name="Thai"
                          />
                        }
                        label="Thai"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Ghanaian')}
                            onChange={() => toggleFilter('Ghanaian')}
                            name="Ghanaian"
                          />
                        }
                        label="Ghanaian"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Lebanese')}
                            onChange={() => toggleFilter('Lebanese')}
                            name="Lebanese"
                          />
                        }
                        label="Lebanese"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Desserts & Sweets')}
                            onChange={() => toggleFilter('Desserts & Sweets')}
                            name="Desserts & Sweets"
                          />
                        }
                        label="Desserts & Sweets"
                      />
                    </div>
                  </div>

                  <Typography variant="h5" gutterBottom className="mt-3">
                    Restaurant Type
                  </Typography>
                  <div className="row">
                    <div className="col-6">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Fast Food1')}
                            onChange={() => toggleFilter('Fast Food1')}
                            name="Fast Food1"
                          />
                        }
                        label="Fast Food1"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Bars')}
                            onChange={() => toggleFilter('Bars')}
                            name="Bars"
                          />
                        }
                        label="Bars"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Bakery')}
                            onChange={() => toggleFilter('Bakery')}
                            name="Bakery"
                          />
                        }
                        label="Bakery"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Beach Shacks')}
                            onChange={() => toggleFilter('Beach Shacks')}
                            name="Beach Shacks"
                          />
                        }
                        label="Beach Shacks"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Cafes')}
                            onChange={() => toggleFilter('Cafes')}
                            name="Cafes"
                          />
                        }
                        label="Cafes"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Casual Dining')}
                            onChange={() => toggleFilter('Casual Dining')}
                            name="Casual Dining"
                          />
                        }
                        label="Casual Dining"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Food Court')}
                            onChange={() => toggleFilter('Food Court')}
                            name="Food Court"
                          />
                        }
                        label="Food Court"
                      />
                    </div>
                    <div className="col-6">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Food Truck')}
                            onChange={() => toggleFilter('Food Truck')}
                            name="Food Truck"
                          />
                        }
                        label="Food Truck"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Patisseries')}
                            onChange={() => toggleFilter('Patisseries')}
                            name="Patisseries"
                          />
                        }
                        label="Patisseries"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Lounges')}
                            onChange={() => toggleFilter('Lounges')}
                            name="Lounges"
                          />
                        }
                        label="Lounges"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Hotel Restaurants')}
                            onChange={() => toggleFilter('Hotel Restaurants')}
                            name="Hotel Restaurants"
                          />
                        }
                        label="Hotel Restaurants"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Night Clubs & Bars')}
                            onChange={() => toggleFilter('Night Clubs & Bars')}
                            name="Night Clubs & Bars"
                          />
                        }
                        label="Night Clubs & Bars"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Restaurant')}
                            onChange={() => toggleFilter('Restaurant')}
                            name="Restaurant"
                          />
                        }
                        label="Restaurant"
                      />
                    </div>
                  </div>

                  <Typography variant="h5" gutterBottom className="mt-3">
                    Filters
                  </Typography>
                  <div className="row">
                    <div className="col-6">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Only On Hotpot')}
                            onChange={() => toggleFilter('Only On Hotpot')}
                            name="Only On Hotpot"
                          />
                        }
                        label="Only On Hotpot"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Free Delievry')}
                            onChange={() => toggleFilter('Free Delievry')}
                            name="Free Delievry"
                          />
                        }
                        label="Free Delievry"
                      />
                    </div>
                    <div className="col-6">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.includes('Vegetarian')}
                            onChange={() => toggleFilter('Vegetarian')}
                            name="Vegetarian"
                          />
                        }
                        label="Vegetarian"
                      />
                    </div>
                  </div>
                </FormControl>

                <div
                  className="p-2"
                  style={{ width: '21%', position: 'fixed', bottom: 0 }}
                >
                  <Button
                    onClick={() => props.filterRestaurants(filters)}
                    style={{
                      width: '100%',
                      marginTop: '3rem',
                      backgroundColor: '#c119b0',
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </Drawer>
    </>
  );
}
