import React, { Component } from 'react';

// import Footer from "./Footer";
// import Hero from "./Hero";
import StartScreen from './Home/StartScreen';
import Meta from '../helpers/meta';
// import StoreAchievements from "./StoreAchievements";
import { connect } from 'react-redux';
import { getSettings } from '../../services/settings/actions';
import { getSingleLanguageData } from '../../services/languages/actions';
// import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

class Desktop extends Component {
  state = {
    showGdpr: false,
  };
  componentDidMount() {
    this.props.getSettings();

    if (!localStorage.getItem('gdprAccepted')) {
      localStorage.setItem('gdprAccepted', 'false');
      if (localStorage.getItem('showGdpr') === 'true') {
        this.setState({ showGdpr: true });
      }
    }

    if (
      localStorage.getItem('showGdpr') === 'true' &&
      localStorage.getItem('gdprAccepted') === 'false'
    ) {
      this.setState({ showGdpr: true });
    }
  }
  handleGdprClick = () => {
    localStorage.setItem('gdprAccepted', 'true');
    this.setState({ showGdpr: false });
  };

  handleOnChange = (event) => {
    // console.log(event.target.value);
    this.props.getSingleLanguageData(event.target.value);
    localStorage.setItem('userPreferedLanguage', event.target.value);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.languages !== nextProps.languages) {
      if (localStorage.getItem('userPreferedLanguage')) {
        this.props.getSingleLanguageData(
          localStorage.getItem('userPreferedLanguage')
        );
        // console.log("Called 1");
      } else {
        if (nextProps.languages && nextProps.languages.length) {
          const id = nextProps?.languages?.filter(
            (lang) => lang?.is_default === 1
          )[0]?.id;
          this.props.getSingleLanguageData(id);
        }
      }
    }
  }

  render() {
    const { settings, languages, language } = this.props;

    return (
      <React.Fragment>
        {this.state.showGdpr && (
          <div className="fixed-gdpr">
            <span
              dangerouslySetInnerHTML={{
                __html: localStorage.getItem('gdprMessage'),
              }}
            ></span>
            <span>
              <button
                className="btn btn-sm ml-2"
                style={{ backgroundColor: localStorage.getItem('storeColor') }}
                onClick={this.handleGdprClick}
              >
                {localStorage.getItem('gdprConfirmButton')}
              </button>
            </span>
          </div>
        )}
        <Meta
          storename={localStorage.getItem('storeName')}
          seotitle={localStorage.getItem('seoMetaTitle')}
          seodescription={localStorage.getItem('seoMetaDescription')}
          ogtype="website"
          ogtitle={localStorage.getItem('seoOgTitle')}
          ogdescription={localStorage.getItem('seoOgDescription')}
          ogurl={window.location.href}
          twittertitle={localStorage.getItem('seoTwitterTitle')}
          twitterdescription={localStorage.getItem('seoTwitterDescription')}
        />

        <StartScreen
          settings={settings}
          languages={languages}
          language={language}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings.settings,
  languages: state.languages.languages,
  language: state.languages.language,
  locations: state.locations.locations,
});

export default connect(mapStateToProps, {
  getSettings,
  getSingleLanguageData,
})(Desktop);

// return (
//     <React.Fragment>
//         {this.state.showGdpr && (
//             <div className="fixed-gdpr">
//                 <span
//                     dangerouslySetInnerHTML={{
//                         __html: localStorage.getItem("gdprMessage")
//                     }}
//                 ></span>
//                 <span>
//                     <button
//                         className="btn btn-sm ml-2"
//                         style={{ backgroundColor: localStorage.getItem("storeColor") }}
//                         onClick={this.handleGdprClick}
//                     >
//                         {localStorage.getItem("gdprConfirmButton")}
//                     </button>
//                 </span>
//             </div>
//         )}
//         <Meta
//             seotitle={localStorage.getItem("seoMetaTitle")}
//             seodescription={localStorage.getItem("seoMetaDescription")}
//             ogtype="website"
//             ogtitle={localStorage.getItem("seoOgTitle")}
//             ogdescription={localStorage.getItem("seoOgDescription")}
//             ogurl={window.location.href}
//             twittertitle={localStorage.getItem("seoTwitterTitle")}
//             twitterdescription={localStorage.getItem("seoTwitterDescription")}
//         />
//         <Hero />

//         <CarouselProvider
//             naturalSlideWidth={25}
//             naturalSlideHeight={25}
//             totalSlides={5}
//             visibleSlides={4}
//             style={{backgroundColor: '#000'}}
//           >
//             <Slider>
//               <Slide className={'slider-div'} index={0}><img style={{width: 240, marginTop: 54}} src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_520,h_520/rng/md/carousel/production/cjxhfbchbicl9yuaevhj" alt="HotPot Africa" /></Slide>
//               <Slide className={'slider-div'} index={1}><img style={{width: 240, marginTop: 54}} src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_520,h_520/rng/md/carousel/production/kqbubuqfz6bk0h4nztcz" alt="HotPot Africa" /></Slide>
//               <Slide className={'slider-div'} index={2}><img style={{width: 240, marginTop: 54}} src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_520,h_520/rng/md/carousel/production/ztpd5q9awnmmnefczn5x" alt="HotPot Africa" /></Slide>
//               <Slide className={'slider-div'} index={3}><img style={{width: 240, marginTop: 54}} src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_520,h_520/rng/md/carousel/production/awurei8ypqkafoqay9ym" alt="HotPot Africa" /></Slide>
//               <Slide className={'slider-div'} index={4}><img style={{width: 240, marginTop: 54}} src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_520,h_520/rng/md/carousel/production/ft6ftbffbbyp7q0p8ip9" alt="HotPot Africa" /></Slide>
//             </Slider>
//         </CarouselProvider>

//         <div className="container-fluid p-0">
//             <div className="row">
//                 <div className="col-md-12">
//                     <div style={{padding: 50, textAlign: 'center'}}>
//                         <img alt="slider" style={{width: 345}} src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/connection_error_bsppck" />
//                         <h4 className={'mb-2'}>Oh! All restaurants are <br /> currently unserviceable</h4>
//                         <p className={'text-muted'}>All restaurants are unserviceable, check back in some time.</p>
//                     </div>
//                 </div>
//             </div>
//         </div>

//         <Footer languages={this.props.languages} handleOnChange={this.handleOnChange} />
//     </React.Fragment>
// );
