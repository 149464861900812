import { Link } from 'react-router-dom';

import { ROOT_URL } from '../../../configs/index';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIconOutlined from '@mui/icons-material/HomeOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartIconOutlined from '@mui/icons-material/ShoppingCartOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
// import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import HandleLanguages from '../../Shared/Language/HandleLanguages';

const getUrl = (menuItem, language, languageWord) => {
  switch (menuItem) {
    case language?.sideDrawerMenuItemHome:
      return '/';
    case language?.sideDrawerMenuItemRestaurants:
      return '/restaurants';
    case language?.sideDrawerMenuItemFavorites:
      return '/favorites';
    case language?.sideDrawerMenuItemCart:
      return '/cart';
    case language?.sideDrawerMenuItemOrders:
      return '/my-orders';
    case language?.sideDrawerMenuItemOffers:
      return '/offers';
    case language?.sideDrawerMenuItemAccount:
      return 'my-account';
    case language?.sideDrawerMenuItemAlerts:
      return '/alerts';
    case languageWord:
      return '/languages';
    default:
      return;
  }
};

const getIcons = (menuItem, language, languages, languageWord) => {
  switch (menuItem) {
    case language?.sideDrawerMenuItemHome:
      return <HomeIconOutlined fontSize="medium" />;
    case language?.sideDrawerMenuItemRestaurants:
      return <RestaurantOutlinedIcon fontSize="medium" />;
    case language?.sideDrawerMenuItemFavorites:
      return <FavoriteBorderIcon fontSize="medium" />;
    case language?.sideDrawerMenuItemCart:
      return <ShoppingCartIconOutlined fontSize="medium" />;
    case language?.sideDrawerMenuItemOrders:
      return <ShoppingBagOutlinedIcon fontSize="medium" />;
    case language?.sideDrawerMenuItemOffers:
      return <LocalOfferOutlinedIcon fontSize="medium" />;
    case language?.sideDrawerMenuItemAccount:
      return <AccountCircleOutlinedIcon fontSize="medium" />;
    case language?.sideDrawerMenuItemAlerts:
      return <NotificationsOutlinedIcon fontSize="medium" />;
    case languageWord:
      return <HandleLanguages languages={languages} device={'mobile'} />;
    default:
      return;
  }
};

const list = (anchor, props) => {
  const { language, languages, languageWord } = props;
  return (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      style={{ paddingLeft: '5px' }}
    >
      <List>
        {[
          language?.sideDrawerMenuItemHome,
          language?.sideDrawerMenuItemRestaurants,
          language?.sideDrawerMenuItemFavorites,
          language?.sideDrawerMenuItemCart,
          language?.sideDrawerMenuItemOrders,
          language?.sideDrawerMenuItemOffers,
        ].map((text) => (
          <ListItem key={`${text}-${Math.random()}`} disablePadding>
            <ListItemButton
              component={Link}
              to={getUrl(text, language)}
              onClick={() => props.close(false)}
            >
              <ListItemIcon>
                {getIcons(text, language, languages, languageWord)}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[
          language?.sideDrawerMenuItemAccount,
          language?.sideDrawerMenuItemAlerts,
          languageWord,
        ].map((text) => (
          <ListItem key={`${text}-${Math.random()}`} disablePadding>
            <ListItemButton
              component={Link}
              to={getUrl(text, language, languageWord)}
              onClick={() => props.close(false)}
            >
              <ListItemIcon>
                {getIcons(text, language, languages, languageWord)}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const SideDrawer = (props) => {
  const localstorage = Object.assign({}, window.localStorage);
  const { location } = useHistory();
  return (
    <Drawer
      className="menu-side-drawer-wrapper"
      anchor={'right'}
      open={props.state}
      onClose={() => props.close(false)}
    >
      <div
        className="menu-side-drawer"
        style={{ top: location?.pathname === '/' ? '55px' : '' }}
      >
        <div className="menu-side-drawer-topline-wrapper">
          <img
            src={
              localstorage.storeLogo
                ? `${ROOT_URL}/${localstorage.storeLogoSm}`
                : `/assets/img/logo_b.png`
            }
            alt="HotPot Africa"
            className="menu-side-drawer-store-logo"
          />
          <div className="menu-side-drawer-store-name">
            {localstorage?.storeName}
          </div>
          <IconButton
            onClick={() => props.close(false)}
            aria-label="close"
            className={'menu-side-drawer-close-btn'}
            style={{ marginLeft: 'auto', color: 'currentColor' }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {list('right', props)}
      </div>
    </Drawer>
  );
};

export default SideDrawer;
