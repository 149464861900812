import { APPLY_COUPON, ALL_COUPONS, REMOVE_COUPONS } from './actionTypes';
import { APPLY_COUPON_URL, ALL_COUPONS_URL } from '../../configs';
import Axios from 'axios';

export const applyCoupon =
  (coupon, restaurant_id, user_id, cart_total) => (dispatch) => {
    Axios.post(APPLY_COUPON_URL, {
      coupon: coupon,
      restaurant_id: restaurant_id,
      user_id,
      min_order_amount: cart_total,
    })
      .then((response) => {
        console.log(response.data);
        const coupon = response.data;
        return dispatch({ type: APPLY_COUPON, payload: coupon });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

export const allCoupons = () => (dispatch) => {
  Axios.post(ALL_COUPONS_URL)
    .then((response) => {
      console.log(response.data);
      const coupons = response.data;
      return dispatch({ type: ALL_COUPONS, payload: coupons });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const removeCoupon = () => (dispatch) => {
  return dispatch({ type: REMOVE_COUPONS });
};
