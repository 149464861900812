import {
  GET_DELIVERY_RESTAURANTS_URL,
  GET_SELFPICKUP_RESTAURANTS_URL,
  GET_SINGLE_ORDER_TO_BE_RATED,
  GET_RESTAURANTS_SLIDES_URL,
  ROOT_URL,
} from '../../../configs/index';
import React, { Component } from 'react';

import ContentLoader from 'react-content-loader';
import DelayLink from '../../helpers/delayLink';
import Ink from 'react-ink';
import LazyLoad from 'react-lazyload';
import BackWithSearch from '../../Mobile/Elements/BackWithSearch';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
// import PromoSlider from "../PromoSlider";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import TuneOutlined from '@mui/icons-material/TuneOutlined';
import Grow from '@mui/material/Grow';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';

class PromoRestaurants extends Component {
  state = {
    total: null,
    restaurants: [],
    loading: false,
    loading_more: true,
    selfpickup: false,
    userPreferredSelectionDelivery: true,
    userPreferredSelectionSelfPickup: false,
    no_restaurants: false,
    data: [],
    review_data: [],
    filterText: null,
    filter_items: [],
    filterModal: false,
    filterType: 'sort',
    sort: null,
    cusine: null,
    filter: null,
    slug: '',
  };

  arrayholder = [];

  componentDidMount() {
    this.getAllRestaurantSliders();
    if (localStorage.getItem('enRAR') === 'true' && 'enRAR' === 'disRAR') {
      this.getRatableOrdersDetails();
    }

    if (localStorage.getItem('userPreferredSelection') === 'DELIVERY') {
      this.setState({ userPreferredSelectionDelivery: true });
      this.filterDelivery();
    }
    if (
      localStorage.getItem('userPreferredSelection') === 'SELFPICKUP' &&
      localStorage.getItem('enSPU') === 'true'
    ) {
      this.setState({ userPreferredSelectionSelfPickup: true });
      this.filterSelfPickup();
    } else {
      localStorage.setItem('userPreferredSelection', 'DELIVERY');
      localStorage.setItem('userPreferredSelection', 'DELIVERY');
      this.setState({ userPreferredSelectionDelivery: true });
      this.getDeliveryRestaurants();
    }

    this.setState({ slug: this.props.match.params.slug });
  }

  searchFilterFunction = (text) => {
    const newData = this.arrayholder.filter((item) => {
      const itemData = `${item.name.toUpperCase()}`;

      const textData = text.toUpperCase();

      return itemData.indexOf(textData) > -1;
    });

    this.setState({ restaurants: newData });
  };

  getDeliveryRestaurants = () => {
    if (localStorage.getItem('userSetAddress')) {
      if (!this.state.loading) {
        // Set loading state to true to
        // avoid multiple requests on scroll
        this.setState({
          loading: true,
        });

        const userSetAddress = JSON.parse(
          localStorage.getItem('userSetAddress')
        );
        // make XHR request
        axios
          .post(GET_DELIVERY_RESTAURANTS_URL, {
            latitude: userSetAddress.lat,
            longitude: userSetAddress.lng,
          })
          .then((response) => {
            console.log(this.props.match.params.slug);
            const restaurants = response.data.filter((i) =>
              this.props.match.params.slug.split(',').includes(String(i.id))
            );
            if (restaurants.length) {
              // add new
              this.setState({
                total: restaurants.length,
                restaurants: [...this.state.restaurants, ...restaurants],
                loading: false,
                loading_more: false,
                no_restaurants: false,
              });

              this.arrayholder = restaurants;
            } else {
              this.setState({
                total: null,
                loading: false,
                loading_more: false,
                no_restaurants: true,
              });
            }
          });
      }
    }
  };

  getSelfPickupRestaurants = () => {
    if (localStorage.getItem('userSetAddress')) {
      if (!this.state.loading) {
        // Set loading state to true to
        // avoid multiple requests on scroll
        this.setState({
          loading: true,
        });

        const userSetAddress = JSON.parse(
          localStorage.getItem('userSetAddress')
        );

        // make XHR request
        axios
          .post(GET_SELFPICKUP_RESTAURANTS_URL, {
            latitude: userSetAddress.lat,
            longitude: userSetAddress.lng,
          })
          .then((response) => {
            const restaurants = response.data;
            if (restaurants.length) {
              // console.log("RES from API", restaurants);
              // add new
              this.setState({
                total: restaurants.length,
                restaurants: [...this.state.restaurants, ...restaurants],
                loading: false,
                loading_more: false,
                no_restaurants: false,
              });

              this.arrayholder = restaurants;
            } else {
              this.setState({
                total: null,
                loading: false,
                loading_more: false,
                no_restaurants: true,
              });
            }
          });
      }
    }
  };

  filterDelivery = () => {
    this.setState(
      () => ({
        selfpickup: false,
        restaurants: [],
        userPreferredSelectionDelivery: true,
        userPreferredSelectionSelfPickup: false,
      }),
      () => {
        this.getDeliveryRestaurants();
      }
    );
    localStorage.setItem('userPreferredSelection', 'DELIVERY');
    if (localStorage.getItem('showPromoSlider') === 'true') {
      // window.scrollTo({ top: 205, behavior: "smooth" });
    } else {
      // window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  filterSelfPickup = () => {
    this.setState(
      () => ({
        selfpickup: true,
        restaurants: [],
        userPreferredSelectionSelfPickup: true,
        userPreferredSelectionDelivery: false,
      }),
      () => {
        this.getSelfPickupRestaurants();
      }
    );
    localStorage.setItem('userPreferredSelection', 'SELFPICKUP');
    if (localStorage.getItem('showPromoSlider') === 'true') {
      // window.scrollTo({ top: 205, behavior: "smooth" });
    } else {
      // window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  getAllRestaurantSliders = () => {
    axios.post(GET_RESTAURANTS_SLIDES_URL).then((response) => {
      if (response.data) {
        this.setState({
          data: response.data,
        });
      }
    });
  };

  changeRouteToRestaurantsCategories = (categories) => {
    if (categories.categories_ids) {
      const saveCategorySelectOptions = new Promise((resolve) => {
        localStorage.setItem(
          'categorySelectOptions',
          JSON.stringify(categories.categories_ids)
        );
        resolve('categorySelectOptions Saved');
      });
      saveCategorySelectOptions.then(() => {
        this.props.history.push('categories/restaurants');
      });
    }
  };

  getRatableOrdersDetails = () => {
    const { user } = this.props;
    if (user.success) {
      axios
        .post(GET_SINGLE_ORDER_TO_BE_RATED, {
          user_id: user.data.id,
          token: user.data.auth_token,
        })
        .then((response) => {
          this.setState({
            review_data: response.data,
          });
        });
    }
  };

  animateStarIcon = () => {
    let stars = [];

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <Zoom top delay={i * 100} key={i}>
          <i
            className="fa fa-star pr-1"
            style={{
              color: localStorage.getItem('rarModHomeBannerStarsColor'),
            }}
          ></i>
        </Zoom>
      );
    }
    return stars;
  };

  sortRestaurants = (val) => {
    if (val === 'clear') {
      this.setState({ filterModal: false, restaurants: this.arrayholder });
    }

    if (this.state.filterType === 'cusine') {
      let newData = this.arrayholder.filter(
        (i) => i.cusines !== undefined && i.cusines !== null
      );
      console.log('newData');
      console.log(newData);
      if (newData) {
        let newData2 = newData.filter((i) =>
          JSON.parse(i.cusines).includes(this.state.cusine)
        );
        this.setState({ filterModal: false, restaurants: newData2 });
      } else {
        this.setState({ filterModal: false, restaurants: newData });
      }
      return;
    }

    if (this.state.filterType === 'filter') {
      let newData = this.arrayholder.filter((i) => i[this.state.filter] === 1);
      this.setState({ filterModal: false, restaurants: newData });
      return;
    }

    if (
      this.state.sort === 'sortRelevance' ||
      this.state.filterType === 'cusine'
    ) {
      this.setState({ filterModal: false, restaurants: this.arrayholder });
    }
    if (this.state.sort === 'sortCost') {
      let newData = this.arrayholder.sort((a, b) =>
        parseInt(a.price_range) > parseInt(b.price_range) ? 1 : -1
      );
      this.setState({ filterModal: false, restaurants: newData });
    }
    if (this.state.sort === 'sortDelivery') {
      let newData = this.arrayholder.sort((a, b) =>
        parseInt(a.delivery_time) > parseInt(b.delivery_time) ? 1 : -1
      );
      this.setState({ filterModal: false, restaurants: newData });
    }
    if (this.state.sort === 'sortRating') {
      let newData = this.arrayholder.sort((a, b) =>
        parseInt(a.rating) > parseInt(b.rating) ? 1 : -1
      );
      this.setState({ filterModal: false, restaurants: newData });
    }
  };

  render() {
    return (
      <React.Fragment>
        <BackWithSearch
          boxshadow={true}
          has_title={true}
          title="Restaurants"
          disable_search={true}
          back_to_home={false}
          goto_orders_page={true}
        />
        <div className="bg-white mb-100 pt-50">
          {this.state.restaurants.length === 0 ? (
            <>
              <h1 className="restaurant-count pt-3 mb-0 mr-2 px-15">
                {localStorage.getItem('noRestaurantMessage')}
              </h1>
              <ContentLoader
                height={378}
                width={400}
                speed={1.2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
              >
                <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

                <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

                <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
              </ContentLoader>
            </>
          ) : (
            this.state.restaurants.map((restaurant, index) => (
              <React.Fragment key={restaurant.id}>
                <LazyLoad>
                  <div
                    className={
                      restaurant.is_open === 0
                        ? 'col-xs-12 col-sm-12 restaurant-block  page-inactive-clickable'
                        : 'col-xs-12 col-sm-12 restaurant-block '
                    }
                  >
                    <DelayLink
                      to={'../restaurants/' + restaurant.slug}
                      delay={200}
                      className="block text-center mb-3"
                      clickAction={() => {
                        localStorage.getItem('userPreferredSelection') ===
                          'DELIVERY' &&
                          restaurant.delivery_type === 1 &&
                          localStorage.setItem(
                            'userPreferredSelection',
                            'DELIVERY'
                          );
                        localStorage.getItem('userPreferredSelection') ===
                          'SELFPICKUP' &&
                          restaurant.delivery_type === 2 &&
                          localStorage.setItem(
                            'userPreferredSelection',
                            'SELFPICKUP'
                          );
                        localStorage.getItem('userPreferredSelection') ===
                          'DELIVERY' &&
                          restaurant.delivery_type === 3 &&
                          localStorage.setItem(
                            'userPreferredSelection',
                            'DELIVERY'
                          );
                        localStorage.getItem('userPreferredSelection') ===
                          'SELFPICKUP' &&
                          restaurant.delivery_type === 3 &&
                          localStorage.setItem(
                            'userPreferredSelection',
                            'SELFPICKUP'
                          );
                      }}
                    >
                      <div
                        className={`block-content block-content-full ${
                          restaurant.is_featured
                            ? 'ribbon ribbon-bookmark ribbon-warning pt-2'
                            : 'pt-2'
                        } `}
                      >
                        {restaurant.is_featured ? (
                          <div className="ribbon-box">
                            {localStorage.getItem('restaurantFeaturedText')}
                          </div>
                        ) : null}

                        <Fade duration={500}>
                          <img
                            style={{ objectFit: 'scale-down' }}
                            src={ROOT_URL + '/public/' + restaurant.image}
                            alt={restaurant.name}
                            className="restaurant-image"
                          />
                        </Fade>
                      </div>
                      <div className="block-content block-content-full restaurant-info">
                        <div className="font-w600 text-dark">
                          {restaurant.name}
                        </div>
                        <div className="font-size-sm text-muted truncate-text text-muted">
                          {restaurant.description}
                        </div>
                        <hr className="my-10" />
                        <div className="text-center restaurant-meta mt-5 d-flex align-items-center justify-content-between text-muted">
                          {restaurant.is_open === 1 && (
                            <>
                              <div className="col-2 p-0 text-left">
                                <i
                                  className="fa fa-star pr-1"
                                  style={{
                                    color: localStorage.getItem('storeColor'),
                                  }}
                                />{' '}
                                {restaurant.rating}
                              </div>
                              <div className="col-4 p-0 text-center">
                                <i className="si si-clock pr-1" />{' '}
                                {restaurant.delivery_time}{' '}
                                {localStorage.getItem('homePageMinsText')}
                              </div>
                            </>
                          )}

                          <div className="col-6 p-0 text-center">
                            <i className="si si-wallet pr-1" />{' '}
                            {localStorage.getItem('currencySymbolAlign') ===
                              'left' && localStorage.getItem('currencyFormat')}
                            {restaurant.price_range}{' '}
                            {localStorage.getItem('currencySymbolAlign') ===
                              'right' && localStorage.getItem('currencyFormat')}
                            {localStorage.getItem('homePageForTwoText')}
                          </div>

                          {restaurant.is_open === 0 && (
                            <div className="col-6 p-0 text-center">
                              <i className="si si-clock pr-1" /> Opens at{' '}
                              {restaurant.opening_time}
                            </div>
                          )}
                        </div>
                      </div>
                      <Ink duration="500" hasTouch={false} />
                    </DelayLink>
                  </div>
                </LazyLoad>

                {this.state.review_data.ratable &&
                  localStorage.getItem('enRAR') === 'true' &&
                  localStorage.getItem('rarModEnHomeBanner') === 'true' &&
                  'enRAR' === 'disRAR' && (
                    <React.Fragment>
                      {console.log('Came here')}
                      {!this.state.loading &&
                        index ===
                          parseInt(
                            localStorage.getItem('rarModHomeBannerPosition')
                          ) -
                            1 && (
                          <div
                            className="col-xs-12 col-sm-12 rating-block px-15 py-10"
                            style={{
                              backgroundColor: localStorage.getItem(
                                'rarModHomeBannerBgColor'
                              ),
                            }}
                          >
                            <DelayLink
                              to={{
                                pathname:
                                  '../rate-and-review/' +
                                  this.state.review_data.data.id,
                              }}
                              delay={250}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <strong
                                  style={{
                                    color: localStorage.getItem(
                                      'rarModHomeBannerTextColor'
                                    ),
                                  }}
                                >
                                  {localStorage.getItem('rarModHomeBannerText')}
                                  {localStorage.getItem(
                                    'rarModShowBannerRestaurantName'
                                  ) === 'true' &&
                                    this.state.review_data.data.restaurant.name}
                                </strong>
                                <span
                                  className="btn btn-default"
                                  style={{
                                    minWidth: '100px',
                                    textAlign: 'center',
                                  }}
                                >
                                  {this.animateStarIcon()}
                                </span>
                              </div>
                              <Ink duration="500" hasTouch={false} />
                            </DelayLink>
                          </div>
                        )}
                    </React.Fragment>
                  )}
              </React.Fragment>
            ))
          )}

          {this.state.loading_more ? (
            <div className="">
              <ContentLoader
                height={120}
                width={400}
                speed={1.2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
              >
                <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                <rect x="144" y="35" rx="0" ry="0" width="115" height="18" />
                <rect x="144" y="65" rx="0" ry="0" width="165" height="16" />
              </ContentLoader>
            </div>
          ) : null}
        </div>

        <Modal
          open={this.state.filterModal}
          style={{ top: 'unset' }}
          onClose={() => this.setState({ filterModal: false })}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Grow in={this.state.filterModal}>
            <div className="row bg-white">
              <div className="col-sm-12">
                <div
                  className="p-2"
                  style={{ borderBottom: '1px solid #c3c3c3' }}
                >
                  <IconButton
                    onClick={() => this.setState({ filterModal: false })}
                    aria-label="Close"
                  >
                    <CloseOutlined />
                  </IconButton>

                  <Typography
                    style={{ display: 'inline', marginLeft: 4 }}
                    variant="body1"
                  >
                    Sort/Filter
                  </Typography>
                  <Button
                    onClick={() => this.sortRestaurants('clear')}
                    style={{ position: 'absolute', right: 10, top: 13 }}
                  >
                    Clear
                  </Button>
                </div>
              </div>

              <div
                className="col-4 col-sm-4 p-0"
                style={{
                  borderRight: '1px solid #c3c3c3',
                  backgroundColor: 'aliceblue',
                }}
              >
                <div
                  onClick={() => this.setState({ filterType: 'sort' })}
                  className={
                    this.state.filterType === 'sort'
                      ? 'bg-white ml-3 p-3'
                      : 'ml-3 p-3'
                  }
                  style={{ borderBottom: '1px solid #c3c3c3' }}
                >
                  <Typography variant="body1">Sort</Typography>
                </div>

                <div
                  onClick={() => this.setState({ filterType: 'cusine' })}
                  className={
                    this.state.filterType === 'cusine'
                      ? 'bg-white ml-3 p-3'
                      : 'ml-3 p-3'
                  }
                  style={{ borderBottom: '1px solid #c3c3c3' }}
                >
                  <Typography variant="body1">Cusines</Typography>
                </div>

                <div
                  onClick={() =>
                    this.setState({ filterType: 'restaurantType' })
                  }
                  className={
                    this.state.filterType === 'restaurantType'
                      ? 'bg-white ml-3 p-3'
                      : 'ml-3 p-3'
                  }
                  style={{ borderBottom: '1px solid #c3c3c3' }}
                >
                  <Typography variant="body1">Restaurant Type</Typography>
                </div>

                <div
                  onClick={() => this.setState({ filterType: 'filter' })}
                  className={
                    this.state.filterType === 'filter'
                      ? 'bg-white ml-3 p-3'
                      : 'ml-3 p-3'
                  }
                  style={{ borderBottom: '1px solid #c3c3c3' }}
                >
                  <Typography variant="body1">Filters</Typography>
                </div>
              </div>
              <div className="col-8 col-sm-8">
                <div className="p-3">
                  <FormControl className="w-100" component="fieldset">
                    <RadioGroup
                      style={{
                        maxHeight: 310,
                        overflow: 'scroll',
                        display: 'grid',
                      }}
                      className={
                        this.state.filterType === 'sort' ? '' : 'd-none'
                      }
                      aria-label="sort"
                      name="sort"
                      value={this.state.sort}
                      onChange={(e) => this.setState({ sort: e.target.value })}
                    >
                      <FormControlLabel
                        className="m-0"
                        value="sortRelevance"
                        control={<Radio />}
                        label="Relevance"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="sortCost"
                        control={<Radio />}
                        label="Cost for two"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="sortDelivery"
                        control={<Radio />}
                        label="Delivery Time"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="sortRating"
                        control={<Radio />}
                        label="Rating"
                      />
                    </RadioGroup>

                    <RadioGroup
                      style={{
                        maxHeight: 310,
                        overflow: 'scroll',
                        display: 'grid',
                      }}
                      className={
                        this.state.filterType === 'cusine' ? '' : 'd-none'
                      }
                      aria-label="cusine"
                      name="cusine"
                      value={this.state.cusine}
                      onChange={(e) =>
                        this.setState({ cusine: e.target.value })
                      }
                    >
                      <FormControlLabel
                        className="m-0"
                        value="African"
                        control={<Radio />}
                        label="African"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Beverages"
                        control={<Radio />}
                        label="Beverages"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Chinese"
                        control={<Radio />}
                        label="Chinese"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Dairy"
                        control={<Radio />}
                        label="Dairy"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Continental"
                        control={<Radio />}
                        label="Continental"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Fast Food"
                        control={<Radio />}
                        label="Fast Food"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Jamaican"
                        control={<Radio />}
                        label="Jamaican"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Nigerian "
                        control={<Radio />}
                        label="Nigerian "
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Thai"
                        control={<Radio />}
                        label="Thai"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Ghanaian "
                        control={<Radio />}
                        label="Ghanaian "
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Lebanese"
                        control={<Radio />}
                        label="Lebanese"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Desserts & Sweets"
                        control={<Radio />}
                        label="Desserts & Sweets"
                      />
                    </RadioGroup>

                    <RadioGroup
                      style={{
                        maxHeight: 310,
                        overflow: 'scroll',
                        display: 'grid',
                      }}
                      className={
                        this.state.filterType === 'filter' ? '' : 'd-none'
                      }
                      aria-label="filter"
                      name="filter"
                      value={this.state.filter}
                      onChange={(e) =>
                        this.setState({ filter: e.target.value })
                      }
                    >
                      <FormControlLabel
                        className="m-0"
                        value="is_hotpot"
                        control={<Radio />}
                        label="Only On Hotpot"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="is_free_delivery"
                        control={<Radio />}
                        label="Free Delievry"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="is_pureveg"
                        control={<Radio />}
                        label="Vegetarian"
                      />
                    </RadioGroup>

                    <RadioGroup
                      style={{
                        maxHeight: 310,
                        overflow: 'scroll',
                        display: 'grid',
                      }}
                      className={
                        this.state.filterType === 'restaurantType'
                          ? ''
                          : 'd-none'
                      }
                      aria-label="restaurantType"
                      name="restaurantType"
                      value={this.state.filter}
                      onChange={(e) =>
                        this.setState({ filter: e.target.value })
                      }
                    >
                      <FormControlLabel
                        className="m-0"
                        value="Fast Food"
                        control={<Radio />}
                        label="Fast Food"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Bars"
                        control={<Radio />}
                        label="Bars"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Bakery"
                        control={<Radio />}
                        label="Bakery"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Beach Shacks"
                        control={<Radio />}
                        label="Beach Shacks"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Cafes"
                        control={<Radio />}
                        label="Cafes"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Casual Dining"
                        control={<Radio />}
                        label="Casual Dining"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Food Court"
                        control={<Radio />}
                        label="Food Court"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Food Truck"
                        control={<Radio />}
                        label="Food Truck"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Patisseries"
                        control={<Radio />}
                        label="Patisseries"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Lounges"
                        control={<Radio />}
                        label="Lounges"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Hotel Restaurants"
                        control={<Radio />}
                        label="Hotel Restaurants"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Night Clubs & Bars"
                        control={<Radio />}
                        label="Night Clubs & Bars"
                      />
                      <FormControlLabel
                        className="m-0"
                        value="Restaurant"
                        control={<Radio />}
                        label="Restaurant"
                      />
                    </RadioGroup>

                    <Button
                      onClick={() => this.sortRestaurants('sort')}
                      style={{
                        width: '100%',
                        marginTop: '3rem',
                        backgroundColor: '#c119b0',
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      Apply
                    </Button>
                  </FormControl>
                </div>
              </div>
            </div>
          </Grow>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(PromoRestaurants);
