export const GetAddressFromCoordinates = async (lat, lng, apiKey) =>
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`,
    {
      method: 'GET',
    }
  )
    .then((response) => response.json())
    .then((j) => {
      if (j.error_message) {
        console.log(j.error_message);
        return {
          error: true,
          state: true,
          res: j.error_message,
        };
      }
      return {
        data: `${j.results[0].formatted_address}`,
      };
    })
    .catch((error) => error);
