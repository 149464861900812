import React, { useState, useEffect, useRef } from 'react';

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import { useHistory } from "react-router-dom";
// import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Axios from 'axios';
import {
  GET_DELIVERY_RESTAURANTS_URL,
  GET_SELFPICKUP_RESTAURANTS_URL,
  ROOT_URL,
  GET_RESTAURANTS_SLIDES_URL,
  GET_PROMO_SLIDER_URL,
} from '../../../configs';
import Skeleton from '@mui/lab/Skeleton';
import Header from '../Layouts/Header';
import { useHistory } from 'react-router-dom';
import Ink from 'react-ink';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { withStyles } from '@mui/styles/';
import FiltersDrawer from './Filters/FiltersDrawer';
import EmptyCart from '../../../components/Loading/EmptyCart';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { intlFormatNumber } from '../../helpers/formatPrice';
import './styles.scss';

export default function Restaurants() {
  const localstorage = Object.assign({}, window.localStorage);
  const history = useHistory();
  const seeAll = useRef(null);

  const [loading, setLoading] = useState(true);
  // const [filter, setFilter] = useState('TopPicks');
  const [restaurants, setRestaurants] = useState([]);
  const [arrayHolder, setArrayHolder] = useState([]);
  const [banners, setBanners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [deliveryType, setDeliveryType] = useState('Delivery');
  const [value, setValue] = useState(0);
  const [filters, setFilters] = useState([]);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
    if (newValue === 0) {
      setRestaurants(arrayHolder.sort((i, j) => i.is_featured - j.is_featured));
    }
    if (newValue === 1) {
      setRestaurants(arrayHolder.sort((i, j) => i.price_range - j.price_range));
    }
    if (newValue === 2) {
      setRestaurants(
        arrayHolder.sort((i, j) => i.delivery_time - j.delivery_time)
      );
    }
    if (newValue === 3) {
      setRestaurants(arrayHolder.sort((i, j) => j.rating - i.rating));
    }
  };

  useEffect(() => {
    Axios.post(GET_PROMO_SLIDER_URL)
      .then((res) => {
        console.log(res.data);
        setBanners(res.data.mainSlides);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    console.log(localStorage.getItem('userLat'));
    console.log(localStorage.getItem('userLng'));
    Axios.post(GET_DELIVERY_RESTAURANTS_URL, {
      latitude: localStorage.getItem('userLat'),
      longitude: localStorage.getItem('userLng'),
    })
      .then((res) => {
        if (!res.data) {
          return;
        }
        console.log('DELIVERY1', res.data);
        res.data = res.data.map((i) => {
          console.log(i);
          console.log('DELIVERY2', i.schedule_data);
          return {
            ...i,
            schedule_data: JSON.parse(i.schedule_data),
          };
        });
        setRestaurants(res.data.filter((i) => i && i.is_active === 1));
        setArrayHolder(res.data.filter((i) => i && i.is_active === 1));
        setLoading(false);
      })
      .catch((e) => console.log(e));

    Axios.post(GET_RESTAURANTS_SLIDES_URL)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    let url =
      deliveryType === 'Delivery'
        ? GET_DELIVERY_RESTAURANTS_URL
        : GET_SELFPICKUP_RESTAURANTS_URL;

    Axios.post(url, {
      latitude: localStorage.getItem('userLat'),
      longitude: localStorage.getItem('userLng'),
    })
      .then((res) => {
        if (!res.data) {
          return;
        }
        console.log(res.data);
        res.data = res.data.map((i) => {
          return { ...i, schedule_data: JSON.parse(i.schedule_data) };
        });
        setRestaurants(res.data.filter((i) => i.is_active === 1));
        setArrayHolder(res.data.filter((i) => i.is_active === 1));
        setLoading(false);
      })
      .catch((e) => console.log(e));

    Axios.post(GET_RESTAURANTS_SLIDES_URL)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((e) => console.log(e));
  }, [deliveryType]);

  // useEffect(()=>{
  //   window.addEventListener('scroll', handleScroll, true);
  //   return function(){
  //     window.removeEventListener('scroll', handleScroll, true);
  //   }
  // },[])

  // const handleScroll = () => {
  //   if(restaurants.length===0)return;
  //   // lastScrollY = window.scrollY;

  //   if(topPicks.current.getBoundingClientRect().top < 80 && topPicks.current.getBoundingClientRect().top > 0){
  //     setFilter('TopPicks')
  //   }
  //   if(onlyVeg.current.getBoundingClientRect().top < 80 && onlyVeg.current.getBoundingClientRect().top > 0){
  //     setFilter('OnlyVeg')
  //   }
  //   if(seeAll.current.getBoundingClientRect().top < 80 && seeAll.current.getBoundingClientRect().top > 0){
  //     setFilter('SeeAll')
  //   }
  // };

  // const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

  // const scrollToFilter = (filter, ref) => {
  //   setFilter(filter);
  //   ref.current.scrollIntoView({ behavior: 'smooth', block: 'start'})
  //   // scrollToRef(ref);
  // }

  const changeRouteToRestaurantsCategories = (category) => {
    history.push('/desktop/categories/' + category.id);

    // if (categories.categories_ids) {
    //   const saveCategorySelectOptions = new Promise(resolve => {
    //     localStorage.setItem("categorySelectOptions", JSON.stringify(categories.categories_ids));
    //     resolve("categorySelectOptions Saved");
    //   });
    //   saveCategorySelectOptions.then(() => {
    //     this.props.history.push("/desktop/categories/"+categories.id);
    //   });
    // }
  };

  const filterRestaurants = (v) => {
    setFilters(v);

    if (v.length === 0) {
      setRestaurants(arrayHolder);
      return;
    }

    let f = [];

    let newData = arrayHolder.filter((i) => {
      if (i.schedule_data) {
        if (i.schedule_data.cusines) {
          f = [...f, ...i.schedule_data.cusines.split(',')];
        }
        if (i.schedule_data.restaurantType) {
          f = [...f, ...i.schedule_data.restaurantType.split(',')];
        }
        if (i.schedule_data.filters) {
          f = [...f, ...i.schedule_data.filters.split(',')];
        }

        if (hasDuplicates(v, f)) {
          return i;
        }

        return f;
      }
      return i;
    });
    setRestaurants(newData);
  };

  function hasDuplicates(arr1, arr2) {
    // console.log(new Set(arr))
    // console.log(arr.length)
    // return new Set(arr).size !== arr.length;
    var duplicates = arr1.filter(function (val) {
      return arr2.indexOf(val) !== -1;
    });
    if (duplicates.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  console.log('BG: ', banners);

  return (
    <div className="container-fluid p-0">
      <Header />

      {banners && (
        <div className="row promo-slider-wrapper">
          <div className="col-md-12">
            <CarouselProvider
              naturalSlideWidth={20}
              naturalSlideHeight={15}
              totalSlides={banners.length}
              visibleSlides={4}
              style={{
                backgroundColor: banners[0]?.promo_slider.bg_color || '#fff',
                maxWidth: '100%',
              }}
            >
              <Slider>
                {banners.map((item, index) => {
                  return (
                    <Slide key={index} className={'slider-div'}>
                      <a href={'/desktop/promo-restaurants/' + item.url}>
                        <img
                          className="zoom"
                          style={{ width: '80%', marginTop: 45 }}
                          src={ROOT_URL + item.image}
                          alt="HotpotAfrica"
                        />
                      </a>
                    </Slide>
                  );
                })}
              </Slider>
              <ButtonBack className="slider-left-button">
                <ChevronLeftIcon />
              </ButtonBack>
              <ButtonNext className="slider-right-button">
                <ChevronRightIcon />
              </ButtonNext>
            </CarouselProvider>
          </div>
        </div>
      )}

      <div
        className="container"
        style={{
          position: 'sticky',
          top: 0,
          marginTop: 64,
        }}
      >
        {!loading && (restaurants.length > 0 || filters.length !== 0) && (
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div style={{ marginTop: 40 }}>
                    <div>
                      <div className="mt-2">
                        <FormGroup row>
                          <FormControlLabel
                            style={{ margin: 0, width: 120 }}
                            control={
                              <IOSSwitch
                                checked={
                                  deliveryType === 'Delivery' ? true : false
                                }
                                onChange={(e) => setDeliveryType(e.target.name)}
                                name="Delivery"
                              />
                            }
                            label="Delivery"
                          />
                          <FormControlLabel
                            style={{ margin: 0, width: 90 }}
                            control={
                              <IOSSwitch
                                checked={
                                  deliveryType === 'Pickup' ? true : false
                                }
                                onChange={(e) => setDeliveryType(e.target.name)}
                                name="Pickup"
                              />
                            }
                            label="Pickup"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="p-2">
                    {localStorage.getItem('enRestaurantCategorySlider') ===
                      'true' && (
                      <React.Fragment>
                        {categories.length > 0 && (
                          <div
                            style={{ marginTop: '0px!important' }}
                            className="slider-wrapper bg-white secondary-slider-wrapper"
                          >
                            <CarouselProvider
                              naturalSlideWidth={45}
                              naturalSlideHeight={45}
                              totalSlides={categories.length}
                              visibleSlides={10}
                              style={{ backgroundColor: '#fff' }}
                            >
                              <Slider>
                                {categories.map((category, index) => {
                                  return (
                                    <Slide
                                      style={{ paddingBottom: '4%' }}
                                      key={index}
                                      className={'slider-div'}
                                    >
                                      <div
                                        className="slider-wrapper__img-wrapper zoom"
                                        key={category.id}
                                      >
                                        <div
                                          style={{ position: 'relative' }}
                                          onClick={() => {
                                            changeRouteToRestaurantsCategories(
                                              category
                                            );
                                          }}
                                        >
                                          <img
                                            src={ROOT_URL + category.image}
                                            alt={category.name}
                                            className="slider-wrapper__img slider-cust-img"
                                            style={{
                                              objectFit: 'scale-down',
                                              height: '3.8rem',
                                              width: '3.8rem',
                                              borderRadius:
                                                parseFloat(
                                                  localStorage.getItem(
                                                    'restaurantCategorySliderStyle'
                                                  )
                                                ) + 'rem',
                                            }}
                                          />
                                          {localStorage.getItem(
                                            'showRestaurantCategorySliderLabel'
                                          ) === 'true' && (
                                            <h6
                                              className="text-center mt-2 mb-0"
                                              style={{ fontSize: 14 }}
                                            >
                                              {category.name}
                                            </h6>
                                          )}
                                          <Ink duration="500" hasTouch={true} />
                                        </div>
                                      </div>
                                    </Slide>
                                  );
                                })}
                              </Slider>
                              <ButtonBack
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  top: '25%',
                                  left: 0,
                                }}
                                className="slider-left-button"
                              >
                                <ChevronLeftIcon size={24} />
                              </ButtonBack>
                              <ButtonNext
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  top: '25%',
                                  right: 0,
                                }}
                                className="slider-right-button"
                              >
                                <ChevronRightIcon size={24} />
                              </ButtonNext>
                            </CarouselProvider>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>

              <div style={{ padding: 25, paddingTop: 0 }}>
                <div className="row mb-3">
                  <div className="col-md-3 p-0">
                    <div style={{ borderBottom: '1px solid #d8d8d8' }}>
                      <h4 ref={seeAll} style={{ marginTop: 12 }}>
                        <b>{restaurants.length} Restaurants</b>
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-7 p-2">
                    <Tabs
                      value={value}
                      style={{ borderBottom: '1px solid #d8d8d8' }}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                    >
                      <Tab label="Relevance" />
                      <Tab label="Cost For Two" />
                      <Tab label="Delivery Time" />
                      <Tab label="Rating" />
                    </Tabs>
                  </div>
                  <div className="col-md-2 p-0">
                    <FiltersDrawer
                      filterRestaurants={(v) => filterRestaurants(v)}
                    />
                  </div>
                </div>

                {loading && (
                  <div className="row">
                    {[...Array(12)].map((i) => {
                      return (
                        <div className="col-md-3">
                          <div className="mb-3">
                            <Skeleton variant="rect" height={150} />
                            <Skeleton variant="text" style={{ marginTop: 5 }} />
                            <Skeleton variant="text" width={100} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {!loading && restaurants.length > 0 && (
                  <div className="row dashed-bottom">
                    {restaurants.map((item) => {
                      console.log(item.image);
                      return (
                        <div key={item.id} className="col-md-3">
                          <div
                            className={
                              'mb-3 ' +
                              (item.is_open === 0
                                ? 'page-inactive-clickable'
                                : '')
                            }
                          >
                            <div>
                              <CardActionArea
                                onClick={() =>
                                  history.push('restaurantDetail/' + item.slug)
                                }
                              >
                                <img
                                  src={ROOT_URL + item.image}
                                  alt="HotpotAfrica"
                                  style={{
                                    width: '100%',
                                    minHeight: 165,
                                    objectFit: 'cover',
                                    borderRadius: 4,
                                  }}
                                />

                                <CardContent
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '135px',
                                    padding: '0',
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      minHeight: '80px',
                                    }}
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      component="h2"
                                      style={{
                                        margin: '10px 0',
                                      }}
                                    >
                                      {item.name}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                    >
                                      {item.description}
                                    </Typography>
                                  </div>
                                  <div className="restaurant-cards-options-wrapper mt-2">
                                    <span
                                      style={{
                                        marginRight: 2,
                                        fontSize: 13,
                                        backgroundColor: '#4d525d',
                                        padding: '3px 6px',
                                        borderRadius: 2,
                                        color: 'white',
                                      }}
                                    >
                                      <StarIcon style={{ fontSize: 12 }} />{' '}
                                      {item.rating}
                                    </span>
                                    <div className="restaurant-cards-options-delivery">
                                      <div>
                                        <span
                                          style={{
                                            color: '#6f6f6f',
                                            padding: '0 6px',
                                          }}
                                        >
                                          •
                                        </span>
                                        <span style={{ fontSize: 11 }}>
                                          {item.delivery_time}
                                          {` ${localstorage.deliveryTime}`}
                                        </span>
                                      </div>
                                      <div>
                                        <span
                                          style={{
                                            color: '#6f6f6f',
                                            padding: '0 6px',
                                          }}
                                        >
                                          •
                                        </span>
                                        <span style={{ fontSize: 11 }}>
                                          {intlFormatNumber(
                                            localstorage?.currencyId,
                                            item.price_range,
                                            localstorage?.currencySymbolAlign
                                          )}
                                          {` ${localstorage.deliveryCostsForTwo}`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </CardContent>
                              </CardActionArea>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!loading && restaurants.length === 0 && (
          <div className="row">
            <div className="col-md-12">
              <div
                style={{
                  padding: 50,
                  textAlign: 'center',
                  marginBottom: '2rem',
                }}
              >
                <EmptyCart />
                <h4 className={'mb-2'}>
                  Oh! All restaurants are <br /> currently unserviceable
                </h4>
                <p className={'text-muted'}>
                  All restaurants are unserviceable, check back in some time.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const IOSSwitch = withStyles(() => ({
  root: {
    width: 52,
    height: 26,
    padding: 0,
    margin: useTheme().spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(26px)',
      color: useTheme().palette.common.white,
      '& + $track': {
        backgroundColor: '#c419af',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#c419af',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${useTheme().palette.grey[400]}`,
    backgroundColor: '#dcdcdc',
    opacity: 1,
    transition: useTheme().transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
