import { GoogleApiWrapper, Map, Marker, Polyline } from 'google-maps-react';
import mapStyle from '../../../GeoLocationPage/mapStyle.json';
import ContentLoader from 'react-content-loader';
import React, { Component } from 'react';

// function decode(t,e){for(var n,o,u=0,l=0,r=0,d= [],h=0,i=0,a=null,c=Math.pow(10,e||5);u<t.length;){a=null,h=0,i=0;do a=t.charCodeAt(u++)-63,i|=(31&a)<<h,h+=5;while(a>=32);n=1&i?~(i>>1):i>>1,h=i=0;do a=t.charCodeAt(u++)-63,i|=(31&a)<<h,h+=5;while(a>=32);o=1&i?~(i>>1):i>>1,l+=n,r+=o,d.push([l/c,r/c])}return d=d.map(function(t){return{latitude:t[0],longitude:t[1]}})}
class GoogleMaps extends Component {
  state = {
    zoom: 13,
    pathCoordinates: [],
    cords: {},
  };

  componentDidMount() {
    console.log('SAY ME THE KEY: ', this.props.googleMapsApiKey);
    const deliveryMarker = this.refs.deliveryRefIcon;

    if (deliveryMarker) {
      deliveryMarker.style.transform = 'rotate(' + 50 + 'deg)';
      deliveryMarker.style.transition = 'transform 1s linear';
    }
    setTimeout(() => {
      this.setState({ zoom: 18 });
    }, 1 * 1000);

    this.calculatePath();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    setTimeout(() => {
      if (
        document.querySelector(
          '[src*="/assets/img/various/marker-delivery.png"]'
        ) &&
        nextProps.delivery_gps_location.heading
      ) {
        console.log('Inside update delivery icon');
        document.querySelector(
          '[src*="/assets/img/various/marker-delivery.png"]'
        ).style.transform =
          'rotate(' + nextProps.delivery_gps_location.heading + 'deg)';
        document.querySelector(
          '[src*="/assets/img/various/marker-delivery.png"]'
        ).style.transition = 'transform 0.25s linear';
      }
    }, 500);
  }

  calculatePath() {
    let mode = 'driving'; // 'walking';
    let origin = `${JSON.parse(this.props.deliveryLocation).lat}, ${
      JSON.parse(this.props.deliveryLocation).lng
    }`;
    let destination = `${parseFloat(
      this.props.restaurant_latitude
    )}, ${parseFloat(this.props.restaurant_longitude)}`;

    let url = `http://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&key=${this.props.googleMapsApiKey}&mode=${mode}`;

    fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.routes.length) {
          this.setState({
            cords: this.decode(responseJson.routes[0].overview_polyline.points), // definition below
          });
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  }

  handleGoogleMapApi = () => {
    var flightPath = new this.props.google.maps.Polyline({
      path: [
        {
          lat: JSON.parse(this.props.deliveryLocation).lat,
          lng: JSON.parse(this.props.deliveryLocation).lng,
        },
        {
          lat: parseFloat(this.props.restaurant_latitude),
          lng: parseFloat(this.props.restaurant_longitude),
        },
      ],
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    console.log(flightPath);
    flightPath.setMap(this.props.google.map);
  };

  render() {
    return (
      <Map
        ref={(ref) => {
          this.map = ref;
        }}
        google={this.props.google}
        onReady={(mapProps, map) => {
          // this.props.reverseLookup(this.state.initialPosition.lat, this.state.initialPosition.lng);
          // localStorage.setItem("userLat", map.center.lat());
          // localStorage.setItem("userLng", map.center.lng());
          // this.showButton(map, this);
          this.handleGoogleMapApi();
        }}
        // onGoogleApiLoaded={this.handleGoogleMapApi}
        style={{
          width: '100%',
          position: 'inherit',
          height: '55vh',
        }}
        initialCenter={
          !isNaN(this.props.delivery_gps_location.delivery_lat) &&
          this.props.delivery_gps_location.delivery_lat !== undefined
            ? {
                lat: parseFloat(this.props.delivery_gps_location.delivery_lat),
                lng: parseFloat(this.props.delivery_gps_location.delivery_long),
              }
            : {
                lat: this.props.restaurant_latitude,
                lng: this.props.restaurant_longitude,
              }
        }
        zoom={this.state.zoom}
        styles={mapStyle}
        zoomControl={false}
        mapTypeControl={false}
        scaleControl={true}
        streetViewControl={false}
        fullscreenControl={false}
      >
        {console.log([
          {
            lat: parseFloat(this.props.restaurant_latitude),
            lng: parseFloat(this.props.restaurant_longitude),
          },
        ])}
        <Polyline
          path={[
            this.props.show_delivery_gps
              ? {
                  lat: parseFloat(
                    this.props.delivery_gps_location.delivery_lat
                  ),
                  lng: parseFloat(
                    this.props.delivery_gps_location.delivery_long
                  ),
                }
              : {
                  lat: parseFloat(this.props.restaurant_latitude),
                  lng: parseFloat(this.props.restaurant_longitude),
                },
            {
              lat: parseFloat(JSON.parse(this.props.deliveryLocation).lat),
              lng: parseFloat(JSON.parse(this.props.deliveryLocation).lng),
            },
          ]}
          geodesic={true}
          zIndex={5}
          options={{
            strokeColor: '#ea4335',
            strokeOpacity: 0,
            icons: [
              {
                icon: {
                  path: 'M 0,-2 0,2',
                  strokeColor: '#ea4335',
                  strokeOpacity: 1,
                  strokeWeight: 3,
                },
                repeat: '36px',
              },
            ],
          }}
        />

        {console.log(JSON.parse(this.props.order.location).lat)}

        <Marker
          position={{
            lat: JSON.parse(this.props.order.location).lat,
            lng: JSON.parse(this.props.order.location).lng,
          }}
          icon={{
            url: '/assets/img/various/marker-home.png',
            // anchor: new this.props.google.maps.Point(32, 32),
            scaledSize: new this.props.google.maps.Size(34, 54),
          }}
        ></Marker>

        <Marker
          position={{
            lat: parseFloat(this.props.restaurant_latitude),
            lng: parseFloat(this.props.restaurant_longitude),
          }}
          icon={{
            url: '/assets/img/various/marker-restaurant.png',
            // anchor: new this.props.google.maps.Point(32, 32),
            scaledSize: new this.props.google.maps.Size(34, 54),
          }}
        ></Marker>

        {console.log('--------------------')}

        {console.log([
          {
            lat: JSON.parse(this.props.deliveryLocation).lat,
            lng: JSON.parse(this.props.deliveryLocation).lng,
          },
          {
            lat: parseFloat(this.props.restaurant_latitude),
            lng: parseFloat(this.props.restaurant_longitude),
          },
        ])}

        {this.props.show_delivery_gps && (
          <Marker
            position={{
              lat: parseFloat(this.props.delivery_gps_location.delivery_lat),
              lng: parseFloat(this.props.delivery_gps_location.delivery_long),
            }}
            icon={{
              url: '/assets/img/various/marker-delivery.png',
              anchor: new this.props.google.maps.Point(32, 32),
              scaledSize: new this.props.google.maps.Size(54, 54),
            }}
          ></Marker>
        )}

        <Polyline
          coordinates={[
            {
              latitude: JSON.parse(this.props.deliveryLocation).lat,
              longitude: JSON.parse(this.props.deliveryLocation).lng,
            },
            {
              latitude: parseFloat(this.props.restaurant_latitude),
              longitude: parseFloat(this.props.restaurant_longitude),
            },
          ]}
          strokeWidth={4}
        />
      </Map>
    );
  }
}

const MapLoadingContainer = () => (
  <ContentLoader
    height={400}
    width={window.innerWidth}
    speed={1.2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width={window.innerWidth} height="400" />
  </ContentLoader>
);

export default GoogleApiWrapper((props) => ({
  apiKey: props.googleMapsApiKey,
  LoadingContainer: MapLoadingContainer,
}))(GoogleMaps);
