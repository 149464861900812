import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { GET_ORDERS_URL, ROOT_URL } from '../../../../configs';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Axios from 'axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/lab/Skeleton';
import OrderDetailDrawer from './OrderDetailDrawer';
import OrderTrackDrawer from './OrderTrackDrawer';
import EmptyCart from '../../../Loading/EmptyCart';

export default function MyOrders(params) {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  // const [account, setAccount] = useState({});
  const [orders, setOrders] = useState([]);
  const [openOrderId, setOpenOrderId] = useState(null);
  const [openOrderIdTrack, setOpenOrderIdTrack] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const [trackDrawer, setTrackDrawer] = useState(false);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user'));
    // setAccount(user)

    Axios.post(GET_ORDERS_URL, { token: user.auth_token, user_id: user.id })
      .then((res) => {
        console.log(res);
        setOrders(res.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, []);

  const openOrderDetailsDrawer = (id) => {
    setOpenOrderId(id);
    setDrawer(true);
  };

  const openOrderTrackDrawer = (id) => {
    setOpenOrderIdTrack(id);
    setTrackDrawer(true);
  };

  return (
    <div className="container-fluid">
      {loading && (
        <div className="row">
          {[...Array(5)].map((i) => {
            return (
              <div key={i} className="col-md-12">
                <div className="mb-3 bg-white p-3">
                  <Skeleton variant="rect" height={64} />
                  <Skeleton variant="text" style={{ marginTop: 5 }} />
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={50} />
                </div>
              </div>
            );
          })}
        </div>
      )}

      {!loading && orders.length === 0 && (
        <div className="row bg-white">
          <div className="col-12">
            <div className="p-3">
              <EmptyCart />
            </div>
          </div>
        </div>
      )}

      <div className="row">
        {orders.map((item) => {
          return (
            <div className="col-12 mb-3">
              <Card>
                <CardContent style={{ cursor: 'pointer' }}>
                  <div className="row">
                    <div
                      className="col-2"
                      style={{
                        borderBottom: '1px dashed grey',
                        paddingBottom: 14,
                      }}
                    >
                      <img
                        alt="restaurant"
                        src={
                          ROOT_URL + '/public/' + item.resturant_details.image
                        }
                        className="img-fluid"
                      />
                    </div>
                    <div
                      className="col-10 p-0"
                      style={{
                        borderBottom: '1px dashed grey',
                        paddingBottom: 14,
                      }}
                    >
                      <Typography variant="h6" component="h2">
                        {item.resturant_details.name}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {item.resturant_details.address}
                      </Typography>
                      <br />
                      <br />
                      <Button
                        variant="outlined"
                        onClick={() => openOrderDetailsDrawer(item.id)}
                        size="small"
                      >
                        View Details
                      </Button>
                      <br />
                      <br />
                      {(item.orderstatus_id === 1 ||
                        item.orderstatus_id === 2 ||
                        item.orderstatus_id === 3 ||
                        item.orderstatus_id === 4 ||
                        item.orderstatus_id === 7) && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            openOrderTrackDrawer(item.unique_order_id)
                          }
                          size="small"
                        >
                          Track
                        </Button>
                      )}
                    </div>
                  </div>

                  <br />

                  <Typography variant="caption">ITEMS</Typography>

                  {item.orderitems.map((dish) => {
                    return (
                      <div className="row">
                        <div className="col-9">
                          <Typography variant="body2" component="h2">
                            <b>
                              {dish.quantity} X {dish.name}
                            </b>
                          </Typography>
                        </div>
                        <div className="col-3">
                          <Typography variant="body2" component="h2">
                            <b>
                              {localStorage.getItem('currencyFormat')}{' '}
                              {dish.price}
                            </b>
                          </Typography>
                        </div>
                      </div>
                    );
                  })}

                  <br />

                  <div className="row">
                    <div className="col-6">
                      <Typography variant="caption">ORDERED ON</Typography>
                      <Typography variant="body2">
                        <b>{new Date(item.created_at).toLocaleString()}</b>
                      </Typography>
                    </div>
                    <div className="col-3">
                      <Typography variant="caption">STATUS</Typography>
                      <Typography
                        style={{
                          color: item.orderstatus_id === 6 ? 'red' : 'black',
                        }}
                        variant="body2"
                      >
                        <b>
                          {item.orderstatus_id === 1
                            ? 'Order Placed'
                            : item.orderstatus_id === 2
                            ? 'Preparing Food'
                            : item.orderstatus_id === 3
                            ? 'Delivery Agent Assigned'
                            : item.orderstatus_id === 4
                            ? 'Food is on the way'
                            : item.orderstatus_id === 6
                            ? 'Canceled'
                            : 'Order Placed'}
                        </b>
                      </Typography>
                    </div>
                    <div className="col-3">
                      <Typography variant="caption">Order Type</Typography>
                      <Typography variant="caption" component="p">
                        {item.delivery_type === 1 ? 'Delivery' : 'Self Pickup'}
                      </Typography>
                    </div>
                  </div>
                </CardContent>
                <CardActions>
                  {/*(item.orderstatus_id!==6 && item.orderstatus_id!==5)&& (
                  <Button style={{backgroundColor: 'green', color: 'white'}} variant="contained" onClick={()=>history.push('/liveTrack/'+item.id)} size="small">TRACK</Button>
                )*/}
                  {item.orderstatus_id === 5 && (
                    <Button disabled={true} variant="outlined" size="small">
                      DELIVERED
                    </Button>
                  )}
                  {item.orderstatus_id !== 6 && (
                    <Button
                      onClick={() =>
                        history.push(
                          `/desktop/restaurantDetail/${item.resturant_details.slug}`
                        )
                      }
                      size="small"
                    >
                      RE-ORDER
                    </Button>
                  )}
                </CardActions>
              </Card>
            </div>
          );
        })}
      </div>

      <OrderDetailDrawer
        setDrawer={setDrawer}
        drawer={drawer}
        order_id={openOrderId}
      />
      <OrderTrackDrawer
        setDrawer={setTrackDrawer}
        drawer={trackDrawer}
        order_id={openOrderIdTrack}
      />
    </div>
  );
}
