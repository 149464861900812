import { useState } from 'react';
import { GET_SETTINGS_URL } from '../../../configs';
import Axios from 'axios';

export const useGetSettings = () => {
  const [settingsObj, setSettingsObj] = useState({});

  if (Object.keys(settingsObj)?.length < 1) {
    Axios.post(GET_SETTINGS_URL).then((response) => {
      const settingsTemp =
        response && 'settings' in response ? response.settings : response;
      setSettingsObj(settingsTemp.data);
    });
  }

  return settingsObj;
};
