import React, { useState, useEffect } from 'react';

// import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from '@mui/styles/';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
// import { useHistory } from "react-router-dom";
// import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Axios from 'axios';
import { GET_DELIVERY_RESTAURANTS_URL, ROOT_URL } from '../../../configs';
import Skeleton from '@mui/lab/Skeleton';
import Header from '../Layouts/Header';
import { useHistory } from 'react-router-dom';
// import Switch from '@mui/material/Switch';
// import { withStyles } from '@mui/styles/';
// import FiltersDrawer from './Filters/FiltersDrawer';
import EmptyCart from '../../../components/Loading/EmptyCart';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: useTheme().spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function PromoRestaurants() {
  const history = useHistory();
  const { slug } = useParams();

  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  // const [filter, setFilter] = useState('TopPicks');
  const [restaurants, setRestaurants] = useState([]);
  // const [banners, setBanners] = useState([]);
  // const [categories, setCategories] = useState([]);
  // const [deliveryType, setDeliveryType] = useState('Delivery');
  // const [value, setValue] = useState(0);

  useEffect(() => {
    Axios.post(GET_DELIVERY_RESTAURANTS_URL, {
      latitude: localStorage.getItem('userLat'),
      longitude: localStorage.getItem('userLng'),
    })
      .then((res) => {
        console.log(res.data);
        setRestaurants(
          res.data
            .filter((i) => i.is_active === 1)
            .filter((j) => slug.split(',').includes(String(j.id)))
        );
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, [slug]);

  return (
    <div className="container-fluid p-0">
      <Header />

      <div className="container">
        {!loading && restaurants.length > 0 && (
          <div className="row">
            <div className="col-md-12">
              <div style={{ padding: 25, paddingTop: 0 }}>
                {loading && (
                  <div className="row">
                    {[...Array(12)].map((i) => {
                      return (
                        <div className="col-md-3">
                          <div className="mb-3">
                            <Skeleton variant="rect" height={150} />
                            <Skeleton variant="text" style={{ marginTop: 5 }} />
                            <Skeleton variant="text" width={100} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {!loading && restaurants.length > 0 && (
                  <div className="row dashed-bottom pt-4">
                    {restaurants.map((item) => {
                      return (
                        <div key={item.id} className="col-md-3">
                          <div
                            className={
                              'mb-3 ' +
                              (item.is_open === 0
                                ? 'page-inactive-clickable'
                                : '')
                            }
                          >
                            <div className={classes.root}>
                              <CardActionArea
                                onClick={() =>
                                  history.push(
                                    '/desktop/restaurantDetail/' + item.slug
                                  )
                                }
                              >
                                <img
                                  src={ROOT_URL + '/public/' + item.image}
                                  alt="HotpotAfrica"
                                  style={{
                                    width: '100%',
                                    objectFit: 'cover',
                                  }}
                                />

                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                  >
                                    {item.description}
                                  </Typography>

                                  <div className="mt-2">
                                    <span
                                      style={{
                                        marginRight: 2,
                                        fontSize: 13,
                                        backgroundColor: 'green',
                                        padding: '3px 6px',
                                        borderRadius: 2,
                                        color: 'white',
                                      }}
                                    >
                                      <StarIcon style={{ fontSize: 12 }} />{' '}
                                      {item.rating}
                                    </span>
                                    <span
                                      style={{
                                        color: '#6f6f6f',
                                        padding: '0 6px',
                                      }}
                                    >
                                      •
                                    </span>
                                    <span style={{ fontSize: 11 }}>
                                      {item.delivery_time} MINS
                                    </span>
                                    <span
                                      style={{
                                        color: '#6f6f6f',
                                        padding: '0 6px',
                                      }}
                                    >
                                      •
                                    </span>
                                    <span style={{ fontSize: 11 }}>
                                      {item.price_range} FOR TWO
                                    </span>
                                  </div>
                                </CardContent>
                              </CardActionArea>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!loading && restaurants.length === 0 && (
          <div className="row">
            <div className="col-md-12">
              <div
                style={{
                  padding: 50,
                  textAlign: 'center',
                  marginBottom: '2rem',
                }}
              >
                <EmptyCart />
                <h4 className={'mb-2'}>
                  Oh! All restaurants are <br /> currently unserviceable
                </h4>
                <p className={'text-muted'}>
                  All restaurants are unserviceable, check back in some time.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
