import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import Ink from 'react-ink';
import { formatPrice } from '../../../../../helpers/formatPrice';
import Fade from 'react-reveal/Fade';
// import Flip from "react-reveal/Flip";

const CancelReasons = [
  'My order was placed by mistake.',
  'I just moved to another place.',
  'I ordered food from somewhere.',
  'Total cart price has been increased.',
  'I want to update my new address.',
  'Food delivery address is wrong.',
  'I am not able to track my order.',
  'Delivery boy is not picking my call.',
  'I am not at my home right now.',
  'It seems like food is staled…',
];

class OrderCancelPopup extends Component {
  state = {
    open: false,
    reason: '',
    cancelResonPickerOpen: false,
  };
  handlePopupOpen = () => {
    this.setState({ open: true });
  };
  handlePopupClose = () => {
    this.setState({ open: false });
  };
  handleReasonOpen = () => {
    this.setState({ cancelResonPickerOpen: true });
  };
  handleReasonClose = (item) => {
    this.setState({ reason: item, cancelResonPickerOpen: false });
  };

  renderCancelReasons() {
    return CancelReasons.map((item, index) => (
      <div
        className="menu-item-block-single"
        onClick={() => this.handleReasonClose(item)}
        data-name={item}
      >
        <Fade bottom duration={150}>
          <div className="">
            <span className="text-font-sm text-muted">{item}</span>
          </div>
        </Fade>
      </div>
    ));
  }

  render() {
    const { order, user, cancelOrder } = this.props;

    return (
      <React.Fragment>
        <button
          className="btn btn-square btn-sm btn-outline-danger mb-0 mt-15"
          style={{ position: 'relative', fontSize: '0.8rem' }}
          onClick={this.handlePopupOpen}
        >
          {localStorage.getItem('cancelOrderMainButton')}
          <Ink duration="500" />
        </button>
        <Modal
          open={this.state.open}
          onClose={this.handlePopupClose}
          closeIconSize={32}
        >
          <div className="text-center mt-100">
            <div style={{ fontSize: '1.2rem', fontWeight: '500' }}>
              <i
                className="si si-info"
                style={{ fontSize: '4rem', opacity: '0.3', color: '#FF9800' }}
              ></i>
              <p className="m-0">
                {localStorage.getItem('orderCancellationConfirmationText')}
              </p>
              <p className="text-small">
                <small>
                  <b>order id:</b> {order.unique_order_id}
                </small>
              </p>
              {order.orderstatus_id === 1 ? (
                <React.Fragment>
                  {order.payment_mode !== 'COD' && (
                    <p className="text-muted font-w400">
                      {localStorage.getItem('currencySymbolAlign') === 'left' &&
                        localStorage.getItem('currencyFormat')}
                      {order.total}{' '}
                      {localStorage.getItem('currencySymbolAlign') ===
                        'right' && localStorage.getItem('currencyFormat')}
                      {localStorage.getItem('willBeRefundedText')}
                    </p>
                  )}
                  {order.payment_mode === 'COD' &&
                    order.total - order.payable !== 0 && (
                      <p className="text-muted font-w400">
                        {localStorage.getItem('currencySymbolAlign') ===
                          'left' && localStorage.getItem('currencyFormat')}
                        {formatPrice(order.total - order.payable)}{' '}
                        {localStorage.getItem('currencySymbolAlign') ===
                          'right' && localStorage.getItem('currencyFormat')}
                        {localStorage.getItem('willBeRefundedText')}
                      </p>
                    )}
                </React.Fragment>
              ) : (
                <p className="text-muted font-w400">
                  {localStorage.getItem('willNotBeRefundedText')}
                </p>
              )}
            </div>
            {/*<div className="mb-3">No charges if cancled in 2 min</div>*/}

            <button
              className="btn btn-danger btn-rounded mb-3"
              onClick={() => this.setState({ cancelResonPickerOpen: true })}
              style={{ backgroundColor: localStorage.getItem('storeColor') }}
            >
              Select Cancel Reason
              <i className="si si-arrow-down ml-1"></i>
            </button>

            {/*<label className="float-left ml-3">
							Cancel Reason
						</label>*/}

            <div className="col-md-9 pb-5">
              <input
                type="text"
                className="p-2 bg-light form-control edit-address-input"
                name="name"
                value={this.state.reason}
                onChange={(reason) => this.setState({ reason })}
              />
            </div>

            <div>
              <button
                className={
                  this.state.reason !== ''
                    ? 'btn btn-lg mr-3 btn-danger'
                    : 'btn btn-lg mr-3 bg-light'
                }
                disabled={this.state.reason === '' ? true : false}
                onClick={() =>
                  cancelOrder(
                    user.data.auth_token,
                    user.data.id,
                    order.id,
                    this.state.reason
                  )
                }
                style={{
                  border: '0',
                  borderRadius: '0',
                  backgroundColor: localStorage.getItem('storeColor'),
                }}
              >
                {localStorage.getItem('yesCancelOrderBtn')}
              </button>
              <button
                onClick={this.handlePopupClose}
                className="btn btn-lg"
                style={{ border: '0', borderRadius: '0' }}
              >
                {localStorage.getItem('cancelGoBackBtn')}
              </button>
            </div>
          </div>
        </Modal>

        <div className="menu-list-container">
          {this.state.cancelResonPickerOpen === true && (
            <div className="menu-list-container">
              <React.Fragment>
                <div className="menu-open-backdrop"></div>
                <div className="menu-items-block" ref="menuItemBlock">
                  <div className="menu-item-block-inner">
                    {this.renderCancelReasons()}
                  </div>
                </div>
              </React.Fragment>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default OrderCancelPopup;
