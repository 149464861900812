import React, { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles/';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Axios from 'axios';
import { GET_DELIVERY_RESTAURANTS_URL, ROOT_URL } from '../../../configs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Header from '../Layouts/Header';
import InputAdornment from '@mui/material/InputAdornment';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: useTheme().spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Search() {
  const classes = useStyles();
  const history = useHistory();

  const [search, setSearch] = useState('');
  const [restaurants, setRestaurants] = useState([]);
  // const [banners, setBanners] = useState([]);
  const [arrayHolder, setArrayHolder] = useState([]);

  useEffect(() => {
    Axios.post(GET_DELIVERY_RESTAURANTS_URL, {
      latitude: localStorage.getItem('userLat'),
      longitude: localStorage.getItem('userLng'),
    })
      .then((res) => {
        console.log(res.data);
        setRestaurants(res.data);
        setArrayHolder(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const searchItems = (text) => {
    const newData = arrayHolder.filter((item) => {
      const itemData = `${String(item.name).toUpperCase()}`;
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });

    setRestaurants(newData);
    setSearch(text);
  };

  return (
    <div className="container-fluid p-0">
      <Header />

      <div className="row">
        <div className="col-md-12">
          <div style={{ padding: 35, textAlign: 'center' }}>
            <Card style={{ width: '50%', margin: 'auto' }}>
              <TextField
                style={{
                  width: '95%',
                  borderTopWidth: 1,
                  fontSize: 28,
                  marginTop: 10,
                  marginBottom: 10,
                }}
                id="search"
                value={search}
                autoFocus={true}
                onChange={(e) => searchItems(e.target.value)}
                placeholder="Search for Restaurants Or Dishes"
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ fontSize: 28 }} />
                    </InputAdornment>
                  ),
                }}
              />

              {search !== '' && (
                <List className={classes.root}>
                  {restaurants.map((item) => {
                    return (
                      <ListItem
                        button
                        key={item.id}
                        onClick={() =>
                          history.push('/desktop/restaurantDetail/' + item.slug)
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <img
                              alt="logo"
                              src={ROOT_URL + '/public/' + item.image}
                            />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.name}
                          secondary={item.description}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
