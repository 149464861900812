import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ContextStore } from '../../../configs/lib/ReactiveStatusProvider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import StarIcon from '@mui/icons-material/Star';
// import Card from '@mui/material/Card';
import Axios from 'axios';
import { PLACE_ORDER_URL, ROOT_URL } from '../../../configs';
// import Skeleton from '@mui/lab/Skeleton';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import Header from '../Layouts/Header';
// import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';
// import FavouriteBorder from '@mui/icons-material/FavoriteBorder';
// import FavoriteOutlined from '@mui/icons-material/FavoriteOutlined';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
// import Grow from '@mui/material/Grow';
// import TextField from '@mui/material/TextField';
import EmptyCart from '../../../components/Loading/EmptyCart';
import { useHistory, Link } from 'react-router-dom';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
// import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CouponsDrawer from './Coupons/CouponsDrawer';
import AddressDrawer from './Address/AddressDrawer';
import PaystackButton from 'react-paystack';

import LoginDrawer from '../Auth/LoginDrawer';
import SignupDrawer from '../Auth/SignupDrawer';

import { useCouponCode } from '../../Shared/Hooks/useCouponCode';

import './styles.scss';

export default function Cart(params) {
  const { loggedIn } = useContext(ContextStore);
  const localstorage = Object.assign({}, window.localStorage);
  // const { resSlug } = useParams();
  const history = useHistory();
  const couponCode = useCouponCode();

  // const topPicks = useRef(null)

  const [loading, setLoading] = useState(false);
  const [restaurant, setRestaurant] = useState({});
  const [cart, setCart] = useState(null);
  const [user, setUser] = useState(null);
  const [itemSubTotalPrice, setItemSubTotalPrice] = useState(0);
  const [itemTotalPrice, setItemTotalPrice] = useState(0);

  // const [tip, setTip] = useState(0);
  const [coupon, setCoupon] = useState(null);
  const [applyCoupon, setApplyCoupon] = useState({
    couponId: 0,
    applied: false,
    code: '',
  });
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [ready, setReady] = useState(false);

  const [login, showLogin] = useState(false);
  const [registry, showRegistry] = useState(false);

  // const [outOfRange, setOutOfRange] = useState(false);
  // const [deliveryTime, setDeliveryTime] = useState(undefined);

  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (loggedIn) {
      setUser(JSON.parse(localStorage.getItem('user')));
    }
  }, [loggedIn]);

  useEffect(() => {
    if (localStorage.getItem('cart') !== null) {
      setCart(JSON.parse(localStorage.getItem('cart')));
    }
    if (
      localStorage.getItem('user') !== null &&
      localStorage.getItem('user') !== undefined
    ) {
      setUser(JSON.parse(localStorage.getItem('user')));
    }

    setApplyCoupon({
      couponId: 0,
      applied: false,
      code: '',
    });

    let cartRestaurant = JSON.parse(localStorage.getItem('cartRestaurant'));
    let cartItems = JSON.parse(localStorage.getItem('cart'));
    setRestaurant(cartRestaurant);
    setCart(cartItems);

    let cp = localStorage.getItem('applyedCoupon');
    if (cp !== null) {
      setCoupon(JSON.parse(cp));
    }
  }, []);

  useEffect(() => {
    if (couponCode?.coupons?.length) {
      setCoupon(couponCode?.coupons);
    }
  }, [couponCode]);

  const subTotal = useCallback(() => {
    let total = 0;
    total = cart?.map(
      (item) => (total += parseFloat(item?.cartPrice || item?.price))
    );
    if (total?.length < 1) {
      setCart(null);
    }
    setItemSubTotalPrice(total?.length ? total?.pop()?.toFixed(2) : 0);
  }, [cart]);

  const calculateDiscount = useCallback(() => {
    if (!coupon || !applyCoupon?.applied) return 0;

    console.log('applyCoupon: ', applyCoupon);
    if (coupon[applyCoupon?.couponId - 1]?.discount_type === 'PERCENTAGE') {
      const dis =
        (itemSubTotalPrice *
          parseFloat(coupon[applyCoupon?.couponId - 1]?.discount)) /
        100;

      if (
        coupon[applyCoupon?.couponId - 1]?.upto_amount &&
        dis > parseFloat(coupon[applyCoupon?.couponId - 1]?.upto_amount)
      ) {
        return parseFloat(coupon[applyCoupon?.couponId - 1]?.upto_amount);
      }
      return dis.toFixed(2);
    }
    if (coupon[applyCoupon?.couponId - 1]?.discount_type === 'AMOUNT') {
      // let t = subTotal();
      let dis = parseFloat(coupon[applyCoupon?.couponId - 1]?.discount);
      if (dis > parseFloat(coupon[applyCoupon?.couponId - 1]?.upto_amount)) {
        return parseFloat(coupon[applyCoupon?.couponId - 1]?.upto_amount);
      }
      return dis.toFixed(2);
    }
  }, [applyCoupon, coupon, itemSubTotalPrice]);

  const taxPay = useCallback(() => {
    let tax = parseFloat(localStorage.getItem('taxPercentage'));
    if (isNaN(tax)) {
      return 0;
    }
    return Math.round(
      parseFloat(((itemSubTotalPrice + deliveryCharge) * tax) / 100)
    );
  }, [deliveryCharge, itemSubTotalPrice]);

  const totalPay = useCallback(() => {
    const tax = localStorage.getItem('taxApplicable') ? taxPay() : '';
    let total = Number(itemSubTotalPrice);
    total += Number(restaurant?.restaurant_charges);
    total += Number(deliveryCharge);
    total += Number(tax);
    total -= calculateDiscount();
    setItemTotalPrice(total.toFixed(2));
  }, [
    calculateDiscount,
    deliveryCharge,
    itemSubTotalPrice,
    restaurant?.restaurant_charges,
    taxPay,
  ]);

  useEffect(() => {
    if (cart !== null) {
      subTotal();
      calculateFee();
      totalPay();
    } else {
      setReady(true);
    }
  }, [cart, subTotal, totalPay]);

  // const removeFromCart=(item)=>{
  //   let newItems = cart.filter((i)=>i.id !== item.id)
  //   setCart([newItems])
  // }

  const incrementCart = useCallback(
    (citem) => {
      let item = cart.filter((i) => i.id === citem.id)[0];
      item.quantity = item.quantity + 1;
      let newItems = cart.filter((i) => i.id !== citem.id);
      item.cartPrice = Number(item.price) * item.quantity;
      newItems.push(item);
      setCart(newItems);
      localStorage.setItem('cart', JSON.stringify(newItems));
    },
    [cart, setCart]
  );

  const decrementCart = (citem) => {
    let item = cart.filter((i) => i.id === citem.id)[0];
    if (item.quantity === 1) {
      let newItems = cart.filter((i) => i.id !== citem.id);
      setCart(newItems);
      localStorage.setItem('cart', JSON.stringify(newItems));
      if (newItems.length === 0) {
        localStorage.removeItem('cart');
      }
    } else {
      let newItems = cart.filter((i) => i.id !== citem.id);
      item.quantity = item.quantity - 1;
      item.cartPrice = Number(item.price) * item.quantity;
      newItems.push(item);
      setCart(newItems);
      localStorage.setItem('cart', JSON.stringify(newItems));
    }
  };

  function calculateFee() {
    let cartRestaurant = JSON.parse(localStorage.getItem('cartRestaurant'));

    console.log(cartRestaurant);
    let baseCharge = parseFloat(cartRestaurant.delivery_charges);

    // if(cartRestaurant.delivery_charge_type === 'DYNAMIC'){
    //   var target_url = `https://admin.pureeats.in/googleDirectionCall?olat=${localStorage.getItem("userLat")}&olng=${localStorage.getItem("userLng")}&dlat=${cartRestaurant.latitude}&dlng=${cartRestaurant.longitude}`;
    //   Axios.get(`${target_url}`)
    //   .then(res=>{
    //     if(res.data.rows[0].elements[0].distance === undefined){
    //       setOutOfRange(true);
    //       return;
    //     }

    //     if((res.data.rows[0].elements[0].distance.value/1000) > parseInt(cartRestaurant.delivery_radius)){
    //       setOutOfRange(true);
    //       return;
    //     }

    //     setDeliveryTime(res.data.rows[0].elements[0].distance.value);

    //     if ((cartRestaurant.base_delivery_distance*1000) < res.data.rows[0].elements[0].distance.value) {
    //       let extra = ((res.data.rows[0].elements[0].distance.value - cartRestaurant.base_delivery_distance*1000))/1000;

    //       let finalCharge = baseCharge + (parseInt(cartRestaurant.extra_delivery_charge) * extra);
    //       console.log(baseCharge)
    //       console.log(extra)
    //       console.log(finalCharge)
    //       baseCharge = finalCharge;
    //       setDeliveryCharge(Math.round(baseCharge))
    //     }else{
    //       setDeliveryCharge(Math.round(baseCharge))
    //     }
    //   })
    // }else{
    //   setDeliveryCharge(Math.round(baseCharge))
    // }
    setDeliveryCharge(Math.round(baseCharge));
    setReady(true);
  }

  const placeOrder = (method) => {
    setLoading(true);
    if (user === null) return;

    Axios.post(PLACE_ORDER_URL, {
      token: user.auth_token,
      user: {
        delivery_details: null,
        running_order: null,
        success: true,
        data: user,
      },
      order: cart,
      coupon:
        localStorage.getItem('applyedCoupon') !== null
          ? { code: JSON.parse(localStorage.getItem('applyedCoupon')).code }
          : '',
      location: {
        address: localStorage.getItem('address'),
        lat: user.default_address.latitude,
        lng: user.default_address.longitude,
        house: '',
        tag: null,
      },
      order_comment: '',
      total: itemTotalPrice,
      method: 'COD',
      payment_token: '',
      delivery_type: 1,
      partial_wallet: 0,
    })
      .then((response) => {
        const checkout = response.data;

        if (checkout.success) {
          // dispatch({ type: PLACE_ORDER, payload: checkout });

          // const state = getState();
          // console.log(state);
          // const cartProducts = state.cart.products;
          // const user = state.user.user;
          localStorage.removeItem('orderComment');
          localStorage.removeItem('cart');
          localStorage.removeItem('cartRestaurant');

          history.push('/desktop/account');
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  /* PayStack */
  const callback = (response) => {
    if (response.status === 'success') {
      const payment_token = response.reference;
      const method = 'PAYSTACK';
      this.__placeOrder(payment_token, method);
    } else {
      console.log(response);
    }
  };

  const close = () => {
    console.log('PayStack Payment Closed');
  };

  const getReference = () => {
    //you can put any unique reference implementation code here
    let text = '';
    let possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=';

    for (let i = 0; i < 15; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  if (!ready) {
    return <p>...</p>;
  }

  const triggerCallback = () => {
    showLogin(false);
    showRegistry(false);
  };

  return (
    <div className="container-fluid p-0">
      <Header />

      {cart === null && (
        <div className="row">
          <div className="col-md-12">
            <div className="p-3 text-center">
              <EmptyCart />
              <Typography variant="h6" className="mt-3">
                Your cart is empty.
              </Typography>
              <Typography variant="body2">
                You can go to home page to view more restaurants or search for
                some near you.
              </Typography>
              <Button
                onClick={() =>
                  localStorage.getItem('address')
                    ? history.push('/desktop/restaurants')
                    : history.push('/')
                }
                style={{
                  backgroundImage:
                    'linear-gradient(to right, rgb(196, 25, 175), rgb(114, 32, 227))',
                  color: 'white',
                  borderRadius: 4,
                  marginTop: 15,
                }}
                variant="contained"
              >
                SEARCH RESTAURANTS NEAR YOU
              </Button>
            </div>
          </div>
        </div>
      )}

      {!!cart && (
        <div className="row bg-app desktop-checkout">
          <div className="col-md-6">
            <div className="row">
              <div className="col-12">
                <div style={{ marginLeft: 75 }} className="p-3 mt-4 bg-white">
                  <Typography
                    style={{ fontWeight: 'bold', fontSize: 28 }}
                    className="mb-3"
                    variant="h6"
                  >
                    Delivery address
                  </Typography>
                  <div
                    style={{
                      position: 'absolute',
                      width: '40px',
                      height: '40px',
                      borderRadius: 4,
                      backgroundColor: '#282c3f',
                      boxShadow: '0 3px 5px 0 rgba(40,44,63,.4)',
                      top: '35px',
                      left: '38px',
                      zIndex: 0,
                      padding: 9,
                    }}
                  >
                    <RoomOutlinedIcon
                      style={{
                        color: '#fff',
                        borderRadius: 4,
                      }}
                    />
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      borderLeft: '1px dashed #282c3f',
                      top: 55,
                      left: 56,
                      bottom: '-67px',
                    }}
                  ></div>

                  <div
                    style={{
                      position: 'absolute',
                      width: '40px',
                      height: '40px',
                      borderRadius: 4,
                      backgroundColor: '#282c3f',
                      boxShadow: '0 3px 5px 0 rgba(40,44,63,.4)',
                      top: '151px',
                      zIndex: 99,
                      left: '38px',
                      padding: 9,
                    }}
                  >
                    <AccountBalanceWalletOutlinedIcon
                      style={{ color: 'white' }}
                    />
                  </div>

                  <Typography className="" variant="body">
                    {user
                      ? localStorage.getItem('address')
                      : !!localStorage.getItem('userSetAddress') &&
                        JSON.parse(localStorage.getItem('userSetAddress'))
                          ?.formatted_address}
                  </Typography>

                  {/*deliveryTime && (
                        <Typography className="text-success" variant="caption">
                          Delivery in {Math.round(20+(deliveryTime/60))} mins
                        </Typography>
                        <AddressDrawer />
                      )*/}
                  <AddressDrawer />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div
                  style={{ marginLeft: '75px' }}
                  className="p-3 mt-4 bg-white"
                >
                  <Typography
                    style={{
                      marginTop: '-5px',
                      fontWeight: 'bold',
                      fontSize: 28,
                    }}
                    className={user ? 'mb-3' : 'mb-3 text-muted'}
                    variant="h6"
                  >
                    Choose payment method
                  </Typography>

                  {user === null && (
                    <>
                      <Button
                        onClick={() => showLogin(true)}
                        color="secondary"
                        size="large"
                      >
                        Login
                      </Button>
                      <Button
                        onClick={() => showRegistry(true)}
                        color="secondary"
                        size="large"
                      >
                        Signup
                      </Button>
                      <Typography className="text-muted" variant="caption">
                        to continue
                      </Typography>
                    </>
                  )}

                  {registry && (
                    <SignupDrawer
                      trigger={true}
                      triggerCallback={triggerCallback}
                      signin={localstorage?.firstScreenRegisterBtn}
                      account={localstorage?.accountMyAccount}
                    />
                  )}
                  {login && (
                    <LoginDrawer
                      trigger={true}
                      triggerCallback={triggerCallback}
                      login={localstorage?.firstScreenLoginBtn}
                    />
                  )}

                  {user !== null && (
                    <div className="row">
                      <div
                        className="col-md-2 p-0"
                        style={{ backgroundColor: 'rgb(226 226 226)' }}
                      >
                        <Tabs
                          value={tab}
                          orientation="vertical"
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          onChange={handleTabChange}
                          aria-label="Help"
                          style={{ marginTop: 20 }}
                        >
                          <Tab label="Online" />
                          <Tab label="Wallet" />
                          <Tab label="COD" />
                        </Tabs>
                      </div>
                      <div
                        className="col-md-10"
                        style={{
                          backgroundColor: '#fff',
                          padding: 40,
                          minHeight: 540,
                        }}
                      >
                        {tab === 0 && (
                          <div
                            style={{ padding: 20 }}
                            role="tabpanel"
                            id="vertical-tabpanel-0"
                            aria-labelledby="vertical-tabpanel-0"
                          >
                            <div className="row" style={{ marginBottom: 75 }}>
                              <div className="col-9">
                                <div className="col-12 mb-4 mt-4 paymentGatewayBlock">
                                  <Typography variant="caption">
                                    <b>PAY ONLINE</b>
                                  </Typography>
                                  <Typography variant="body2">
                                    PayStack
                                  </Typography>
                                  <Typography variant="body2"></Typography>

                                  <PaystackButton
                                    text={localStorage.getItem(
                                      'paystackPayText'
                                    )}
                                    className="mt-2 MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
                                    callback={callback}
                                    close={close}
                                    disabled={false}
                                    embed={false}
                                    reference={getReference()}
                                    email={user.email}
                                    style={{
                                      backgroundColor: '#3f51b5',
                                      color: 'white!important',
                                      height: 50,
                                      width: '100%',
                                      marginTop: 10,
                                    }}
                                    amount={itemTotalPrice}
                                    paystackkey={localStorage.getItem(
                                      'paystackPublicKey'
                                    )}
                                    tag="button"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {tab === 1 && (
                          <div
                            Style={{ padding: 20 }}
                            role="tabpanel"
                            id="vertical-tabpanel-0"
                            aria-labelledby="vertical-tabpanel-0"
                          >
                            <div className="row" style={{ marginBottom: 75 }}>
                              <div className="col-9">
                                <Typography variant="caption">
                                  <b>Wallet</b>
                                </Typography>
                                <Typography variant="body2">
                                  Your Available Balance:{' '}
                                  {localStorage.getItem('currencyFormat')}{' '}
                                  {user ? user.wallet_balance : 0}
                                </Typography>
                                <Typography variant="body2"></Typography>
                                <Button
                                  disabled={loading}
                                  onClick={() => null}
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    width: '100%',
                                    height: 40,
                                    color: 'white',
                                    marginTop: 10,
                                  }}
                                >
                                  {loading ? 'Please Wait...' : 'PLACE ORDER'}
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}

                        {tab === 2 && (
                          <div
                            style={{ padding: 20 }}
                            role="tabpanel"
                            id="vertical-tabpanel-0"
                            aria-labelledby="vertical-tabpanel-0"
                          >
                            <div className="row" style={{ marginBottom: 75 }}>
                              <div className="col-9">
                                <Typography variant="caption">
                                  <b>COD</b>
                                </Typography>
                                <Typography variant="body2">
                                  Cash on Delivery. Pay using cash
                                </Typography>
                                <Button
                                  disabled={loading}
                                  onClick={() => placeOrder('COD')}
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    width: '100%',
                                    height: 40,
                                    color: 'white',
                                    marginTop: 10,
                                  }}
                                >
                                  {loading ? 'Please Wait...' : 'PLACE ORDER'}
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-3 m-3 bg-white" style={{ padding: '0px' }}>
              <div
                className="row"
                style={{
                  borderBottom: '1px solid rgba(171, 171, 171, 0.5)',
                  marginRight: 5,
                }}
              >
                <div className="col-sm-12" style={{ padding: '0px' }}>
                  <div className="row mb-3">
                    <div className="col-4">
                      <Link
                        to={`/desktop/restaurantDetail/${
                          !!localStorage.getItem('cartRestaurant') &&
                          JSON.parse(localStorage.getItem('cartRestaurant'))
                            .slug
                        }`}
                      >
                        <img
                          style={{
                            width: '100%',
                            borderRadius: 4,
                            objectFit: 'cover',
                          }}
                          alt="logo"
                          src={ROOT_URL + restaurant.image}
                        />
                      </Link>
                    </div>

                    <div className="col-8 col-md-8 p-0">
                      <div className="p-2">
                        <Typography variant="h6">
                          <b>{restaurant.name}</b>
                        </Typography>
                        <div
                          className="cart-address"
                          style={{ color: localStorage.getItem('storeColor') }}
                        >
                          {restaurant.address}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 cart-items-box">
                  <div className="row dashed-bottom">
                    {cart
                      .sort((a, b) =>
                        a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                      )
                      .map((i) => {
                        return (
                          <div key={i.id} className="col-12">
                            <div className="p-2 cart-item-list-action-area-wrapper">
                              <div className="row">
                                <div className="col-6 p-0">
                                  <div className="cart-drawer-item-list-row cart-item-list-row">
                                    <img
                                      // src={ROOT_URL + "/public/" + item.image}
                                      src={ROOT_URL + i.image}
                                      alt="HotpotAfrica"
                                      style={{
                                        width: '35%',
                                        borderRadius: 4,
                                        objectFit: 'cover',
                                      }}
                                    />
                                    <Typography
                                      variant="h6"
                                      color="textSecondary"
                                      style={{
                                        minWidth: 55,
                                        margin: '0 10px',
                                      }}
                                    >
                                      <b>{i.name}</b>
                                    </Typography>
                                  </div>
                                </div>
                                <div className="col-6 p-3 cart-item-amount-price-section">
                                  {cart.some((a) => a.id === i.id) && (
                                    <div
                                      className="MuiButton-contained"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        minWidth: 200,
                                        background: 'white',
                                        color:
                                          localStorage.getItem('storeColor') ||
                                          '#000',
                                        borderRadius: 0,
                                        padding: '10px',
                                        fontSize: 15,
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => decrementCart(i)}
                                        style={{ float: 'left' }}
                                        size="small"
                                        aria-label="delete"
                                      >
                                        <RemoveIcon fontSize="small" />
                                      </IconButton>
                                      <p
                                        style={{
                                          width: 20,
                                          marginBottom: 0,
                                          textAlign: 'center',
                                        }}
                                      >
                                        {
                                          cart.filter((a) => a.id === i.id)[0]
                                            .quantity
                                        }
                                      </p>
                                      <IconButton
                                        onClick={() => incrementCart(i)}
                                        style={{ float: 'right' }}
                                        size="small"
                                        aria-label="plus"
                                      >
                                        <AddIcon fontSize="small" />
                                      </IconButton>
                                      <h5
                                        className={`cart-item-price-alignment restaurant-item-pricing-alignment--${localStorage.getItem(
                                          'currencySymbolAlign'
                                        )}`}
                                      >
                                        <div
                                          className={`${localStorage.getItem(
                                            'currencySymbolAlign'
                                          )} cart-drawer-line-currency`}
                                        >
                                          {localStorage.getItem(
                                            'currencyFormat'
                                          )}{' '}
                                        </div>
                                        {Number(i?.cartPrice || i?.price)
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /(\d)(?=(\d\d)+\d$)/g,
                                            '$1,'
                                          )}
                                      </h5>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  {!!coupon && (
                    <CouponsDrawer
                      setCoupon={setCoupon}
                      coupon={coupon}
                      applyCoupon={setApplyCoupon}
                    />
                  )}

                  <div className="row order-attention-desc">
                    <div className="text-danger">
                      Orders once placed cannot be cancelled after 2 minutes of
                      booking and are non-refundable
                    </div>
                  </div>

                  <div className="row bill-datails-wrapper">
                    <div className="bill-details-title">BILL DETAILS</div>
                    <div className="bill-details-item-wrapper">
                      <div className="bill-details-items-container">
                        <div className="bill-details-item-title">
                          Item Total
                        </div>
                        <div
                          className={`bill-details-item-price cart-item-price-alignment restaurant-item-pricing-alignment--${localStorage.getItem(
                            'currencySymbolAlign'
                          )}`}
                        >
                          <div
                            className={`${localStorage.getItem(
                              'currencySymbolAlign'
                            )} cart-drawer-line-currency`}
                          >
                            {localStorage.getItem('currencyFormat')}{' '}
                          </div>
                          {String(itemSubTotalPrice).replace(
                            /(\d)(?=(\d\d)+\d$)/g,
                            '$1,'
                          )}
                        </div>
                      </div>

                      <div className="bill-details-items-container">
                        <div className="bill-details-item-title">
                          Restaurant Charges
                        </div>
                        <div
                          className={`bill-details-item-price cart-item-price-alignment restaurant-item-pricing-alignment--${localStorage.getItem(
                            'currencySymbolAlign'
                          )}`}
                        >
                          <div
                            className={`${localStorage.getItem(
                              'currencySymbolAlign'
                            )} cart-drawer-line-currency`}
                          >
                            {localStorage.getItem('currencyFormat')}{' '}
                          </div>
                          {restaurant.restaurant_charges}
                        </div>
                      </div>

                      {!!calculateDiscount() > 0 && (
                        <div className="bill-details-items-container">
                          <div className="bill-details-item-title">
                            Total Discount
                          </div>
                          <div
                            className={`bill-details-item-price cart-item-price-alignment restaurant-item-pricing-alignment--${localStorage.getItem(
                              'currencySymbolAlign'
                            )}`}
                          >
                            <div
                              className={`${localStorage.getItem(
                                'currencySymbolAlign'
                              )} cart-drawer-line-currency`}
                            >
                              {localStorage.getItem('currencyFormat')}{' '}
                            </div>
                            - {calculateDiscount()}
                          </div>
                        </div>
                      )}

                      {coupon && applyCoupon?.applied && (
                        <div className="row cart-coupon-saving-confirmation">
                          <div
                            className="row"
                            style={{
                              flexDirection: 'column',
                              height: 50,
                              width: '100%',
                              padding: 5,
                              margin: '25px 35px 5px 15px',
                              border: '1px solid #00ab05',
                              textAlign: 'center',
                              backgroundColor: '#e4ffe8',
                            }}
                          >
                            <div className="col-8 p-2">
                              <div className="cart-coupon-savings-text">
                                Your total saving
                              </div>
                            </div>
                            <div className="cart-coupon-total-savings-wrapper">
                              <Typography
                                style={{ color: '#00ab05' }}
                                className="p-2 float-right"
                                variant="h5"
                              >
                                <div
                                  className={`bill-total-savings bill-details-item-price cart-item-price-alignment restaurant-item-pricing-alignment--${localStorage.getItem(
                                    'currencySymbolAlign'
                                  )}`}
                                >
                                  <div
                                    className={`${localStorage.getItem(
                                      'currencySymbolAlign'
                                    )} cart-drawer-line-currency`}
                                  >
                                    <b>
                                      {' '}
                                      {localStorage.getItem(
                                        'currencyFormat'
                                      )}{' '}
                                    </b>
                                  </div>
                                  <b>{calculateDiscount()}</b>
                                </div>
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        className="bill-details-items-container"
                        style={{ ...Styles.bts, ...Styles.bbs, paddingTop: 10 }}
                      >
                        <div className="bill-details-item-title">
                          Delivery Fee
                        </div>
                        <div
                          className={`bill-details-item-price bill-details-item-price-delivery-fee cart-item-price-alignment restaurant-item-pricing-alignment--${localStorage.getItem(
                            'currencySymbolAlign'
                          )}`}
                        >
                          <div
                            className={`${localStorage.getItem(
                              'currencySymbolAlign'
                            )} cart-drawer-line-currency`}
                          >
                            {localStorage.getItem('currencyFormat')}{' '}
                          </div>
                          {Number(deliveryCharge).toFixed(2)}
                        </div>
                      </div>

                      {localStorage.getItem('taxApplicable') === 'true' &&
                        ((
                          <div className="col-8" style={Styles.bbs}>
                            <Typography
                              className="p-2 text-danger"
                              variant="body2"
                            >
                              TAX ({localStorage.getItem('taxPercentage')}%)
                            </Typography>
                          </div>
                        ),
                        (
                          <div className="col-4" style={Styles.bbs}>
                            <Typography
                              className="p-2 text-danger float-right"
                              variant="body2"
                            >
                              {localStorage.getItem('currencyFormat')}{' '}
                              {taxPay()}
                            </Typography>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="bill-total-wrapper">
                  <div className="bill-total-title">To Pay</div>
                  <div className="bill-total-price">
                    <h5
                      className={`restaurant-item-pricing-alignment--${localStorage.getItem(
                        'currencySymbolAlign'
                      )}`}
                    >
                      <div
                        className={`${localStorage.getItem(
                          'currencySymbolAlign'
                        )} cart-drawer-line-currency`}
                      >
                        {localStorage.getItem('currencyFormat')}{' '}
                      </div>
                      {Number(itemTotalPrice)
                        .toFixed(2)
                        .toString()
                        .replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const Styles = {
  vegIcon: {
    width: '16px',
    height: '16px',
    position: 'absolute',
    left: '10px',
    top: '10px',
    background: 'white',
  },
  bts: {
    marginRight: 20,
    borderTop: '1px solid rgba(171, 171, 171, 0.5)',
  },
  btd: {
    borderTop: '1px dashed',
  },
  bbs: !!localStorage.getItem('taxApplicable') === 'true' && {
    borderBottom: '1px solid rgba(171, 171, 171, 0.5)',
  },
  bbd: {
    borderBottom: '1px dashed',
  },
};
