import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import Ink from 'react-ink';
import HandleLanguages from '../../Shared/Language/HandleLanguages';
import getLanguageWord from '../../Shared/Lib/getLanguageWord';
import { ROOT_URL } from '../../../configs/index';
import './styles.scss';

class Navigation extends Component {
  static contextTypes = {
    router: () => null,
  };

  localstorage = Object.assign({}, window.localStorage);

  state = {
    languageWord: null,
    isPreferedLanguage: false,
    deviceLanguageImage: undefined,
    setUserAddress: {},
  };

  componentDidMount() {
    getLanguageWord();
    let { userSetAddress } = this.localstorage;

    this.setState({
      setUserAddress: userSetAddress ? JSON.parse(userSetAddress) : {},
    });
    this.forceUpdate();
  }

  render() {
    const {
      logo,
      loggedin,
      history,
      disable_back_button,
      alertUnreadTotal,
      languages,
    } = this.props;

    console.log(this.state.setUserAddress);

    return (
      <React.Fragment>
        <div
          className="col-12 p-0"
          style={{ position: 'fixed', top: 0, zIndex: 2147483647 }}
        >
          <div className="block m-0">
            <div className="block-content p-0">
              <div className="input-group search-box">
                {!disable_back_button && (
                  <div className="input-group-prepend">
                    <button
                      type="button"
                      className="btn search-navs-btns"
                      style={{ position: 'relative' }}
                    >
                      <i className="si si-arrow-left" />
                      <Ink duration="500" />
                    </button>
                  </div>
                )}
                <div className="form-control search-input-header">
                  {logo && (
                    <img
                      style={{ width: 24 }}
                      src={
                        this.localstorage.storeLogoSm
                          ? `${ROOT_URL}/${this.localstorage.storeLogoSm}`
                          : `/assets/img/fav.png`
                      }
                      alt={localStorage.getItem('storeName')}
                      className="store-logo"
                    />
                  )}

                  <span
                    onClick={() => {
                      loggedin
                        ? history.push('/my-addresses')
                        : history.push('/restaurants');
                    }}
                    style={{
                      marginLeft: 10,
                      fontSize: 14,
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    }}
                  >
                    {Object.keys(this.state.setUserAddress)?.length
                      ? this.state.setUserAddress?.formatted_address?.length >
                        25
                        ? `${this.state.setUserAddress?.formatted_address?.substring(
                            0,
                            25
                          )}...`
                        : `${this.state.setUserAddress?.address?.substring(
                            0,
                            25
                          )}...`
                      : null}
                  </span>
                  {Object.keys(this.state.setUserAddress)?.length ? (
                    <i
                      onClick={() => {
                        loggedin
                          ? history.push('/my-addresses')
                          : history.push('/search-location');
                      }}
                    ></i>
                  ) : null}
                </div>
                <div className="input-group-append input-group-alert">
                  <NavLink
                    to={{ pathname: '/alerts', state: { fromHome: true } }}
                    className="col footer-links px-2 py-1"
                  >
                    {alertUnreadTotal && alertUnreadTotal > 0 ? (
                      <span
                        className="alert-quantity-badge"
                        style={{ backgroundColor: localStorage.storeColor }}
                      >
                        {alertUnreadTotal}
                      </span>
                    ) : (
                      ''
                    )}
                    <i className="si si-bell fa-2x" /> <br />
                    <span
                      className={
                        history.location.pathname === '/alerts'
                          ? 'active-footer-tab'
                          : ''
                      }
                    ></span>
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/languages', state: { fromHome: true } }}
                  >
                    <HandleLanguages
                      languages={languages}
                      device={'mobile'}
                      position={'header'}
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  alertUnreadTotal: state.alert.alertUnreadTotal,
});

export default withRouter(connect(mapStateToProps)(Navigation));
